import { Box, Stack, TableContainer } from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api_endpoints, { base_url } from "../../Api/end_points";
import CustomChip from "../../Common/CustomChip";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import FilterAndSort from "../../Common/FilterAndSort";
import { FilterPropertyType } from "../../Common/FilterAndSort/type";
import PageTitle from "../../Common/PageTitle";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import { IRequestBody } from "../../Constants/interfaces";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import { QueryColumn } from "./columnsData";
import {
  deleteQueries,
  getCategories,
  getCrops,
  getQueryByQueryId,
  getQueryList,
  getStates,
  queryStatusList,
} from "./QueryAPICalls";
import Respond from "./Respond";
import { useStyles } from "./style";
import View from "./View/index";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { ConvertJSONToXLS } from "../../Utils/fileHelper";
import moment from "moment";
import { checkExportToExcelAllowed } from "../../Api/generic_apicalls";

export type FilterType = {
  languageIds: Array<string>;
  notificationTypes: Array<string>;
  moduleNames: Array<string>;
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  status: Array<string>;
  frequencyIds: Array<string>;
  crop: Array<string>;
  category: Array<string>;
};

type QueryObjProps = {
  queryId: string;
  message: string;
  createdDate: string;
  phoneNumber: any;
  name: string;
  category: any;
  rating: number;
  responded: boolean;
};

const DEFAULT_PAGE_SIZE: number = 5;
const defaultFilters: FilterType = {
  sortBy: "ID",
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  languageIds: [],
  status: [],
  notificationTypes: [],
  moduleNames: [],
  frequencyIds: [],
  crop: [],
  category: [],
};

const QueryResponse: FC = (props: any) => {
  /**default data for filter */
  let defaultFilterData: FilterPropertyType = {
    languageIds: [],
    states: [],
    status: [],
    notificationTypes: [],
    moduleNames: [],
    frequencyIds: [],
    crop: [],
    category: [],
    responded: [],
  };

  const storeData = useSelector((state: RootState) => {
    return {
      langCode: state.auth.languageCode,
      languages: state.generic.ListOfLanguages?.languageList,
    };
  });

  const classes = useStyles();

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState(0);
  const [showRespond, setShowRespond] = useState<boolean>(false);
  const [showView, setShowView] = useState<boolean>(false);
  const [queriesData, setQueriesData] = useState<any[]>([]);
  const [cropData, setCropData] = useState<any>([]);
  const [statesData, setStatesData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<string[]>([]);
  const [rowPerPage, setRowPerPage] = useState<number>(5);
  const [queryItemObject, setQueryItemObject] = useState<any>([]);
  const [queryItem, setQueryDataById] = useState<any>();
  const [deleteList, setDeleteList] = useState<any>([]);
  const [sortId, setSortId] = useState("1");
  const [secondarySortId, setSecondarySortId] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<number>(0);
  const [filterData, setFilterData] =
    useState<FilterPropertyType>(defaultFilterData);
  const [filters, setFilters] = useState<FilterType>({
    ...defaultFilters,
  });
  const [buttonExportVisible, setButtonExportVisible] =
    useState<boolean>(false);

  useEffect(() => {
    fetchQueryData(sortId);
    getCropsList();
    getCategoryList();
    fetchStatesData();
    setShowView(false);

    setFilters({
      sortBy: "ID",
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: 0,
      languageIds: [],
      status: [],
      notificationTypes: [],
      moduleNames: [],
      frequencyIds: [],
      crop: [],
      category: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchQueryData(sortId);
  }, [filterData, sortId]);

  useEffect(() => {
    /** If  searchedValue is empty than only reset the table data*/
    if (currentPage > 0) {
      setCurrentPage(0);
      return;
    }
    fetchQueryData(sortId);
  }, [searchText]);

  useEffect(() => {
    checkExportToExcelAllowed("Query Response")?.then((res) =>
      setButtonExportVisible(res)
    );
  }, []);

  const handleSortData = (sortType: any) => {
    setQueriesData([]);
    setSortId(sortType?.toString() ?? "1");
    fetchQueryData(sortType ?? "1");
  };

  const fetchQueryData = (sortType: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.query_list;
    apiData.showLoader = true;
    apiData.payLoad = {
      pageNo: currentPage,
      pageSize: rowPerPage,
      sortBy: "createdDate",
      orderBy: sortType === "1" ? "desc" : "asc",
    };
    if (searchText?.length > 0) {
      apiData.payLoad.searchText = searchText;
    }
    if (
      filterData &&
      filterData.hasOwnProperty("status") &&
      filterData.status!.length > 0
    ) {
      apiData.payLoad.status =
        filterData?.status?.[0] === "2" ? "OPEN" : "CLOSED";
    }
    if (filterData?.crop?.length > 0) {
      apiData.payLoad.crops = filterData.crop.toString();
    }

    if (filterData?.responded && filterData?.responded?.length > 0) {
      apiData.payLoad.responded = filterData.responded.toString();
    }

    if (filterData?.category?.length > 0) {
      apiData.payLoad.category = filterData.category.toString();
    }

    if (filterData?.languageIds && filterData?.languageIds?.length > 0) {
      const languagesId = filterData.languageIds.map((item: any) => {
        return item.split(":::")[1];
      });
      apiData.payLoad.languageId = languagesId.toString();
    }

    if (filterData?.states && filterData?.states?.length > 0) {
      const statesId = filterData.states.map((item: any) => {
        return item.split(":::")[1];
      });
      apiData.payLoad.statesId = statesId.toString();
    }

    return getQueryList(apiData, successCallBack, errorCallback);
  };

  const fetchStatesData = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.country_wise_states;
    apiData.showLoader = true;
    return getStates(apiData, successCallBack, errorCallback);
  };

  const fetchQueriesWithQueryId = (queryID: string) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = `${api_endpoints.query_list}/${queryID}`;
    apiData.showLoader = true;

    return getQueryByQueryId(apiData, successCallBack, errorCallback);
  };

  const getCropsList = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.crops;
    apiData.showLoader = true;
    apiData.customHeaders = { "language-code": storeData.langCode ?? "en" };
    apiData.payLoad = { pageNo: 0, pageSize: 100 };
    return getCrops(apiData, successCallBack, errorCallback);
  };

  const getCategoryList = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.categories;
    apiData.showLoader = true;
    return getCategories(apiData, successCallBack, errorCallback);
  };

  const ExportToExcelCall = () => {
    const selectedData = queriesData.filter((item: any) => item.checked);

    if (selectedData.length === 0) {
      TriggerToastMessage("Please select atleast one line to export", "warn");
      return;
    }

    const columnsData = [
      {
        label: "Query ID",
        value: "queryId",
      },
      {
        label: "Crop",
        value: "name",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Phone No.",
        value: "phoneNumber",
      },
      {
        label: "User Message",
        value: "message",
      },
      {
        label: "Received on",
        value: (row: any) =>
          row.createdDate ? moment(row.createdDate).format("YYYY-MM-DD") : "",
      },
      {
        label: "Aging Days",
        value: "agingDays",
      },
      {
        label: "Status",
        value: "status",
      },
      {
        label: "User Rating",
        value: "rating",
      },
    ];

    const dataObj = [
      {
        columns: columnsData,
        content: selectedData,
        sheet: "Queries",
      },
    ];

    let settings = {
      fileName: "QueriesInfo_" + new Date().getTime(), // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    ConvertJSONToXLS(dataObj, settings);
  };

  // for drop-down api calls
  const successCallBack = (response: any, type: string) => {
    switch (type) {
      case "queryList":
        setPageCount(response.data.totalPages);
        setQueriesData(response.data.queries);
        break;
      case "getQueryByQueryId":
        setQueryDataById(response.data);
        setShowView(true);
        break;
      case "deleteQuery":
        fetchQueryData(sortId);
        break;
      case "crops":
        let cropsData = response.data.cropsList;
        setCropData(cropsData);
        break;
      case "categories":
        let categoryData = response.data;
        setCategoryData(categoryData);
        break;
      case "states":
        let resultStates = response.data;
        setStatesData(resultStates);
        break;
      case "exportToExcel":
        downloadXLSFile(response);
        break;
    }
  };

  const downloadXLSFile = async (response: any) => {
    const headers = response.config.headers;
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${response.config.baseURL}${response.config.url}`,
      responseType: "arraybuffer",
      headers,
      params: response.config.params,
    };
    try {
      const response = await axios(config);
      const outputFilename = `QueriesList_${new Date().toLocaleDateString()}.xlsx`;
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error: any) {
      throw Error(error);
    }
  };

  const errorCallback = () => {
    TriggerToastMessage("Unable to process your request", "error");
  };

  const deleteQueryList = () => {
    let ids: Array<number> = queriesData
      .filter((row: any) => row.checked === true)
      .map((ids: any) => ids.queryId);

    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.payLoad = ids;
    apiData.endPoint = `${api_endpoints.query_list}`;
    apiData.showLoader = true;
    return deleteQueries(apiData, successCallBack, errorCallback);
  };

  const handleCloseView = () => {
    setShowView(false);
  };

  const handleCloseRespond = () => {
    setShowRespond(false);
  };

  // const handleDeleteSelections = (list: any) => {
  //   setDeleteList(list);
  // };

  const handleActionClick = (clickAction: string, details: QueryObjProps) => {
    switch (clickAction) {
      case "Delete":
        deleteQueryList();
        break;
      case "Export to excel":
        ExportToExcelCall();
        break;
      case "View_Push":
        const queryID = details.queryId;
        fetchQueriesWithQueryId(queryID);
        setQueryItemObject(details);
        break;
      case "Respond_Push":
        setShowRespond(true);
        setQueryItemObject(details);
        break;
    }
  };

  /**Filter handler */
  const filterHandler = (properties: FilterPropertyType) => {
    setQueriesData([]);
    setCurrentPage(0)
    let newproperties = { ...properties };
    setFilterData(newproperties);
  };

  // const handlePageChange = (pageNumber: number) => {
  //   setQueriesData([]);
  //   setCurrentPage(pageNumber);
  // };

  useEffect(() => {
    fetchQueryData(sortId);
  }, [currentPage]);


  useEffect(() => {
    fetchQueryData(sortId);
  }, [rowPerPage]);

  // const handleTableDelete = () => {
  //   deleteQueryList();
  // };

  const queryDataOnReplySuccess = (status: string) => {
    if (status === "Reply to query is successful") {
      // setQueriesData([]);
      fetchQueryData(sortId);
    }
  };

  /**
   * @description A function to trigger an api call to fetch notifications
   */
  const onChangeOfRowsPerPage = (value: number) => {
    setRowPerPage(value);
  };

  /**
   * @description to check the all rows or perticular row
   *
   */
  const selectAllRecords = (status: boolean) => {
    checkOrUncheckAllRows(queriesData, status);
  };

  const selectEachByRow = (check: boolean, queryData: any) => {
    checkOrUncheckRow(queryData, check);
  };

  const checkOrUncheckAllRows = (
    data: Array<QueryObjProps>,
    status: boolean
  ) => {
    const newArr = _.map(data, (o) => _.extend({ ...o }, { checked: status }));
    setQueriesData([...newArr]);
  };

  const checkOrUncheckRow = (data: QueryObjProps, status: boolean) => {
    const filterData: any = queriesData.map((isdata: any) => {
      if (isdata.queryId === data.queryId) {
        isdata.checked = status;
        return isdata;
      }

      return isdata;
    });
    setQueriesData([...filterData]);
  };

  const renderChips = () => {
    return (
      <Stack direction="row" spacing={1}>
        {filterData.languageIds?.map((item, index) => (
          <CustomChip
            key={index}
            label={item?.split("::")?.[0]}
            handleChipDelete={(id: any, type: any) => {
              setFilterData((state) => {
                return {
                  ...state,
                  languageIds: state.languageIds?.filter(
                    (_, index) => index !== id
                  ),
                };
              });
            }}
            id={index}
            type="crop"
          />
        ))}
        {filterData.crop.map((item, index) => (
          <CustomChip
            key={index}
            label={item}
            handleChipDelete={(id: any, type: any) => {
              setFilterData((state) => {
                return {
                  ...state,
                  crop: state.crop.filter((_, index) => index !== id),
                };
              });
            }}
            id={index}
            type="crop"
          />
        ))}
        {filterData.category.map((item, index) => (
          <CustomChip
            key={index}
            label={item}
            handleChipDelete={(id: any, type: any) => {
              setFilterData((state) => {
                return {
                  ...state,
                  category: state.category.filter((_, index) => index !== id),
                };
              });
            }}
            id={index}
            type="crop"
          />
        ))}
        {filterData?.status?.map((item, index) => (
          <CustomChip
            key={index}
            label={item === "2" ? "Open" : "Closed"}
            handleChipDelete={(id: any, type: any) => {
              setFilterData((state) => {
                return {
                  ...state,
                  status: state?.status?.filter((_, index) => index !== id),
                };
              });
            }}
            id={index}
            type="crop"
          />
        ))}
        {filterData?.responded?.map((item, index) => (
          <CustomChip
            key={index}
            label={item === "true" ? "Yes" : "No"}
            handleChipDelete={(id: any, type: any) => {
              setFilterData((state) => {
                return {
                  ...state,
                  responded: state?.responded?.filter(
                    (_, index) => index !== id
                  ),
                };
              });
            }}
            id={index}
            type="crop"
          />
        ))}

        {filterData?.states?.map((item, index) => (
          <CustomChip
            key={index}
            label={item?.split("::")?.[0]}
            handleChipDelete={(id: any, type: any) => {
              setFilterData((state) => {
                return {
                  ...state,
                  states: state?.states?.filter((_, index) => index !== id),
                };
              });
            }}
            id={index}
            type="crop"
          />
        ))}
      </Stack>
    );
  };

  return (
    <>
      <TableContainer>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ paddingX: "1.5rem", marginTop: "1.5rem" }}
          >
            <PageTitle title={t("str_query_response")} />
            <SearchBar
              placeholderText={t("str_search")}
              searchValue={searchText}
              onSearchValueChange={(text: string) => {
                setSearchText(text);
              }}
              onEnterClick={() => {}}
            />
          </Stack>
          <Box>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                paddingX: "1.5rem",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomTabs
                  value={value}
                  tabsList={[t("str_data_list")]}
                  isQuery={false}
                  onChange={(newValue: number) => setValue(newValue)}
                />
              </Stack>
            </Box>

            <Box className={classes.panelBoxHelper}>
              <RowsPerPage
                pagSize={rowPerPage}
                onChangeRowsPerPage={(value: any) => {
                  onChangeOfRowsPerPage(value);
                  setCurrentPage(0)
                }}
              />

              <FilterAndSort
                filters={filterData}
                sortBy={sortId}
                secondarySortBy={secondarySortId}
                // onChangeFilters={props.onChangeFilters}
                onChangeFilters={(properties: any) => filterHandler(properties)}
                onSort={(sortType: any) => handleSortData(sortType)}
                onSecondarySort={(sortType: any) =>
                  setSecondarySortId(sortType?.toString())
                }
                showRespondedList={activeTab === 0}
                showLanguageSelection={activeTab === 0}
                showNotificationType={activeTab === 1}
                showFrequencyList={activeTab === 1 || activeTab === 2}
                showStatusList={activeTab === 0}
                showPublishOnSort={activeTab === 1}
                showSentOnSort={activeTab === 0}
                showSecondarySort={activeTab === 2}
                showStatestype={activeTab === 0}
                showCropList={activeTab === 0 && cropData.length > 0}
                showCategoryList={activeTab === 0 && categoryData.length > 0}
                statusList={queryStatusList}
                cropList={cropData}
                category={categoryData}
                statesList={statesData}
              />
            </Box>
            <Stack
              className={classes.chipBoxHelper}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {renderChips()}
            </Stack>
            {/* {queriesData !== null && queriesData.length > 0 && (
              <div> */}
            <DataTable
              actionsType={ACTION_TYPE.TYPE_QUERY}
              // showActionProgress={props.showActionProgress}
              enableSelection={activeTab !== 2}
              columns={QueryColumn}
              data={queriesData}
              pageNumber={1}
              onPageChange={props.onPageChange}
              onSelectAll={selectAllRecords}
              onDeselectAll={() => {}}
              onActionClick={handleActionClick}
              onRowSelect={selectEachByRow}
              isEditEnabled={false}
              isStatusBadge={false}
              isViewButton={true}
              isRespondButton={true}
              isDownLoadButton={activeTab === 2}
              isActionTitleEnabled={true}
            />
            <DataTableActions
              actionsType={ACTION_TYPE.TYPE_QUERY}
              buttonVisible={true}
              buttonExportVisible={buttonExportVisible}
              pageNumber={currentPage + 1}
              onPageChange={(selectedPageNo: number) =>
                setCurrentPage(selectedPageNo - 1)
              }
              onActionClick={handleActionClick}
              showActionProgress={isActionInProgress}
              totalpages={pageCount}
              canShowAvatar={false}
              activeTab={activeTab}
            />
          </Box>
        </div>
      </TableContainer>
      {showView && queryItem !== null && (
        <View
          onCloseView={handleCloseView}
          queryItemObject={queryItemObject}
          queryItemData={queryItem}
        />
      )}
      {showRespond && (
        <Respond
          onCloseRespond={handleCloseRespond}
          QueryInRespond={queryItemObject}
          OnReplySuccess={queryDataOnReplySuccess}
        />
      )}
    </>
  );
};

export default QueryResponse;
