import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Box, Button, Divider, FormControl, FormHelperText, MenuItem, Select, Stack, Typography,} from "@mui/material";
import {ErrorMessage, Field, FieldProps, Form, Formik, FormikProps,} from "formik";
import _ from "lodash";
import {useEffect, useState} from "react";
import ButtonWithIcon, {CANCEL_TYPE, CREATE_TYPE,} from "../../../Common/Buttons";
import {ActionType, STEPS} from "../../../Common/Constants";
import MultiSelect from "../../../Common/MultiSelect";
import SortableComponent from "../../../Common/SortableRecords";
import {checkForDuplicates} from "../../../Utils/genericUtils";
import {FilterSchemaValidation} from "../HybridValidationSchema";
import {useStyles} from "../style";
import {FilterKeys, InitialFilters} from "./index";

export type StateListType = {
  stateShortCode: string;
  stateId: number;
  stateName: string;
  enStateName: string;
  name: string;
  checked: boolean;
};

type FilterProps = {
  ListOfStages: Array<FilterKeys>;
  selectedStage: FilterKeys;
  stateList: Array<StateListType>;
  onSelect: Function;
  onSave: Function;
  onRemove: Function;
  onNext: Function;
  onPrevious: Function;
  onClose: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  updateListOrder: Function;
  isEdit: boolean;
  hybridGeneralDetails: Array<any>;
  hybridFeatures: Array<any>;
  hybridPrecautions: Array<any>;
  hybridPractice: Array<any>;
  hybridTestimonials: Array<any>;
  filterList: Array<any>;
  actionType: string;
  isPrimaryProduct: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
};


export const ApplicableFilterForm: React.FC<FilterProps> = (
  props: FilterProps
) => {
  const SELECT_ALL = "Select all";
  const classes = useStyles();
  const nextStepHandler = (step: number) => {
    props.onNext(step);
  };

  let latestSelectedStates: any[] = props.selectedStage.states
    ? props.selectedStage.states
    : [];
  const [stateList, setStateList] = useState<Array<StateListType>>([
    ...props.stateList,
  ]);
  const [selectedStates, setSelectedStates] = useState<Array<any> | undefined>(
    props.selectedStage.states ? props.selectedStage.states : undefined
  );

  const [listOfStages, setListOfStages] = useState<Array<any>>([]);

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (value === "Select all") {
      return handleAllSatesSelection(checked);
    } else {
      /**automatic uncheck 'Select All' */
      if (stateList && stateList[0].checked && !checked) {
        stateList[0].checked = false;
      }

      /**automatic check 'Select All' */
      if (
        checked &&
        _.filter(stateList, (state) => state.checked).length + 1 ===
          stateList.length - 1
      ) {
        stateList[0].checked = true;
      }

      /**one by one check and uncheck */
      const index: number = stateList.findIndex(
        (state: any) => state.name === value
      );
      stateList[index].checked = checked;
      setStateList([...stateList]);

      /**filter out selected states */
      let filteredStates: any = stateList.filter(
        (state: StateListType) => state.checked && state.name !== SELECT_ALL
      );
      setSelectedStates(filteredStates.length ? filteredStates : []);
      latestSelectedStates = filteredStates.length ? filteredStates : [];

      return filteredStates;
    }
  };

  const handleClearStateSelection = (checked: boolean) => {
    handleAllSatesSelection(checked);
  };

  const handleAllSatesSelection = (checked: boolean) => {
    let allStateList: any = stateList;
    allStateList.map((state: any) => {
      state.checked = checked;
    });
    setStateList(allStateList);
    let filteredStates = allStateList.filter(
      (state: StateListType) => state.checked && state.name !== SELECT_ALL
    );
    setSelectedStates(filteredStates.length ? filteredStates : []);
    latestSelectedStates = filteredStates.length ? filteredStates : [];
    return filteredStates;
  };

  useEffect(() => {
    const { ListOfStages, stateList } = props;

    if (ListOfStages.length > 0 && stateList.length > 0) {
      const stateMap = stateList.reduce((map: any, state) => {
        map[state.stateShortCode] = state.name;
        return map;
      }, {});

      const filters = ListOfStages.map((stage) => {
        const dataState = stage.states?.map((state) => ({
          ...state,
          name: stateMap[state.shortCode ?? state.stateShortCode] || state.name,
        }));

        return {
          ...stage,
          states: dataState,
          filterStates: dataState,
        };
      });

      setListOfStages(filters);
    }
  }, [props.ListOfStages, props.stateList]);

  const renderAddedFilters = () => {
    if (listOfStages.length === 0) {
      return (
        <>
          {" "}
          <Stack flex={1} width={380}>
            <Box>
              <Typography variant="subtitle2">Added Filter(s):</Typography>
              <Typography variant="caption" component="span">
                You may reorder the filters by dragging each panel up or down
              </Typography>
            </Box>
          </Stack>
        </>
      );
    }
    return (
      <Stack flex={1} width={380}>
        <Box>
          <Typography variant="subtitle2">Added Filter(s):</Typography>
          <Typography variant="caption" component="span">
            You may reorder the filters by dragging each panel up or down
          </Typography>
          <FormHelperText error={true}></FormHelperText>
          <Box my={2}></Box>
          <SortableComponent
            name={"Applicable Filter"}
            items={_.cloneDeep(listOfStages)}
            updateCropStageOrder={(newList: Array<FilterProps>) => {
              props.updateListOrder(newList);
            }}
            key={listOfStages.length}
            listProps={props}
            isDraggable={true}
            displayKey={"keySystemName"}
            initialState={InitialFilters}
            selectedRecord={props.selectedStage}
            secMatchKey="valueSystemName"
            // isDisabled={
            //   !props.isPrimaryProduct &&
            //   props.actionType !== ActionType.CREATE
            // }
            showRemoveButton={props.isPrimaryProduct}
          />
        </Box>
      </Stack>
    );
  };
  useEffect(() => {
    if (Array.isArray(selectedStates)) {
      latestSelectedStates = selectedStates;
    }
  }, [selectedStates]);

  useEffect(() => {
    if (props.selectedStage.states) {
      /** Auto selecting the selected states in the DD */
      latestSelectedStates = props.selectedStage.states ?? [];
      stateList.map((eachState: any) => {
        eachState.checked = false;
        latestSelectedStates.map((selectedState: any) => {
          if (selectedState.name === eachState.name) {
            eachState.checked = true;
          }
        });
      });
      if (latestSelectedStates.length === stateList.length - 1) {
        stateList[0].checked = true;
      }
    } else {
      handleAllSatesSelection(false);
    }
  }, [props.selectedStage.states]);

  useEffect(() => {
    if (stateList && !stateList.length) {
      setStateList([...props.stateList]);
    }
  }, [props.stateList]);
  return (
    <>
      <Box>
        <Box className={classes.formContainer}>
          <div className={classes.formContainerChildDivStylesHepler}>
            <Stack direction="row" spacing={2}>
              <Formik
                initialValues={{
                  keySystemName: props.selectedStage.keySystemName,
                  valueSystemName: props.selectedStage.valueSystemName,
                  states: props.selectedStage.states,
                  filterValues: props.selectedStage.filterValues,
                  keyFilterValuesData: props.filterList
                    ? _.filter(props.filterList, (a) => {
                        return new RegExp(a.systemName, "i").test(
                          props.selectedStage.keySystemName ?? ""
                        );
                      })[0]?.filterValues
                    : [],
                }}
                validationSchema={FilterSchemaValidation}
                enableReinitialize={true}
                onSubmit={(values: any, _onSubmitProps: any) => {
                  if (
                    checkForDuplicates(
                      props.ListOfStages,
                      {
                        keySystemName: values!.keySystemName,
                        valueSystemName: values!.valueSystemName,
                      },
                      values!.keySystemName,
                      props.selectedStage.keySystemName
                    )
                  ) {
                    _onSubmitProps.setFieldError(
                      "keySystemName",
                      "Combination of filter key name with filter value already exist."
                    );
                    _onSubmitProps.setSubmitting(false);
                    return;
                  } else {
                    _onSubmitProps.setSubmitting(false);
                    props.onSave(values);
                    _onSubmitProps.resetForm();
                    props.updateUniqueKey();
                    latestSelectedStates = [];
                    setSelectedStates(undefined);
                    handleClearStateSelection(false);
                  }
                }}
              >
                {(values: FormikProps<any>) => (
                  <Stack
                    flex={1}
                    justifyContent="flex-end"
                    direction="row"
                    gap={2}
                  >
                    <Form>
                      <Stack spacing={2} width={380}>
                        <Box>
                          <Typography variant="subtitle2">
                            Filter Key SystemName *
                          </Typography>
                          <Select
                            fullWidth
                            value={values.values.keySystemName}
                            MenuProps={{ classes: { paper: classes.Select } }}
                            onChange={(event: any) => {
                              values.setFieldValue(
                                "keySystemName",
                                event.target.value
                              );
                              let value = _.filter(props.filterList, (a) => {
                                return new RegExp(a.systemName, "i").test(
                                  event.target.value
                                );
                              });
                              values.setFieldValue(
                                "keyFilterValuesData",
                                value[0].filterValues
                              );
                            }}
                            IconComponent={() => (
                              <ExpandMoreIcon
                                className={classes.selectExpandIconHelper}
                              />
                            )}
                            variant="outlined"
                            disabled={
                              props.canAddNewRecord
                                ? !!props.selectedStage.keySystemName
                                : true
                            }
                          >
                            {props.filterList?.map((o: any) => {
                              const systemName = (
                                o.systemName as string
                              ).toUpperCase();
                              return (
                                <MenuItem
                                  key={systemName}
                                  value={`${systemName}`}
                                >
                                  {systemName}
                                </MenuItem>
                              );
                            })}
                          </Select>

                          <FormHelperText
                            error={true}
                            className={classes.languagErrorMsgHepler}
                          >
                            <ErrorMessage name="keySystemName" />
                          </FormHelperText>

                          <Typography variant="subtitle2">
                            Filter Value *
                          </Typography>
                          <Select
                            fullWidth
                            value={values.values.valueSystemName}
                            MenuProps={{ classes: { paper: classes.Select } }}
                            onChange={(event: any) => {
                              values.setFieldValue(
                                "valueSystemName",
                                event.target.value
                              );
                            }}
                            IconComponent={() => (
                              <ExpandMoreIcon
                                className={classes.selectExpandIconHelper}
                              />
                            )}
                            variant="outlined"
                            disabled={
                              !props.canAddNewRecord ||
                              !!props.selectedStage.valueSystemName ||
                              !values.values.keySystemName
                            }
                          >
                            {values.values.keyFilterValuesData
                              ? values.values.keyFilterValuesData?.map(
                                  (o: any) => {
                                    return (
                                      <MenuItem
                                        key={o.valueSystemName}
                                        value={`${o.valueSystemName}`}
                                      >
                                        {o.valueSystemName}
                                      </MenuItem>
                                    );
                                  }
                                )
                              : props.selectedStage.filterValues?.map(
                                  (o: any) => {
                                    return (
                                      <MenuItem
                                        key={o.valueSystemName}
                                        value={`${o.valueSystemName}`}
                                      >
                                        {o.valueSystemName}
                                      </MenuItem>
                                    );
                                  }
                                )}
                          </Select>

                          {/* <MyTextField
                                                  name="valueSystemName"
                                                  type="text"
                                                  label="Filter Value"
                                                  value={values.values.valueSystemName}
                                                  onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                  ) => {
                                                    values.setFieldValue(
                                                      "valueSystemName",
                                                      event.target.value
                                                    );
                                                  }}
                                                /> */}
                          <FormHelperText
                            error={true}
                            className={classes.languagErrorMsgHepler}
                          >
                            <ErrorMessage name="valueSystemName" />
                          </FormHelperText>

                          {/* <Typography variant="subtitle2" style={{marginTop:'15px'}}>State Code</Typography>
                                                                        <Select fullWidth value={values.values.stateCode} MenuProps={{ classes: { paper: classes.Select } }}
                                                                            onChange={(event: any) => {
                                                                                values.setFieldValue('stateCode', event.target.value)
                                                                            }}
                                                                            style={{width:'21rem'}}
                                                                            IconComponent={() => <ExpandMoreIcon sx={{ position: 'absolute', right: '3px' }}/>}
                                                                            variant='outlined'>
                                                                            {props.stateList?.map((o:any) => {
                                                                                return <MenuItem key={o.stateShortCode}
                                                                                    value={`${o.stateShortCode}`}>{o.stateShortCode}</MenuItem>
                                                                            })}
                                                                        </Select>
                                                                        <FormHelperText error={true} className={classes.languagErrorMsgHepler}>
                                                                            <ErrorMessage name='stateCode' />
                                                                        </FormHelperText> */}

                          <Field name="states">
                            {({ form }: FieldProps) => {
                              const { setFieldValue } = form;
                              return (
                                <FormControl
                                  className={classes.formControlMrtHelper}
                                  fullWidth
                                >
                                  <Typography variant="subtitle2">
                                    Available States *
                                  </Typography>
                                  <MultiSelect
                                    name="stateId"
                                    showClearAll={true}
                                    labelKey="name"
                                    options={stateList}
                                    selectedOptions={latestSelectedStates.map(
                                      (state: any) => state.name
                                    )}
                                    onCheckboxChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      console.log(handleStateChange(event));
                                      setFieldValue(
                                        "states",
                                        handleStateChange(event)
                                      );
                                    }}
                                    onClearSelection={() => {
                                      handleClearStateSelection(false);
                                      setFieldValue("states", null);
                                    }}
                                  />
                                </FormControl>
                              );
                            }}
                          </Field>
                          <FormHelperText
                            error={true}
                            className={classes.languagErrorMsgHepler}
                          >
                            <ErrorMessage name="states" />
                          </FormHelperText>
                        </Box>
                        <Stack alignSelf="self-end" maxWidth={150}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            data-testid="add-filter"
                            disabled={
                              !(props.selectedStage.keySystemName
                                ? props.canUpdateExistingRecord
                                : props.canAddNewRecord)
                            }
                          >
                            {(props.selectedStage?.keySystemName && "Update") ||
                              "Add Filter"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                    <Divider orientation="vertical" flexItem />
                  </Stack>
                )}
              </Formik>
              {
                /**
                 * added filters will be listed here
                 */

                renderAddedFilters()
              }
            </Stack>
          </div>
        </Box>
        <>
          <Divider />
          <div className={classes.formActionFooterBtnHelper}>
            <Stack direction="row" spacing={2}>
              <ButtonWithIcon
                showCreateProgress={false}
                type={CANCEL_TYPE}
                label="Previous"
                onClick={() => {
                  props.onPrevious(STEPS.STEP5);
                }}
              />
              <ButtonWithIcon
                showCreateProgress={false}
                type={CREATE_TYPE}
                // label={props.ListOfStages.length ? "Create" : "Next"}
                label={
                  props.isEdit &&
                  props.actionType !== ActionType.ADD_TRANSLATION
                    ? "Update"
                    : "Create"
                }
                onClick={() => {
                  nextStepHandler(STEPS.STEP7);
                }}
              />
            </Stack>
          </div>
        </>
      </Box>
    </>
  );
};
