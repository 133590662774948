import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Menu,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  cropStatusList,
  frequencyData,
} from "../../Components/PushNotifications/AddEditNotification/PushNotificationAPICalls";
import {
  respondedStatusList,
  StatusType,
} from "../../Components/QueryResponse/QueryAPICalls";
import {
  Category,
  MediaType,
  RetailerCategory,
  RetailerType,
} from "../../Constants/contants";
import { RootState } from "../../redux/reducers";
import {
  CropDataType,
  CropRecordType,
  LanguageRecordType,
} from "../../redux/reducers/generic_reducer";
import CheckboxWithLabel from "../CheckboxWithLabel";
import RadioBtnWithLabel from "../RadioWithLabel";
import {
  ASSET_PATH,
  services,
  ServicesType,
} from "../../Components/Home/allServices";
import { useStyles } from "./styles";
import { FilterPropertyType } from "./type";

type FiltersAndSortProps = {
  hasFilter?: boolean;
  filters: FilterPropertyType;
  onChangeFilters: Function;
  onSort: Function;
  onSecondarySort?: Function;
  sortBy: string | undefined;
  secondarySortBy?: string | undefined;
  showLanguageSelection?: boolean;
  onLanguageSelect?: Function;
  showCropSelection?: boolean;
  onCropSelect?: Function;
  statusList?: StatusType[];
  cropList?: [];
  category?: string[];
  statesList?: any[];
  showStatestype?: boolean;
  showNotificationType?: boolean;
  onNotificationSelect?: Function;
  showModuleType?: boolean;
  onModuleSelect?: Function;
  showModuleList?: boolean;
  showFrequencyList?: boolean;
  showRespondedList?: boolean;
  showCropList?: boolean;
  showCategoryList?: boolean;
  showFrequencyType?: boolean;
  onFrequencySelect?: Function;
  showStatusList?: boolean;
  showStatusType?: boolean;
  onStatusSelect?: Function;
  showPublishOnSort?: boolean;
  showSentOnSort?: boolean;
  showSecondarySort?: boolean;
  showCropStatus?: boolean;
  showSheduledDateSort?: boolean;
  showLastUpdatedOn?: boolean;
  isResetFilter?: any;
  showMediaType?: boolean;
  showRetailerType?: boolean;
  showRetailerCategoryType?: boolean;
};

const FiltersAndSort: React.FC<FiltersAndSortProps> = ({
  hasFilter,
  filters,
  onChangeFilters,
  onSort,
  onSecondarySort,
  sortBy,
  showLanguageSelection,
  showCropSelection,
  showNotificationType,
  showRespondedList,
  showModuleList,
  showFrequencyList,
  showStatusList,
  showCropList,
  showCategoryList,
  showPublishOnSort,
  showSentOnSort,
  showSecondarySort,
  showCropStatus,
  statusList,
  cropList,
  category,
  showSheduledDateSort,
  showLastUpdatedOn,
  isResetFilter,
  showMediaType,
  showRetailerType,
  showRetailerCategoryType,
  statesList,
  showStatestype,
}: FiltersAndSortProps) => {
  const classes = useStyles();

  const storeData = useSelector((RootState: RootState) => {
    return {
      langCode: RootState.auth.languageCode,
      languages: RootState.generic.ListOfLanguages?.languageList,
      crops: RootState.generic.ListOfCrops.cropsList,
      notificationTypes: RootState.generic.ListOfNotification,
    };
  });
  const [properties, setProperties] =
    React.useState<FilterPropertyType>(filters);
  const [sortValue1, setSortValue1] = React.useState<
    number | string | undefined
  >();
  const [sortValue2, setSortValue2] = React.useState<
    number | string | undefined
  >();

  useEffect(() => {
    setProperties(filters);
  }, [filters]);

  let defaultFilterData: FilterPropertyType = {
    languageIds: [],
    status: [],
    notificationTypes: [],
    moduleNames: [],
    frequencyIds: [],
    crop: [],
    states: [],
    category: [],
    mediaType: [],
    retailerType: [],
    retailerCategory: [],
    responded: [],
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const getFilterCountForBadge = () => {
    let selectedFilterCount: any[] = [];

    try {
      delete properties.sortBy;
      delete properties.pageSize;
      delete properties.pageNumber;

      selectedFilterCount = _.reduce(
        properties,
        function (result: any, value: any, key: any) {
          result = result.concat(value);
          return result;
        },
        []
      );
    } catch (err) {
      console.log(
        "error while preparing count of selected filters",
        "getFilterCountForBadge"
      );
    }
    return selectedFilterCount.length;
  };
  const customBadge = () => {
    return (
      <div data-testid="length-badge" className={classes.customFilterBadge}>
        {getFilterCountForBadge()}
      </div>
    );
  };
  const updateProperties = (field: string, updatedData: any) => {
    setProperties((prevState) => ({
      ...prevState,
      [field]: updatedData,
    }));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    onChangeFilters(properties);
    setAnchorEl(null);
  };

  const getIndex = (array: Array<string>, value: string) => {
    return array.findIndex((item) => item === value);
  };

  const handlePropertiesUpdate = (
    value: string,
    data: Array<string>,
    propertyName: string
  ) => {
    const existingIndex: number = getIndex(data, value);
    if (existingIndex > -1) {
      data.splice(existingIndex, 1);
    } else {
      data.push(value);
    }
    updateProperties(propertyName, data);
  };

  /** Language filter selection handler*/
  const handleLanguageSelection = (value: string, checked: boolean) => {
    handlePropertiesUpdate(value, properties.languageIds!, "languageIds");
  };

  const handleStatesSelection = (value: string, checked: boolean) => {
    handlePropertiesUpdate(value, properties.states!, "states");
  };

  const handleCropSelection = (value: string, checked: boolean) => {
    handlePropertiesUpdate(value, properties.crop, "crop");
  };

  const handleCropDataSelection = (value: string, checked: boolean) => {
    handlePropertiesUpdate(value, properties.crop, "crop");
  };

  const handleCategoryDataSelection = (value: string, checked: boolean) => {
    handlePropertiesUpdate(value, properties.category, "category");
  };

  const handleCategory = (value: string, checked: boolean) => {
    handlePropertiesUpdate(value, [], "category");
  };

  /** Notification type filter selection handler*/
  const handleTypeSelection = (value: string, checked: boolean) => {
    handlePropertiesUpdate(
      value,
      properties.notificationTypes!,
      "notificationTypes"
    );
  };
  /** frequency filter selection handler*/
  const handleFrequencySelection = (value: string, checked: boolean) => {
    handlePropertiesUpdate(value, properties.frequencyIds!, "frequencyIds");
  };
  /** status filter selection handler*/
  const handleStatusSelection = (value: string, _checked: boolean) => {
    handlePropertiesUpdate(value, [], "status");
  };

  const handleRespondedSelection = (value: string, _checked: boolean) => {
    handlePropertiesUpdate(value, [], "responded");
  };

  const handleMediaType = (value: string, _checked: boolean) => {
    handlePropertiesUpdate(value, properties?.mediaType!, "mediaType");
  };

  const handleRetailerType = (value: string, _checked: boolean) => {
    handlePropertiesUpdate(value, [], "retailerType");
  };

  const handleRetailerCategory = (data: string) => {
    handlePropertiesUpdate(
      data.toLocaleUpperCase(),
      properties.retailerCategory!,
      "retailerCategory"
    );
  };
  /**Clear filter button handler */
  const clearFilterHandler = () => {
    setProperties(defaultFilterData);
    onChangeFilters(defaultFilterData);
    setAnchorEl(null);
  };
  /**Apply filter button handler */
  const applyFilterHandler = () => {
    onChangeFilters(properties);
    setAnchorEl(null);
  };
  /**Clear sort  handler */
  const clearSortHandler = () => {
    onSort(undefined);
    onSecondarySort?.(undefined);
    setAnchorEl(null);
  };
  /**since sort already applied: close sort handler */
  const applySortHandler = () => {
    onSort(sortValue1);
    onSecondarySort?.(sortValue2);
    setAnchorEl(null);
  };

  const renderLanguageTypeFilter = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Language
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            marginLeft: 1,
            flexDirection: "column",
            display: "flex",
          }}
        >
          {storeData.languages.map(
            (language: LanguageRecordType, index: number) => {
              return (
                <CheckboxWithLabel
                  name={`${language.languageName}:::${language.languageId}`}
                  checked={properties.languageIds!.includes(
                    `${language.languageName}:::${language.languageId}`
                  )}
                  label={language.languageName}
                  onChange={handleLanguageSelection}
                  key={`${language.languageCode}-${index}`}
                />
              );
            }
          )}
        </AccordionDetails>
        <AccordionDetails sx={{ marginLeft: 4, p: 0 }}></AccordionDetails>
      </Accordion>
    );
  };

  const renderStatesFilter = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            States
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            marginLeft: 1,
            flexDirection: "column",
            display: "flex",
          }}
        >
          {statesList?.map((state: any, index: number) => {
            return (
              <CheckboxWithLabel
                name={`${state?.stateName}:::${state?.stateId}`}
                checked={properties?.states!.includes(
                  `${state?.stateName}:::${state?.stateId}`
                )}
                label={state?.stateName}
                onChange={handleStatesSelection}
                key={`${state?.stateId}-${index}`}
              />
            );
          })}
        </AccordionDetails>
        <AccordionDetails sx={{ marginLeft: 4, p: 0 }}></AccordionDetails>
      </Accordion>
    );
  };

  const renderCropTypeFilter = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Crop
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            marginLeft: 1,
            flexDirection: "column",
            display: "flex",
            maxHeight: 200,
            overflow: "auto",
          }}
        >
          {[...storeData.crops]
            .sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .map((crop: CropRecordType) => {
              return (
                <CheckboxWithLabel
                  name={`${crop.name}:::${crop.id}`}
                  checked={properties.crop.includes(
                    `${crop.name}:::${crop.id}`
                  )}
                  label={crop.name}
                  onChange={handleCropSelection}
                  key={`${crop.id}`}
                />
              );
            })}
        </AccordionDetails>
        <AccordionDetails sx={{ marginLeft: 4, p: 0 }}></AccordionDetails>
      </Accordion>
    );
  };

  const renderNotificationType = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Notification Type
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {storeData.notificationTypes.map((notification, index: number) => {
            return (
              <CheckboxWithLabel
                name={notification.id}
                checked={properties.notificationTypes!.includes(
                  String(notification.id)
                )}
                label={notification.notificationTypeName}
                onChange={handleTypeSelection}
                key={`${notification}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderModuleList = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Module Name
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {services.map((service: ServicesType, index: number) => {
            return (
              <CheckboxWithLabel
                name={service.serviceName}
                checked={properties.notificationTypes!.includes(
                  service.serviceName
                )}
                label={service.serviceName}
                onChange={() => {}}
                key={`${service.serviceName}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderFrequency = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Frequency
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {frequencyData.map((frequency: any, index: number) => {
            return (
              <CheckboxWithLabel
                name={frequency.value}
                checked={properties.frequencyIds!.includes(frequency.value)}
                label={frequency.displayName}
                onChange={handleFrequencySelection}
                key={`${frequency.displayName}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderCropList = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Crop
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            marginLeft: 1,
            flexDirection: "column",
            display: "flex",
            maxHeight: 200,
            overflow: "auto",
          }}
        >
          {cropList!
            .sort((a: any, b: any) =>
              a.nameTranslation.localeCompare(
                b.nameTranslation,
                storeData.langCode ?? "en"
              )
            )
            .map((data: CropDataType, index: number) => {
              return (
                <CheckboxWithLabel
                  name={`${data.nameTranslation}`}
                  checked={properties.crop.includes(
                    String(data.nameTranslation)
                  )}
                  label={data.nameTranslation}
                  onChange={handleCropDataSelection}
                  key={`${data.id}-${index}`}
                />
              );
            })}
        </AccordionDetails>
        <AccordionDetails sx={{ marginLeft: 4, p: 0 }}></AccordionDetails>
      </Accordion>
    );
  };

  const renderCategoryList = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Category
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            marginLeft: 1,
            flexDirection: "column",
            display: "flex",
          }}
        >
          {category?.map((category: string, index: number) => {
            return (
              <CheckboxWithLabel
                name={`${category}`}
                checked={properties.category.includes(String(category))}
                label={category}
                onChange={handleCategoryDataSelection}
                key={`${category}-${index}`}
              />
            );
          })}
        </AccordionDetails>
        <AccordionDetails sx={{ marginLeft: 4, p: 0 }}></AccordionDetails>
      </Accordion>
    );
  };

  const renderStatus = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Status
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {statusList!.map((status: any, index: number) => {
            return (
              <RadioBtnWithLabel
                name={status.value}
                checked={properties.status!.includes(status.value)}
                label={status.displayName}
                onChange={handleStatusSelection}
                key={`${status.displayName}-${index}`}
                value={status.value}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderCropStatus = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Status
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {cropStatusList.map((status: any, index: number) => {
            return (
              <RadioBtnWithLabel
                name={status.value}
                checked={properties.status!.includes(status.value)}
                label={status.displayName}
                value={status.value}
                onChange={handleStatusSelection}
                key={`${status.displayName}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderResponded = () => {
    return (
      <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            Responded
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {respondedStatusList.map((status: any, index: number) => {
            return (
              <RadioBtnWithLabel
                name={status.value}
                checked={properties.responded!.includes(status.value)}
                label={status.displayName}
                value={status.value}
                onChange={handleRespondedSelection}
                key={`${status.displayName}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  /**to reset filter and sort data on tab change */
  useEffect(() => {
    setProperties(defaultFilterData);
    setSortValue2(undefined);
  }, [isResetFilter]);

  useEffect(() => {
    if (sortBy) setSortValue1(sortBy);
  }, [sortBy]);

  const renderSortDropDown = (sortName: any) => {
    return (
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
          style={{ marginRight: "8px" }}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            {sortName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {[
            { key: "1", value: "Descending" }, //key changed to string
            { key: "0", value: "Ascending" },
          ].map((obj: any, index: number) => {
            return (
              <RadioBtnWithLabel
                name={sortName}
                checked={sortValue1 === obj.key || false}
                label={obj.value}
                value={obj.key}
                onChange={(value: number | string) => setSortValue1(value)}
                key={`${obj.val}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };
  const renderSecondarySort = (sortName: any) => {
    return (
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
          style={{ marginRight: "8px" }}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            {sortName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {[
            { key: "1", value: "Decending" },
            { key: "0", value: "Ascending" },
          ].map((obj: any, index: number) => {
            return (
              <RadioBtnWithLabel
                name={sortName}
                checked={sortValue2 === obj.key || false}
                label={obj.value}
                value={obj.key}
                onChange={(value: number | string) => setSortValue2(value)}
                key={`${obj.val}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderMediaTypeDropdown = () => {
    return (
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
          style={{ marginRight: "8px" }}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            {"Media Type"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {MediaType.map((obj: any, index: number) => {
            return (
              <CheckboxWithLabel
                name={obj.key}
                checked={properties.mediaType!.includes(obj.key)}
                label={obj.label}
                onChange={() => handleMediaType(obj.key, true)}
                key={`${obj.label}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderCategory = () => {
    return (
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
          style={{ marginRight: "8px" }}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            {"Category"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {Category.map((obj: any) => (
            <RadioBtnWithLabel
              name={"Category"}
              checked={properties.category.indexOf(obj.key) > -1}
              label={obj.label}
              value={obj.key}
              onChange={handleCategory}
              key={obj.label}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderRetailerType = () => {
    return (
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
          style={{ marginRight: "8px" }}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            {"Type"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {RetailerType.map((obj: any, index: number) => {
            return (
              <RadioBtnWithLabel
                name={"Type"}
                checked={properties.retailerType!.indexOf(obj.label) > -1}
                label={obj.label}
                value={obj.label}
                onChange={handleRetailerType}
                key={`${obj.label}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderRetailerCategory = () => {
    return (
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="inherit" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.accordionSummeryHelper}
          style={{ marginRight: "8px" }}
        >
          <Typography variant="subtitle2" sx={{ paddingLeft: 2 }}>
            {"Category"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ marginLeft: 1, flexDirection: "column", display: "flex" }}
        >
          {RetailerCategory.map((obj: any, index: number) => {
            return (
              <CheckboxWithLabel
                name={obj.value}
                checked={
                  properties.retailerCategory!.indexOf(
                    obj.value.toLocaleUpperCase()
                  ) > -1
                }
                label={obj.label}
                onChange={(e: any) => handleRetailerCategory(e)}
                key={`${obj.label}-${index}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <ButtonGroup
      className={classes.buttonGroupHelper}
      variant="text"
      aria-label="text button group"
    >
      {/* -------------------------- PushNotification Filter Menu Start ------------------------------- */}
      {(hasFilter || hasFilter === undefined) && (
        <Button
          component="span"
          color="inherit"
          disableElevation
          disableRipple
          disableTouchRipple
          disableFocusRipple
          sx={{ "&:hover": { background: "none" } }}
        >
          <Button
            color="inherit"
            className={classes.buttonHelper}
            endIcon={<img src={`${ASSET_PATH}/filtersIcon.svg`} alt="" />}
            onClick={handleClick}
            id="filter"
            data-testid="filter"
          >
            <Typography
              variant="subtitle2"
              sx={{ display: "flex", alignItems: "center" }}
            >
              Filters{" "}
            </Typography>
          </Button>
          {customBadge()}
        </Button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl?.id === "filter"}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{ maxHeight: "350px", minWidth: "300px", overflowX: "auto" }}
        >
          {showLanguageSelection && renderLanguageTypeFilter()}
          {showCropSelection && renderCropTypeFilter()}
          {showStatestype && renderStatesFilter()}
          {showNotificationType && renderNotificationType()}
          {showModuleList && renderModuleList()}
          {showFrequencyList && renderFrequency()}
          {showCropList && renderCropList()}
          {showCategoryList && renderCategoryList()}
          {showStatusList && renderStatus()}
          {showCropStatus && renderCropStatus()}
          {showMediaType && renderCategory()}
          {showMediaType && renderMediaTypeDropdown()}
          {showRetailerType && renderRetailerType()}
          {showRetailerCategoryType && renderRetailerCategory()}
          {showRespondedList && renderResponded()}
          <Divider />
          <Box p={2} pb={0}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                color="success"
                fullWidth
                sx={{ borderColor: "#14803C" }}
                onClick={clearFilterHandler}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={applyFilterHandler}
              >
                Apply
              </Button>
            </Stack>
          </Box>
        </div>
      </Menu>
      <Box
        sx={{
          borderLeft: "1px solid #CFD3DB",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          color="inherit"
          className={classes.buttonHelper}
          endIcon={<SortOutlinedIcon />}
          onClick={handleClick}
          id="sort"
        >
          <Typography variant="subtitle2">Sort</Typography>
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl?.id === "sort"}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{ maxHeight: "350px", minWidth: "250px", overflowX: "auto" }}
        >
          {showSheduledDateSort && renderSortDropDown("Scheduled Date")}
          {showPublishOnSort && renderSortDropDown("Published On")}
          {showSentOnSort && renderSortDropDown("Sent on")}
          {showSecondarySort && renderSecondarySort("Receiver Count")}
          {showLastUpdatedOn && renderSortDropDown("Last Updated On")}
          <Divider />
          {/* <FilterButtons /> */}
          <Box p={2} pb={0}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="outlined"
                color="success"
                fullWidth
                sx={{ borderColor: "#14803C" }}
                onClick={clearSortHandler}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={applySortHandler}
              >
                Apply
              </Button>
            </Stack>
          </Box>
        </div>
      </Menu>
      {/* -------------------------- PushNotification Sort Menu End ------------------------------- */}
    </ButtonGroup>
  );
};

export default FiltersAndSort;
