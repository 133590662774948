import { Modal, Paper, Box, Stack, Typography, Divider, Button } from '@mui/material';
import React from 'react';
import ModalHeader from '../Modal/ModalHeader';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useStyles } from "./style";
import { TableComponent } from '../BasicDataTable/BasicDataTable';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';


interface IknowMoreDetailsProps {
    handleKnowMoreClose: Function;
    title: string;
    tableHeaders: Array<any>;
    tableData: any;
    openBulkUploadPopup: Function;
}


const knowMoreDetails: React.FC<any> = (props: IknowMoreDetailsProps) => {
    const classes = useStyles();
    return (
        <>
            <Modal open={true} onClose={() => { }}>
                <Paper className={classes.formPaper}>
                    <ModalHeader header={`File: ${props.title} Review`} onClose={() => { props.handleKnowMoreClose(false) }} />
                    <Divider orientation='horizontal' flexItem />
                    <Box className={classes.knowMoreContainerHelper}>
                        <Box maxWidth={550} mx='auto' className={classes.knowMoreErrorMsgHelper} >
                            <Paper elevation={3} >
                                <Box height={80} px={4} className='msgContainer'>
                                    <Stack direction='row' alignItems='start'>
                                        <Box mr={2}><ErrorOutlineIcon /></Box>
                                        <Stack direction='column' >
                                            <Typography variant="subtitle2" fontSize={16} className='errorTitle'>Error found in uploaded file</Typography>
                                            <Typography variant="body1" fontSize={14}>Please fix the error(s) listed below and upload the file again</Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Paper>
                        </Box>
                        <Box>
                            <TableComponent headers={props.tableHeaders} data={props.tableData || []} />
                        </Box>
                    </Box>

                    <Box className='reuploadBtn'>
                        <Divider orientation='horizontal' flexItem />
                        <Button startIcon={<PublishOutlinedIcon />} variant='contained' color='success'
                        onClick={()=> props.openBulkUploadPopup()}>Reupload</Button>
                    </Box>

                </Paper>
            </Modal>
        </>
    )
}

export default knowMoreDetails;
