import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import { useState } from "react";
import { GetImageURLMappedWithCROP } from "../../../Api/generic_apicalls";
import ButtonWithIcon, {
  CANCEL_TYPE,
  CREATE_TYPE,
} from "../../../Common/Buttons";
import SortableComponent from "../../../Common/SortableRecords";
import { UploadFile } from "../../../Common/UploadFile";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { AgronomySchemaValidation } from "../HybridValidationSchema";
import { useStyles } from "../style";
import { AgronomyKeys, InitialPractices } from "./index";
import {
  ModuleImageUpload,
  STEPS,
  ValidationType,
} from "../../../Common/Constants";
import { genericFormFieldValidation } from "../../../Utils/fileHelper";

type AgronomyProps = {
  ListOfStages: Array<AgronomyKeys>;
  selectedStage: AgronomyKeys;
  onSelect: Function;
  getLatestImgURL: Function;
  onSave: Function;
  onRemove: Function;
  onNext: Function;
  onPrevious: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  systemName: string;
  updateListOrder: Function;
  isEdit: boolean;
  hybridSystemName: string;
  cropSystemName: string;
  actionType: string;
  isPrimaryProduct: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
  isUpdateField: boolean;
};

export const MyTextField = ({ label, ...props }: any) => {
  const classes = useStyles();
  const [field] = useField(props);
  return (
    <>
      <Typography variant="subtitle2" className={classes.label}>
        {`${label} *`}
      </Typography>
      <TextField {...field} {...props} fullWidth></TextField>
    </>
  );
};

export const TextArea = ({ label, ...props }: any) => {
  const classes = useStyles();
  const [field] = useField(props);

  return (
    <>
      <Typography variant="subtitle2" className={classes.label}>
        {`${label} *`}
      </Typography>
      <Typography fontSize="14px" color="#696F88">
        Max. 1000 characters
      </Typography>
      <TextareaAutosize
        {...field}
        {...props}
        className={classes.textAreaStyles}
      ></TextareaAutosize>
    </>
  );
};

export const AgronomyPracticesForm: React.FC<AgronomyProps> = (
  props: AgronomyProps
) => {
  const classes = useStyles();
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

  const nextStepHandler = (step: number) => {
    props.onNext(step);
  };

  const renderAddedPractices = () => {
    if (props.ListOfStages.length === 0) {
      return (
        <>
          {" "}
          <Stack flex={1} width={400}>
            <Box>
              <Typography variant="subtitle2">
                Added Agronomy Practice(s):
              </Typography>
              <Typography variant="caption" component="span">
                You may reorder the stages by dragging each panel up or down
              </Typography>
              <FormHelperText error={true}>
                {isRuleErrorFlag && (
                  <>{"Please add one practice to continue"}</>
                )}
              </FormHelperText>
            </Box>
          </Stack>
        </>
      );
    }
    return (
      <>
        <Stack flex={1} width={400}>
          <Box>
            <Typography variant="subtitle2">
              Added Agronomy Practice(s):
            </Typography>
            <Typography variant="caption" component="span">
              You may reorder the stages by dragging each panel up or down
            </Typography>
            <FormHelperText error={true}></FormHelperText>
            <Box my={2}></Box>
            <SortableComponent
              name={"Agronomy Practices"}
              items={props.ListOfStages}
              updateCropStageOrder={(newList: Array<AgronomyProps>) => {
                props.updateListOrder(newList);
              }}
              key={props.ListOfStages.length}
              listProps={props}
              displayKey={"systemName"}
              initialState={InitialPractices}
              selectedRecord={props.selectedStage}
              secMatchKey={""}
              // isDisabled={
              //   !props.isPrimaryProduct &&
              //   props.actionType !== ActionType.CREATE
              // }
              showRemoveButton={props.isPrimaryProduct}
              isDraggable={true}
            />
          </Box>
        </Stack>
      </>
    );
  };

  const submitGeneralDetails = (data: AgronomyProps) => {
    props.onSave(data);
    props.updateUniqueKey();
  };

  return (
    <>
      <Box>
        <Box className={classes.formContainer}>
          <div className={classes.formContainerChildDivStylesHepler}>
            <Stack direction="row" spacing={2}>
              <Formik
                initialValues={{
                  displayOder: props.selectedStage.displayOrder,
                  heading: props.selectedStage.heading,
                  id: props.selectedStage.id ?? null,
                  media: props.selectedStage.media,
                  systemName: props.selectedStage.systemName,
                  text: props.selectedStage.text,
                  imagePath: props.selectedStage.imagePath,
                }}
                validationSchema={AgronomySchemaValidation}
                onSubmit={(values: any, _onSubmitProps: any) => {
                  if (
                    checkForDuplicates(
                      props.ListOfStages,
                      { systemName: values!.systemName },
                      values!.systemName,
                      props.selectedStage.systemName
                    )
                  ) {
                    _onSubmitProps.setFieldError(
                      "systemName",
                      "Duplicate system name"
                    );
                    _onSubmitProps.setSubmitting(false);
                    return;
                  }
                  let data: any = values;
                  if (
                    values.imagePath &&
                    typeof values.imagePath === "object"
                  ) {
                    /** Generic Image Upload api call for combination of Current module Sysytem Name
                     *  and selected CROP system name
                     */
                    GetImageURLMappedWithCROP(
                      values.imagePath,
                      props.hybridSystemName,
                      ModuleImageUpload.HYBRID_AP,
                      props.cropSystemName,
                      "hybridSystemName",
                      (response: any) => {
                        values.imagePath = response.data.imageUrl;
                        props.getLatestImgURL(response.data.imageUrl);
                        submitGeneralDetails(data);
                        _onSubmitProps.resetForm();
                      },
                      () => {
                        _onSubmitProps.setFieldError(
                          "imagePath",
                          "Unable to upload file."
                        );
                        _onSubmitProps.setSubmitting(false);
                        return;
                      }
                    );
                  } else {
                    submitGeneralDetails(data);
                    _onSubmitProps.resetForm();
                  }
                }}
                enableReinitialize={true}
              >
                {({ values, setFieldValue }) => (
                  <Stack
                    flex={1}
                    justifyContent="flex-end"
                    direction="row"
                    gap={2}
                    width={400}
                  >
                    <Form>
                      <Stack spacing={2} minWidth={350}>
                        <Box>
                          {/* <MyTextField name="systemName" type="text" label="Agronomy System Name"
                                                        onChange={(
                                                            event: React.ChangeEvent<HTMLInputElement>
                                                        ) => {
                                                            setFieldValue('systemName', event.target.value.toUpperCase());
                                                        }}
                                                        disabled={props.canAddNewRecord ? (props.selectedStage.systemName ? true : false) : true}
                                                    />
                                                    <FormHelperText error={true} className={classes.languagErrorMsgHepler}>
                                                        <ErrorMessage name='systemName' />
                                                    </FormHelperText> */}
                          <Field
                            name="systemName"
                            validate={
                              props.selectedStage.systemName
                                ? null
                                : (value: string) =>
                                    genericFormFieldValidation(
                                      value,
                                      ValidationType.SYS_NM
                                    )
                            }
                          >
                            {() => {
                              return (
                                <FormControl fullWidth>
                                  <Typography variant="subtitle2">
                                    Agronomy System Name *
                                  </Typography>
                                  <TextField
                                    name="systemName"
                                    onChange={(event: any) => {
                                      setFieldValue(
                                        "systemName",
                                        event.target.value
                                          .trim()
                                          .toLocaleUpperCase()
                                      );
                                    }}
                                    defaultValue={values.systemName}
                                    value={values?.systemName}
                                    disabled={
                                      props.canAddNewRecord
                                        ? !!props.selectedStage.systemName
                                        : true
                                    }
                                  />
                                </FormControl>
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="systemName" />
                          </FormHelperText>
                          <MyTextField
                            name="heading"
                            type="text"
                            label="Agronomy Heading"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFieldValue("heading", event.target.value);
                            }}
                          />
                          <FormHelperText
                            error={true}
                            className={classes.languagErrorMsgHepler}
                          >
                            <ErrorMessage name="heading" />
                          </FormHelperText>

                          <TextArea
                            name="text"
                            as="textarea"
                            label="Agronomy Description"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFieldValue("text", event.target.value);
                            }}
                          />
                          <FormHelperText
                            error={true}
                            className={classes.languagErrorMsgHepler}
                          >
                            <ErrorMessage name="text" />
                          </FormHelperText>

                          <Field name="imagePath">
                            {() => {
                              return (
                                <UploadFile
                                  name="imagePath"
                                  acceptedFileTypes={[
                                    "image/png",
                                    "image/jpg",
                                    "image/jpeg",
                                  ]}
                                  isRequired={false}
                                  maxFileSize={5}
                                  imageUrl={values.imagePath}
                                  onChange={(data: any) =>
                                    setFieldValue("imagePath", data)
                                  }
                                  key={values.imagePath}
                                  canRemoveOrReplace={props.canAddNewRecord}
                                />
                              );
                            }}
                          </Field>
                          <FormHelperText
                            error={true}
                            className={classes.languagErrorMsgHepler}
                          >
                            <ErrorMessage name="imagePath" />
                          </FormHelperText>
                        </Box>
                        <Stack alignSelf="self-end" maxWidth={150}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            disabled={
                              !(props.selectedStage.systemName
                                ? props.canUpdateExistingRecord
                                : props.canAddNewRecord)
                            }
                          >
                            {(props.selectedStage?.systemName && "Update") ||
                              "Add Practice"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                    <Divider orientation="vertical" flexItem />
                  </Stack>
                )}
              </Formik>
              {
                /**
                 * added practices will be listed here
                 */

                renderAddedPractices()
              }
            </Stack>
          </div>
        </Box>
        <>
          <Divider />
          <div className={classes.formActionFooterBtnHelper}>
            <Stack direction="row" spacing={2}>
              <ButtonWithIcon
                showCreateProgress={false}
                type={CANCEL_TYPE}
                label="Previous"
                onClick={() => {
                  props.onPrevious(STEPS.STEP3);
                }}
              />
              <ButtonWithIcon
                showCreateProgress={false}
                type={CREATE_TYPE}
                label="Next"
                onClick={() => {
                  // props.ListOfStages.length ? nextStepHandler(STEPS.STEP5) : setIsRuleErrorFlag(true);
                  nextStepHandler(STEPS.STEP5);
                }}
              />
            </Stack>
          </div>
        </>
      </Box>
    </>
  );
};
