import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { formatDate } from "../../../Utility/DateFormat";
import "../overrides.css";
import { useStyles } from "./style";
type ResponseViewProps = {
  ViewAPIResponse: any;
  activeTab: any;
};
const ResponseView: React.FC<ResponseViewProps> = (
  props: ResponseViewProps
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ViewResponse, setViewResponse] = useState<any>();
  const [showGrowerMediaURL, setShowGrowerMediaURL] = useState<string>("");
  const [showSRMediaURL, setShowSRMediaURL] = useState<string>("");

  useEffect(() => {
    setViewResponse(props.ViewAPIResponse);
  });

  const renderMediaImageList = (queryMediaList: [], type: string) => {
    return queryMediaList?.length > 0 ? (
      <div className={classes.carouselDiv}>
        <Carousel showThumbs={false} width="90%" className={classes.carousel}>
          {queryMediaList.map((media: any, index: number) => {
            return (
              <div className={classes.flexStyleCarousel}>
                {media.type === "AUDIO" ? (
                  <div className={classes.playerDiv}>
                    <ReactPlayer
                      className="react-player"
                      //className={classes.ImageStyle}
                      url={media.url}
                      width="500px"
                      height="150px"
                      playing={false}
                      controls={true}
                      onStart={() =>
                        type === "Grower"
                          ? setShowGrowerMediaURL("")
                          : setShowSRMediaURL("")
                      }
                    />
                  </div>
                ) : media.type === "IMAGE" ? (
                  <img src={media.url} className={classes.ImageStyle} />
                ) : (
                  <div className={classes.carouselDiv}>
                    {/* <Typography variant="subtitle2">
                      {t("str_video")}
                    </Typography> */}
                    <div className={classes.playerWrapper}>
                      <ReactPlayer
                        className="react-player"
                        url={media.url}
                        playing={false}
                        controls={true}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    ) : null;
  };

  const renderMediaURL = (type: string) => {
    const mediaURL = type === "Grower" ? showGrowerMediaURL : showSRMediaURL;
    if (mediaURL !== "") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {mediaURL.endsWith(".mp4") ? (
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={mediaURL}
                playing={false}
                controls={true}
              />
            </div>
          ) : (
            <img src={mediaURL} className={classes.mediaImageStyle} />
          )}
        </div>
      );
    }
  };

  return (
    <>
      {ViewResponse !== null &&
        ViewResponse?.queryConversations.length > 0 &&
        ViewResponse?.queryConversations.map((item: any, index: number) => {
          if (index === props.activeTab) {
            return (
              <Box style={{ height: "600px", overflow: "auto" }}>
                {item.queryGrowerMessage !== null && (
                  <Box
                    sx={{ backgroundColor: "#F3F4F6", pl: 4, paddingBottom: 5 }}
                  >
                    <Box className={classes.flexStyle}>
                      <Typography variant="body2" pt={3} pb={1} pr={1}>
                        {t("str_date_time")}
                      </Typography>
                      <Typography variant="body2" pt={3} pb={1} pr={1}>
                        {" "}
                        {formatDate(
                          item.queryGrowerMessage.lastUpdatedDate,
                          "DD/MM/YYYY, hh:mm A"
                        )}
                      </Typography>
                    </Box>
                    <Box className={classes.flexStyle}>
                      <Typography
                        variant="subtitle2"
                        className={classes.ResponseHeader}
                      >
                        {t("str_grower_message")}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.messageStyle}
                      >
                        {item.queryGrowerMessage.message}
                      </Typography>
                    </Box>

                    {renderMediaImageList(
                      item.queryGrowerMessage.queryMediaList,
                      "Grower"
                    )}

                    {renderMediaURL("Grower")}
                  </Box>
                )}
                {item.querySrResponse !== null && (
                  <Box sx={{ backgroundColor: "#FFFFFF", pl: 4 }}>
                    <Box className={classes.flexStyle}>
                      <Typography variant="body2" pt={3} pb={1} pr={1}>
                        {t("str_date_time")}
                      </Typography>
                      <Typography variant="body2" pt={3} pb={1} pr={1}>
                        {formatDate(
                          item.querySrResponse.lastUpdatedDate,
                          "DD/MM/YYYY, hh:mm A"
                        )}
                      </Typography>
                    </Box>
                    <Box className={classes.flexStyle}>
                      <Typography
                        variant="subtitle2"
                        className={classes.ResponseHeader}
                      >
                        {t("str_support_message")}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.messageStyle}
                      >
                        {item.querySrResponse !== null &&
                          item.querySrResponse.message}
                      </Typography>
                    </Box>
                    {renderMediaImageList(
                      item.querySrResponse.queryMediaList,
                      "SR"
                    )}

                    {renderMediaURL("SR")}
                  </Box>
                )}
              </Box>
            );
          } else {
            return null;
          }
        })}
    </>
  );
};

export default ResponseView;
