import {
    Box, FormControl,
    FormHelperText,
    TextareaAutosize,
    TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import SortableComponent from '../../../Common/SortableRecords';
import { checkForDuplicates } from '../../../Utils/genericUtils';
import { DosAndDontType, initialDoAndDont } from './';
import { useStyles } from './styles';
import { ValidationSchemaDosandDonts } from './validationSchema';
import { ActionType, DO_DONT, STEPS, ValidationType } from '../../../Common/Constants';
import { genericFormFieldValidation } from '../../../Utils/fileHelper';
import RenderField from "../../../Common/RenderField";


interface DosandDontsProps {
    onNext: Function;
    onPrevious: Function;
    updateListOrder: Function;
    listOfDoAndDont: Array<DosAndDontType>;
    selectedDoAndDont: DosAndDontType;
    onSave: Function;
    onRemove: Function;
    onSelect: Function;
    updateUniqueKey: Function;
    uniqueKey: number;
    isEdit: boolean;
    actionType: string;
    isPrimaryCP: boolean;
    isSystemNameEditable: boolean;
    canAddNewRecord: boolean;
    canUpdateExistingRecord: boolean;
}

const DosandDonts: React.FC<DosandDontsProps> = (props: DosandDontsProps) => {
    // variables
    const classes = useStyles();

    const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

    /** Drag and Drop Menu lsiting */
    const renderDoAndDontList = () => {
        return <>
            <SortableComponent
                name={'DoAndDont'}
                items={props.listOfDoAndDont}
                updateCropStageOrder={(newList: Array<DosAndDontType>) => {
                    props.updateListOrder(newList)
                }}
                key={props.listOfDoAndDont.length}
                listProps={props}
                displayKey={'systemName'}
                initialState={initialDoAndDont}
                selectedRecord={props.selectedDoAndDont}
                isDisabled={!props.isPrimaryCP && props.actionType !== ActionType.CREATE}
            />
        </>
    }
    return (
        <Formik
            initialValues={{
                type: props.selectedDoAndDont.type,
                systemName: props.selectedDoAndDont.systemName,
                text: props.selectedDoAndDont.text,
            }}
            validationSchema={ValidationSchemaDosandDonts}
            onSubmit={(values: any, _onSubmitProps: any) => {
                if (checkForDuplicates(props.listOfDoAndDont, { systemName: values!.systemName }, values!.systemName, props.selectedDoAndDont.systemName)) {
                    _onSubmitProps.setFieldError('systemName', 'Duplicate system name');
                    _onSubmitProps.setSubmitting(false);
                    return;
                } else {
                    _onSubmitProps.setSubmitting(false);
                    props.onSave(values);
                    _onSubmitProps.resetForm();
                    props.updateUniqueKey();
                    setIsRuleErrorFlag(false);
                }
            }}
            enableReinitialize={true}
        >
            {({ values, setFieldValue }) => (
                <>
                    <Form className={classes.formOutline}>
                        <Box className={classes.formContainer}>
                            <Box>
                                <div className={classes.formContainerChildDivHelper}>
                                    <Stack direction='row' spacing={2}>
                                        <Stack flex={1} width={400} justifyContent='flex-start'
                                            borderRight='1px solid #C2C7D0' paddingRight={2}>
                                            <Field name='type'>
                                                {
                                                    () => {
                                                        return (
                                                            <FormControl className={classes.formMarginHelper} fullWidth>
                                                                <RenderField
                                                                    key={'type'}
                                                                    name='type'
                                                                    labelKey='type'
                                                                    options={DO_DONT}
                                                                    label='Type *'
                                                                    value={values.type}
                                                                    onChange={(name: string, value: any) => {
                                                                        setFieldValue(name, value);
                                                                    }}
                                                                    isDisabled={props.canAddNewRecord ? (!!props.selectedDoAndDont.systemName) : true}
                                                                />
                                                            </FormControl>
                                                        );
                                                    }
                                                }
                                            </Field>
                                            <FormHelperText error={true}>
                                                <ErrorMessage name='type' />
                                            </FormHelperText>
                                            <Field
                                                name='systemName'
                                                validate={props.selectedDoAndDont.systemName ? null : (value: string) => genericFormFieldValidation(value, ValidationType.SYS_NM)}
                                            >
                                                {
                                                    () => {
                                                        return (
                                                            <FormControl className={classes.formMarginHelper} fullWidth>
                                                                <Typography variant='subtitle2'>
                                                                    Guideline System Name *
                                                                </Typography>
                                                                <TextField name='systemName'
                                                                    onChange={(event: any) => {
                                                                        setFieldValue('systemName', event.target.value.trim().toLocaleUpperCase());
                                                                    }}
                                                                    defaultValue={values.systemName}
                                                                    value={values?.systemName}
                                                                    disabled={props.canAddNewRecord ? (!!props.selectedDoAndDont.systemName) : true}
                                                                />
                                                            </FormControl>
                                                        );
                                                    }
                                                }
                                            </Field>
                                            <FormHelperText error={true}>
                                                <ErrorMessage name='systemName' />
                                            </FormHelperText>
                                            <Field name='text'>
                                                {
                                                    () => {
                                                        return (
                                                            <FormControl className={classes.formMarginHelper} fullWidth>
                                                                <Typography variant='subtitle2'>
                                                                    Guideline Description *
                                                                </Typography>
                                                                <TextareaAutosize
                                                                    onBlur={(event) => {
                                                                        setFieldValue('text', event.target.value);
                                                                    }}
                                                                    name="text"
                                                                    className={classes.textAreaStyles}
                                                                    maxLength={1024}
                                                                    defaultValue={values.text}
                                                                    key={values.text}
                                                                />
                                                            </FormControl>
                                                        );
                                                    }
                                                }
                                            </Field>
                                            <FormHelperText error={true}>
                                                <ErrorMessage name='text' />
                                            </FormHelperText>
                                            <Stack alignSelf='self-end' mt={2}>
                                                <Button type='submit' data-testid="submit-guideline" variant='contained' color='success'
                                                    disabled={!(props.selectedDoAndDont.systemName ? props.canUpdateExistingRecord : props.canAddNewRecord)}>
                                                    {props.selectedDoAndDont.systemName && 'Update Guideline' || 'Add Guideline'}
                                                </Button>
                                            </Stack>
                                        </Stack>
                                        <Stack flex={1} width={400}>
                                            <Box>
                                                <Typography variant='subtitle2'>Added Guideline(s):</Typography>
                                                <Typography variant='caption' component='span'>You may reorder the
                                                    Guidelines by dragging each panel up or down</Typography>
                                                <Box my={2}>
                                                    <FormHelperText error={true}>
                                                        {isRuleErrorFlag &&
                                                            <>
                                                                Create at least one record to proceed
                                                            </>
                                                        }
                                                    </FormHelperText>
                                                    {renderDoAndDontList()}
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </div>
                            </Box>
                        </Box>

                        <Divider />
                        <div className={classes.formFooterBtnActionHepler}>
                            <Stack direction='row' spacing={2}>

                                <Button
                                    variant="outlined"
                                    color="info"

                                    onClick={() => props.onPrevious(STEPS.STEP1)}
                                    className={classes.buttonHelperNext}
                                >
                                    Previous
                                </Button>
                                <Button color='success' variant="contained" className={classes.buttonHelperNext}
                                    type='button'
                                    onClick={() => {
                                        //props.listOfDoAndDont.length ? props.onNext(STEPS.STEP3) : setIsRuleErrorFlag(true);
                                        props.onNext(STEPS.STEP3)
                                    }}
                                >Next</Button>

                            </Stack>
                        </div>
                    </Form>
                </ >
            )}

        </Formik>
    );
};

export default DosandDonts;
