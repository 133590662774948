import { Button } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "../../Constants/routeConstants";
import {
    InnerBox,
    OuterBox,
    SuccessMessage,
    SuccessMessageDescription
} from "./style";
export const SuccessPage = () => {
    let navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    const navigateToPreviousPage = () => {
        switch (decodeURI(location.pathname)) {
            case '/success/pushnotification':
            case '/success/pushnotification/update':
                navigation(RoutePaths.PushNotifications);
                break;
            case '/success/crops/created':
            case '/success/crops/updated':
                navigation(RoutePaths.Crops);
                break;
            case '/success/threats/created':
            case '/success/threats/updated':
                navigation(RoutePaths.Threats);
                break;
            case '/success/hybrids/created':
            case '/success/hybrids/updated':
                navigation(RoutePaths.Hybrids);
                break;
            case '/success/dashboard/created':
            case '/success/dashboard/updated':
                navigation(RoutePaths.DashboardMedia);
                break;
            case '/success/retailers/created':
            case '/success/retailers/updated':
                navigation(RoutePaths.RetailersAndNurseries);
                break;
            case '/success/Cp Product/created':
            case '/success/Cp Product/updated':
                navigation(-1);
                break;
            case '/success/Plantix Cp Products/created':
            case '/success/Plantix Cp Products/updated':
                navigation(-1);
                break;
            default:
                navigation('/');
        }
    }

    const getMessage = () => {
        let message: string = '';
        switch (params.mode) {
            case 'created':
                message = 'created';
                break;
            case 'updated':
                message = 'updated';
                break;
            case 'published':
                message = 'published';
                break;
            default:
                message = '';
        }
        return <>{`Data successfully ${message}`}</>;
    }
    return (
        <OuterBox className="OuterBox">
            <InnerBox>
                <img
                    width={100}
                    src={process.env.PUBLIC_URL + "/assets/publishsuccess-1.svg"}
                />
                <SuccessMessage>{getMessage()}</SuccessMessage>
                <SuccessMessageDescription>
                    Please visit publish module to publish this record
                </SuccessMessageDescription>
                <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ my: "1rem" }}
                    onClick={() => navigateToPreviousPage()}
                >
                    Back
                </Button>
            </InnerBox>
        </OuterBox>
    );
};
