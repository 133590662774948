import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, ErrorMessageDescription, InnerBox, OuterBox, SuccessMessage, SuccessMessageDescription } from './style';
type CreateSuccessFailPageProps = {
    handleGoBack: Function;
    message: string;
    messagedescription: string;
};
const NotificationSuccessfulCreation = (props: CreateSuccessFailPageProps) => {
    const { message, messagedescription } = props;
    const navigate = useNavigate();
    return (
        <OuterBox className='OuterBox'>
            <InnerBox>
                <img width={100} src={process.env.PUBLIC_URL + '/assets/publishsuccess-1.svg'} />
                <SuccessMessage>{message && message?.length ? message : 'Success'}</SuccessMessage>
                <SuccessMessageDescription>{messagedescription}</SuccessMessageDescription>
                <Button variant='contained' color='success' fullWidth sx={{ my: '1rem' }} onClick={() => props.handleGoBack()}>Back</Button>
            </InnerBox>
        </OuterBox>

    );
};

export default NotificationSuccessfulCreation;

export const NotificationCreationFailed = (props: CreateSuccessFailPageProps) => {
    const { message, messagedescription } = props;
    const navigate = useNavigate();
    return (
        <OuterBox className='OuterBox'>
            <InnerBox>
                <img width={100} src={process.env.PUBLIC_URL + '/assets/publishfail-1.svg'} />
                <ErrorMessage>{message && message?.length ? message : 'Failed'}</ErrorMessage>
                <ErrorMessageDescription>{messagedescription}</ErrorMessageDescription>
                <Button variant='contained' color='success' fullWidth sx={{ my: '1rem' }} onClick={() => props.handleGoBack()}>Back</Button>
            </InnerBox>
        </OuterBox>

    );
};
