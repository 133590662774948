import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Link, Stack, TableContainer } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api_endpoints from "../../Api/end_points";
import { FilterType } from "../../Cards/PushNotification";
import { ActionType } from "../../Common/Constants";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import FilterAndSort from "../../Common/FilterAndSort";
import PageTitle from "../../Common/PageTitle";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import { SORT_OPTIONS } from "../../Constants/enums";
import { IRequestBody } from "../../Constants/interfaces";
import { RootState } from "../../redux/reducers";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import { AddNewThreat } from "./AddEditThreat";
import { useStyles } from "./style";
import { getThreatsData } from "./ThreatsApiCalls";
import { ThreatsDataTableColumns } from "./ThreatsColumnData";
import ViewThreat from "./ViewThreat";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type ThreatType = {
  data: Array<any>;
  filters: FilterType;
  isLoading: boolean;
  onChangeFilters: Function;
  onChangeRowsPerPage: Function;
  onDeselectAll: Function;
  onPageChange: Function;
  onRefresh: Function;
  onSeach: Function;
  onSort: Function;
  title: string;
};
const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export const Threats = (props: any) => {
  const storeData = useSelector((state: RootState) => {
    return {
      primaryLanguageCode: state.auth.languageCode,
      languages: state.generic.ListOfLanguages.languageList,
    };
  });
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [selectedThreatInfo, setSelectedThreatInfo] = React.useState<any>("");
  const [actionType, setActionType] = React.useState<any>("");
  const [threats, setThreats] = React.useState<any>([]);
  const [value] = React.useState(0);
  const [addThreat, setAddThreat] = React.useState(false);
  const [showViewThreat, setShowViewThreat] = React.useState(false);
  const [threatViewDetails, setThreatViewDetails] = React.useState({});
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedSortOptions, setSelectedSortOptions] = useState<number>(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedSortOptions(1);
    setActiveTab(newValue);
  };

  const fetchThreatList = (): void => {
    const apiData = {} as IRequestBody;
    let endPoint: string = api_endpoints.get_threat_list;
    apiData.endPoint = endPoint;
    /**applying sortid to the query params */
    apiData.payLoad = { pageNo: currentPage, pageSize: limit };
    if (selectedFilters.languageIds && selectedFilters.languageIds.length > 0) {
      const languageIds = selectedFilters.languageIds.map(
        (languageObj: any) => {
          return languageObj.split(":::")[1];
        }
      );
      apiData.payLoad = {
        ...apiData.payLoad,
        languageIds: languageIds.join(","),
      };
    }
    if (searchValue) {
      apiData.payLoad = {
        ...apiData.payLoad,
        term: searchValue.toLocaleUpperCase(),
      };
    }
    if (selectedSortOptions >= 0) {
      apiData.payLoad = {
        ...apiData.payLoad,
        sort: selectedSortOptions === 1 ? SORT_OPTIONS.dsc : SORT_OPTIONS.asc,
      };
    }
    getThreatsData(apiData, successCallBack, errorCallback);
  };

  const successCallBack = (response: any) => {
    let listData: any = response.data;
    let updatedThreatsData: any = [];
    listData.cropThreatDtoList.map((row: any) => {
      /**updating threats data according to table columns */
      const offset = moment().utcOffset();
      let updatedResponse = {
        id: row.id,
        name: row.name,
        languageName: row.languageName,
        languageId: row.languageId,
        threatList: `${row.threatList.slice(0, row.threatList.length)}`,
        lastUpdatedDate: row.lastUpdatedDate,
        statusId: row.statusId,
      };
      updatedThreatsData.push(updatedResponse);
    });
    setTotalRecords(listData.totalElements);
    setThreats(updatedThreatsData);
  };

  const errorCallback = (error: any, type: string) => {
    TriggerToastMessage("Unable to fetch", "error");
  };
  const processSort = (selectedSortPropoerties: any) => {
    setSelectedSortOptions(parseInt(selectedSortPropoerties, 10));
  };
  const handleSearchValueChange = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const handleSearch = () => {
    props.onSearch(searchValue);
  };
  /**
   * @description A Handler function, which updates the selected number of rows per page
   * @param value number
   */
  const onChangeOfRowsPerPage = (value: number) => {
    setLimit(value);
    setCurrentPage(0);
  };
  /**this method will handle all view click actions */
  const handleActionClick = (actionType: string, details?: any) => {
    switch (actionType) {
      case ActionType.VIEW:
        setShowViewThreat(true);
        return setThreatViewDetails(details);
      case "Create_Push":
        setActionType(ActionType.CREATE);
        return setAddThreat(true);
      case ActionType.UPDATE:
        setActionType(ActionType.UPDATE);
        setShowViewThreat(false);
        setAddThreat(true);
        return setSelectedThreatInfo(details);
      case ActionType.ADD_TRANSLATION:
        setAddThreat(true);
        const checkIfPrimaryLanguageRecord = _.filter(storeData.languages, {
          languageName: details.languageName,
        });
        if (checkIfPrimaryLanguageRecord.length > 0) {
          setActionType(
            checkIfPrimaryLanguageRecord[0].languageCode ===
              storeData.primaryLanguageCode && actionType === ActionType.UPDATE
              ? ActionType.UPDATE
              : ActionType.ADD_TRANSLATION
          );
        }
        return setSelectedThreatInfo(details);
      case "Back":
        return props.onRefresh();
      default:
        return;
    }
  };

  useEffect(() => {
    if (currentPage === 0) {
      fetchThreatList();
      return;
    }
    setCurrentPage(0);
  }, [limit, searchValue, selectedSortOptions, selectedFilters]);

  useEffect(() => {
    fetchThreatList();
  }, [currentPage]);
  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            className={classes.pageTitleNSearchStylesHelper}
          >
            <PageTitle title="Threats" />
            <SearchBar
              placeholderText="Search by Crop"
              searchValue={searchValue}
              onSearchValueChange={handleSearchValueChange}
              onEnterClick={handleSearch}
            />
          </Stack>
          <Box>
            <Box className={classes.customTabBtmBorder}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomTabs
                  value={activeTab}
                  tabsList={["Data List"]}
                  onChange={handleChange}
                />
                {activeTab === 1 && (
                  <Link
                    href="#"
                    variant="subtitle2"
                    underline="always"
                    className={classes.myUploadTabDownloadHelper}
                  >
                    <FileDownloadOutlinedIcon />
                    Download sample template
                  </Link>
                )}
              </Stack>
            </Box>
            <TabPanel value={value} index={0}>
              <Stack
                direction="row"
                className={classes.rowsPerPageStylesHelper}
              >
                <RowsPerPage
                  pagSize={limit}
                  onChangeRowsPerPage={(value: any) =>
                    onChangeOfRowsPerPage(value)
                  }
                />
                <FilterAndSort
                  sortBy={selectedSortOptions.toString()}
                  onSort={(data: number) => {
                    processSort(data);
                  }}
                  showLanguageSelection={true}
                  //showStageList={true}
                  onChangeFilters={(data: any) => {
                    setSelectedFilters(data);
                  }}
                  showLastUpdatedOn={activeTab === 0}
                  filters={{
                    languageIds: [],
                    notificationTypes: [],
                    status: [],
                    frequencyIds: [],
                    crop: [],
                    category: [],
                  }}
                />
              </Stack>

              <DataTable
                //actionsType={ACTION_TYPE.TYPE_CROP}
                columns={ThreatsDataTableColumns}
                data={threats}
                enableSelection={false}
                pageNumber={1}
                onPageChange={() => { }}
                onSelectAll={() => { }}
                onDeselectAll={() => { }}
                onRowSelect={() => { }}
                isViewButton={true}
                onActionClick={handleActionClick}
                isEditEnabled={true}
                languageKey={"languageName"}
                hasTranslation={true}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <DataTableActions
                  pageNumber={currentPage + 1}
                  actionsType={ACTION_TYPE.TYPE_PUSH}
                  onPageChange={(selectedPageNo: number) =>
                    setCurrentPage(selectedPageNo - 1)
                  }
                  onActionClick={handleActionClick}
                  activeTab={activeTab}
                  totalpages={Math.ceil(totalRecords / limit)}
                  canShowAvatar={activeTab === 0}
                />
              </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}></TabPanel>
          </Box>
        </div>
      </TableContainer>

      {/* --------------------------------------Add New Data Modal---------------------------------------- */}
      {addThreat && (
        <AddNewThreat
          notificationDetails={{}}
          onClose={() => {
            setSelectedThreatInfo("");
            setAddThreat(false);
          }}
          isEdit={selectedThreatInfo.id}
          isDraftRecord={false}
          details={selectedThreatInfo}
          actionType={actionType}
        />
      )}
      {/* --------------------------------------Add New Data Modal---------------------------------------- */}
      {showViewThreat && (
        <ViewThreat
          isDraftRecord={false}
          details={threatViewDetails}
          canShowEditButton={activeTab === 0}
          onCloseViewNotification={() => setShowViewThreat(false)}
          editHandler={() => {
            setSelectedThreatInfo(threatViewDetails);
            setShowViewThreat(false);
            setAddThreat(true);
            setActionType(ActionType.UPDATE);
          }}
        />
      )}
    </>
  );
};

export default Threats;
