import { api_service } from "../../Api/api_service";
import api_endpoints from "../../Api/end_points";
import { IRequestBody } from "../../Constants/interfaces";

export const getThreatsData = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'threats_tab');
  }).catch((err) => {
    errorCallBack(err, 'threats_tab');
  });
};

export const GetThreatStageDetails = (props: any) => {
  const pathVariable = props.isDraftRecord ? props.details.id : props.details.name;
  const apiData = {} as IRequestBody;
  apiData.customHeaders = {
    'language-code': props.details.languageCode
  };
  if (!props.isDraftRecord) {
    apiData.endPoint = api_endpoints.get_published_threat_data.replace('__CROP_SYSTEM_NAME__', pathVariable);
  } else {
    apiData.endPoint = api_endpoints.get_draft_threat_data.replace('__THREATDRAFTID__', pathVariable);
  }
  return new Promise((resolve, reject) => {
    api_service.get(apiData)?.then((response) => {
      return resolve(response.data);
    }).catch((err) => {
      return reject(err);
    });
  });
}
export const DeleteThreat = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .delete(data)
    ?.then((response) => {
      successCallBack(response.data, 'delete_threat');
    })
    .catch((err) => {
      errorCallBack(err, 'delete_threat');
    });
};