import _ from "lodash";
import React, { useEffect, useReducer } from "react";
import { TYPE_NO_PUSH, TYPE_PUSH_APPROVED } from "../../Common/States";
import Published from "../../Components/Publish/Publish";
import { NotificationType } from "../../Components/PushNotifications/AddEditNotification";
import { PushNotificationUrls } from "../../Constants/contants";
import { apiCall, METHODS } from "../../Utility/apiCall";

type PublishCardProps = {
  title: string;
};

interface StateType {
  isLoading: boolean;
  data: any;
  selectedPushList: Array<any>;
  showActionProgress: boolean;
  showCreateProgress: boolean;
  stateType?: typeof TYPE_NO_PUSH | typeof TYPE_PUSH_APPROVED;
}

const SET_LOADING: string = "SET_LOADING";
const SET_DATA: string = "SET_DATA";
const SET_SELECTED_PUSH_LIST: string = "SET_SELECTED_PUSH_LIST";
const SET_STATE_TYPE: string = "SET_STATE_TYPE";
const SET_SHOW_ACTION_PROGRESS: string = "SET_SHOW_ACTION_PROGRESS";
const SET_SHOW_CREATE_PROGRESS: string = "SET_SHOW_CREATE_PROGRESS";

interface ActionType {
  type: typeof SET_LOADING | typeof SET_DATA;
  payload: any;
}

const initialState = {
  isLoading: false,
  data: {},
  stateType: "",
  showActionProgress: false,
  showCreateProgress: false,
  selectedPushList: [],
};

function reducer(state: StateType, action: ActionType) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_DATA:
      return { ...state, data: action.payload.data };
    case SET_SELECTED_PUSH_LIST:
      return { ...state, selectedPushList: action.payload.data };
    case SET_STATE_TYPE:
      return { ...state, stateType: action.payload.data };
    case SET_SHOW_ACTION_PROGRESS:
      return { ...state, showActionProgress: action.payload };
    case SET_SHOW_CREATE_PROGRESS:
      return { ...state, showCreateProgress: action.payload };
    default:
      return state;
  }
}

const DEFAULT_PAGE_SIZE: number = 5;

export type FilterType = {
  sortBy: string;
  pageSize: number;
  pageNumber: number;
  languageIds: Array<string>;
  status: Array<string>;
  notificationTypes: Array<string>;
  moduleNames: Array<string>;
  frequencyIds: Array<string>;
  crop: Array<any>;
  category: Array<string>;
};

const defaultFilters: FilterType = {
  sortBy: "ID",
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  languageIds: [],
  status: [],
  notificationTypes: [],
  moduleNames: [],
  frequencyIds: [],
  crop: [],
  category: [],
};

let previousFilters: any = {};

const PublishCard: React.FC<PublishCardProps> = ({ title }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [filters, setFilters] = React.useState<FilterType>({
    ...defaultFilters,
  });

  // const [previousFilters, setPreviousFilters] = React.useState<any>(Object.assign({}, defaultFilters));

  const setIsLoading = (loading: boolean) =>
    dispatch({ type: SET_LOADING, payload: loading });

  const prepareUrl = (defaultPath: string, params: FilterType) => {
    let path: string = `${defaultPath}?`;
    for (const [key, value] of Object.entries(params)) {
      if (
        key === "languageIds" ||
        key === "status" ||
        key === "notificationTypes"
      ) {
        if (Array.isArray(value)) {
          const languageIds: Array<string> = value;
          if (languageIds?.length) {
            // eslint-disable-next-line no-loop-func
            languageIds.forEach((id) => {
              path += `&${key}=${id}`;
            });
          }
        }
      } else {
        path += `&${key}=${value}`;
      }
    }
    return encodeURI(path);
  };

  // effects
  useEffect(() => {
    // getNotifications();
    previousFilters = JSON.parse(JSON.stringify(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setFilters({
      sortBy: "ID",
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      languageIds: [],
      status: [],
      notificationTypes: [],
      moduleNames: [],
      frequencyIds: [],
      crop: [],
      category: [],
    });
  }, []);

  const toggleSelection = (checked: boolean) => {
    const newData = state?.data.map((obj: any) => {
      return { ...obj, checked };
    });
    dispatch({ type: SET_DATA, payload: { data: [...newData] } });
  };

  const handleSelectAll = () => {
    toggleSelection(true);
    dispatch({ type: SET_SELECTED_PUSH_LIST, payload: { data: state.data } });
  };

  const handleDeselectAll = () => {
    toggleSelection(false);
    dispatch({ type: SET_SELECTED_PUSH_LIST, payload: { data: [] } });
  };

  const handleSelectPush = (checked: boolean, pushNotification: any) => {
    const pushIndex: number = state?.data.findIndex(
      (push: any) => push.id === pushNotification.id
    );
    state.data[pushIndex].checked = checked;
    if (checked) {
      dispatch({
        type: SET_SELECTED_PUSH_LIST,
        payload: { data: state.selectedPushList.concat(pushNotification) },
      });
    } else {
      const filteredSelection = state.selectedPushList.filter(
        (selectedPush: any) => selectedPush.id !== pushNotification.id
      );
      dispatch({
        type: SET_SELECTED_PUSH_LIST,
        payload: { data: filteredSelection },
      });
    }
    dispatch({ type: SET_DATA, payload: { data: [...state.data] } });
  };

  const handleCreatePush = (push: NotificationType) => {
    return;
    // dispatch({ type: SET_SHOW_CREATE_PROGRESS, payload: true });
    // apiCall(METHODS.METHOD_POST, PushNotificationUrls.PUSH_NOTIFICATIONS, {
    //   ...push,
    //   districtIds: push.districtIds.map((district: any) => district.id),
    //   frequency: 5,
    // })
    //   .then((res: any) => {
    //     dispatch({
    //       type: SET_STATE_TYPE,
    //       payload: { data: TYPE_PUSH_APPROVED },
    //     });
    //   })
    //   .catch((err) => console.log(err))
    //   .finally(() => {
    //     dispatch({ type: SET_SHOW_CREATE_PROGRESS, payload: false });
    //   });
  };

  const handleDeletePush = () => {
    const ids: Array<number> = state.selectedPushList.map(
      (push: any) => push.id
    );
    dispatch({ type: SET_SHOW_ACTION_PROGRESS, payload: true });
    apiCall(
      METHODS.METHOD_DELETE,
      `${PushNotificationUrls.PUSH_NOTIFICATIONS}/${ids.join(",").toString()}`
    )
      .then((res: any) => {
        dispatch({ type: SET_SELECTED_PUSH_LIST, payload: { data: [] } });
        //getNotifications();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch({ type: SET_SHOW_ACTION_PROGRESS, payload: false });
      });
  };

  const handleRefresh = () => {
    dispatch({ type: SET_STATE_TYPE, payload: { data: "" } });
    //getNotifications();
  };

  const handleSearch = (searchValue: string) => {
    setIsLoading(true);
    if (searchValue) {
      apiCall(
        METHODS.METHOD_GET,
        `${PushNotificationUrls.PUSH_NOTIFICATIONS}/${searchValue}`
      )
        .then((res: any) => {
          dispatch({ type: SET_DATA, payload: { data: [res?.data] } });
          if (!res?.data) {
            dispatch({ type: SET_STATE_TYPE, payload: { data: TYPE_NO_PUSH } });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      //getNotifications();
    }
  };

  const updateFilters = (fieldName: string, value: any) => {
    setFilters((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    // setPreviousFilters((prevState: any) => (Object.assign({ ...prevState, [fieldName]: value })));
  };

  const handleChangeRowsPerPage = (fieldName: string, pageSize: number) => {
    updateFilters(fieldName, pageSize);
  };

  const handlePageChange = (pageNumber: number) => {
    updateFilters("pageNumber", pageNumber);
  };

  const handleSort = (sortValue: string) => {
    updateFilters("sortBy", sortValue);
  };
  const handleChangeFilters = (filters: FilterType) => {
    if (!_.isEqual(previousFilters, filters)) {
      updateFilters("languageIds", filters.languageIds);
    }
  };

  return (
    <>
      <Published
        title={title}
        // isLoading={state?.isLoading}
        isLoading={false}
        // data={state?.data}
        data={[]}
        stateType={state.stateType}
        showActionProgress={state.showActionProgress}
        showCreateProgress={state.showCreateProgress}
        filters={filters}
        onSelectAll={handleSelectAll}
        onDeselectAll={handleDeselectAll}
        onSelectPush={handleSelectPush}
        onCreatePush={handleCreatePush}
        onDeletePush={handleDeletePush}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onRefresh={handleRefresh}
        onSeach={handleSearch}
        onSort={handleSort}
        onPageChange={handlePageChange}
        onChangeFilters={handleChangeFilters}
      />
    </>
  );
};

export default PublishCard;
