import { Box, Divider, Modal, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import api_endpoints, { base_url } from "../../../Api/end_points";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { IRequestBody } from "../../../Constants/interfaces";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { getRetailersViewDraftInfo } from "../RetailerApiCalls";
import { useStyles } from "./styles";

type RetailerDataTypes = {
  fieldLabel: string;
  fieldName: string;
};

const RetailerData: Array<RetailerDataTypes> = [
  {
    fieldLabel: "Retailer Name",
    fieldName: "name",
  },
  {
    fieldLabel: "Retailer Image",
    fieldName: "retailerImage",
  },

  {
    fieldLabel: "Short Address",
    fieldName: "shortAddress",
  },
  {
    fieldLabel: "Full Address",
    fieldName: "fullAddress",
  },

  {
    fieldLabel: "Phone Number",
    fieldName: "phoneNumber",
  },

  {
    fieldLabel: "Pin Code",
    fieldName: "pincode",
  },

  {
    fieldLabel: "State, District, Taluka",
    fieldName: "location",
  },
  {
    fieldLabel: "Village",
    fieldName: "village",
  },
  {
    fieldLabel: "Type",
    fieldName: "type",
  },
  {
    fieldLabel: "Latitude",
    fieldName: "latitude",
  },
  {
    fieldLabel: "Longitude",
    fieldName: "longitude",
  },
  {
    fieldLabel: "Operating Hours:",
    fieldName: "operatinghoursfields",
  },
  {
    fieldLabel: "Category",
    fieldName: "category",
  },
];

type viewRetailerInfoOBJ = {
  category: string;
  fullAddress: string;
  location: string;
  name: string;
  phoneNumber: string | number;
  pincode: string | number;
  retailerImage: string;
  shortAddress: string;
  type: string;
};

const Label = ({ label }: { label: string }) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.formLabelMrtBtnHelper}
      variant="subtitle2"
      fontSize={14}
    >
      {label}
    </Typography>
  );
};

const RenderField = (props: any) => {
  const { fieldName, fieldLabel, data } = props;
  const classes = useStyles();

  if (fieldName === "retailerImage") {
    return (
      <Stack direction="row" spacing={10}>
        <Label label={fieldLabel} />
        <div className={classes.viewImage}>
          {data?.[fieldName] && (
            <img src={data[fieldName]} alt="Img should Display here" />
          )}
        </div>
      </Stack>
    );
  } else {
    return (
      <>
        <Label label={fieldLabel} />
        <Typography
          className={classes.formLabelMrtBtnHelper}
          fontSize={14}
          variant="body1"
        >
          {data?.[fieldName] ? data[fieldName] : "N/A"}
        </Typography>
      </>
    );
  }
};

const ViewRetailerModal = (props: any) => {
  const classes = useStyles();

  const [viewRetailerInfo, setViewRetailerInfo] = useState<
    Array<viewRetailerInfoOBJ>
  >([]);

  useEffect(() => {
    fetchViewRetailersDraftData();
  }, []);

  const successCallBack = (response: any, type: string, optionalData?: any) => {
    if (type === "view_retailers_info") {
      const viewResponceData = response?.data?.payload;
      const requiredData = [viewResponceData].map((item: any) => ({
        name: item.name,
        retailerImage: item.imageurl,
        shortAddress: item.shortaddress,
        fullAddress: item.fulladdress,
        phoneNumber: item.phonenumber,
        pincode: item.pincode,
        location: `${item.state},${item.district},${item.taluka}`,
        village: item.village,
        type: item.type,
        latitude: item.latitude,
        longitude: item.longitude,
        operatinghoursfields: item.operatinghoursfields,
        category: item?.category?.map((item: any) => item).join(","),
      }));
      return setViewRetailerInfo(requiredData);
    }
  };

  const errorCallback = (error: any, type: string) => {
    TriggerToastMessage("Unable to process your request", "error");
  };

  const fetchViewRetailersDraftData = () => {
    const storeId: string | number = props?.selectedRetailerInfo?.id;
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = (
      props.isDraftRecord
        ? api_endpoints.retailer_publish_info_draft
        : api_endpoints.retailer_view_info_draft
    ).replace("__storeId__", `${storeId?.toString()}`);
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    return getRetailersViewDraftInfo(apiData, successCallBack, errorCallback);
  };

  const RenderRetailerDetails = () => {
    return (
      <Box className={classes.formContainer}>
        <Box className={classes.formFieldsHelper}>
          {/* <Box className={classes.formFieldsHelperUnique}> */}
          {RetailerData.map((item: any, index: number) => {
            console.log("item:" + JSON.stringify(viewRetailerInfo?.[0]));
            return (
              <>
                <RenderField
                  fieldName={item.fieldName}
                  fieldLabel={item.fieldLabel}
                  data={viewRetailerInfo?.[0]}
                />
                {index < RetailerData.length - 1 && (
                  <Divider className={classes.formLabelMrtBtnHelper} />
                )}
              </>
            );
          })}
          {/* </Box> */}
        </Box>
      </Box>
    );
  };

  return (
    <Modal open={true} disableEscapeKeyDown={true} onClose={() => {}}>
      <Paper className={classes.formPaper}>
        <Paper elevation={0} square>
          <ModalHeader
            header={`Retailer ${viewRetailerInfo?.[0]?.name ?? ""}`}
            showDelete={false}
            showEdit={props?.isPublishRetailer ?? false}
            editHandler={() => {
              if (props?.isPublishRetailer) {
                props.onEditButtonPress();
              }
            }}
            onClose={() => {
              props.onRequestClose();
            }}
          />
        </Paper>
        <Box className={classes.viewCropFormContentHelper}>
          <RenderRetailerDetails />
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewRetailerModal;
