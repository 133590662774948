import React, { useState } from "react";
import { Modal, Paper, Box, Typography, Divider, Stack } from "@mui/material";
import ViewResponseQueryModal from "./ViewQuery";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

type ViewProps = {
  onCloseView: Function;
  queryItemObject: any;
  queryItemData: any;
};

const View: React.FC<ViewProps> = ({
  onCloseView,
  queryItemObject,
  queryItemData,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showView, setShowView] = useState<boolean>(true);

  const handleClose = () => {
    setShowView(false);
    onCloseView();
  };

  const headers = [
    t("str_query_ID"),
    t("str_phone_no"),
    t("str_grower_state"),
    t("str_grower_dist"),
    t("str_crop"),
    t("str_category"),
  ];
  return (
    <Modal
      open={showView}
      className={classes.flexStyle}
    >
      <Paper className={classes.PaperStyle}>
        <Box style={{ overflow: "auto" }}>
          <ViewResponseQueryModal
            header={headers}
            onClose={() => {
              handleClose();
            }}
            queryItemObject={queryItemObject}
            queryItemData={queryItemData}
          />
        </Box>
      </Paper>
    </Modal>
  );
};

export default View;
