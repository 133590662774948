import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import {
  Button,
  FormHelperText,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { genericValidateImage } from "../../Utils/fileHelper";
import { useStyles } from "./style";
import getRandomizeValue from "../../Utils/randomizer";
import { ToastOptions, toast } from "react-toastify";

export type Media = {
  id: number | null;
  displayOrder: number;
  path: string | File;
  type: "IMAGE" | "VIDEO";
};

export interface IUploadProps {
  acceptedFileTypes: String[];
  onChange: Function;
  maxFileSize: number;
  name: string;
  canRemoveOrReplace: boolean;
  imageUrl: string | Blob | undefined;
  multiple?: boolean;
  media?: (Partial<Media> | undefined)[];
  onRemoveImage?: (
    index?: number,
    currentMedia?: Partial<Media>[] | undefined[]
  ) => void;
  isRequired?: boolean;
}

const toastOptions: ToastOptions = {
  position: toast.POSITION.TOP_CENTER,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const UploadFile: React.FC<IUploadProps> = (props: IUploadProps) => {
  const fileRefs: React.RefObject<HTMLInputElement>[] = Array.from(
    { length: 5 },
    () => React.useRef(null)
  );
  const fileRef = React.useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const removeImage = () => {
    if (!props.multiple) {
      props.onChange("");
    }
  };

  let viewMedias: Partial<Media>[] | undefined[] = Array.from(
    { length: 5 },
    (e, i) => undefined
  );

  if (props.multiple) {
    const medias = props.media;

    medias?.forEach((media) => {
      if (media?.displayOrder !== undefined && media?.displayOrder !== null) {
        // Check if duplicate media DisplayOrder
        // Add displayOrder
        if (viewMedias[media.displayOrder - 1]) {
          viewMedias[media.displayOrder] = {
            ...media,
            displayOrder: media.displayOrder + 1,
          };
        } else {
          viewMedias[media.displayOrder - 1] = media;
        }
      }
    });
  }

  return !props.multiple ? (
    <Stack>
      <Stack
        direction="row"
        alignItems="flex-start"
        // justifyContent="space-between"
        // spacing={1}
        justifyContent={props.canRemoveOrReplace ? "space-between" : ""}
        spacing={props.canRemoveOrReplace ? 1 : 6}
        marginTop={3}
      >
        <Typography
          variant="subtitle2"
          fontSize={14}
          component="span"
          width="80px"
        >
          Image {props.isRequired ? "*" : ""}
        </Typography>
        <Stack>
          <label htmlFor="contained-button-file" id="upload-img">
            <Input
              ref={fileRef}
              key={getRandomizeValue()}
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
              name={props.name}
              disabled={!props.canRemoveOrReplace}
              inputProps={{ accept: props.acceptedFileTypes }}
              onChange={(event: any) => {
                const selectedFiles = event.target.files!;
                if (selectedFiles.length > 0) {
                  const error = genericValidateImage(selectedFiles[0]);
                  if (error.length === 0) {
                    props.onChange(
                      selectedFiles && selectedFiles.length > 0
                        ? selectedFiles[0]
                        : ""
                    );
                    return;
                  }
                  toast.error(error, toastOptions);
                }
              }}
            />
            {/* Displaying image either by raw data or by using URL */}
            {props.imageUrl && (
              <img
                  alt="product"
                src={`${
                  typeof props.imageUrl === "object"
                    ? URL.createObjectURL(props.imageUrl)
                    : props.imageUrl
                }`}
                width="128px"
                height="128px"
              />
            )}
            {!props.imageUrl && (
              <Button
                variant="contained"
                size="small"
                component="span"
                disabled={!props.canRemoveOrReplace}
                disableRipple
                disableElevation
                disableTouchRipple
                sx={{
                  whiteSpace: "nowrap",
                  height: "128px",
                  border: "1px dashed #C2C7D0",
                  color: "#14151C",
                  background: "#F3F4F6",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": { background: "#C2C7D0" },
                }}
              >
                <AddIcon sx={{ color: "#696F88" }} /> Upload an image
              </Button>
            )}
          </label>
        </Stack>
        {/* {(props.actionType === ActionType.CREATE || (props.isPrimaryProduct && props.isEdit && props.actionType !== ActionType.ADD_TRANSLATION)) && */}
        {props.canRemoveOrReplace && (
          <Stack spacing={2}>
            <FormHelperText sx={{ fontSize: "11px" }}>
              JPG, JPEG, GIF or PNG. Max size of 5 MB
            </FormHelperText>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <PublishOutlinedIcon fontSize="inherit" />
              <Typography
                variant="body2"
                data-testid="replace-picture"
                onClick={() => fileRef.current?.click()}
              >
                <label
                  className={classes.rpPicture}
                  htmlFor="contained-button-file2"
                >
                  Replace picture
                </label>
              </Typography>
            </Stack>
            <Stack
              className={classes.rmPicture}
              direction="row"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                removeImage();
              }}
            >
              <DeleteOutlinedIcon fontSize="inherit" />
              <Typography variant="body2">Remove picture</Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  ) : (
    <Stack>
      <Stack direction="row" alignItems="flex-start" mt={2} gap={4}>
        <Stack>
          <Typography variant="subtitle2" fontSize={14} width={"80px"}>
            Images *
          </Typography>
        </Stack>
        <Stack
          direction="row"
          flexWrap={"nowrap"}
          overflow={"auto"}
          gap={4}
          paddingBottom={2}
        >
          {fileRefs.map((inputRef, index) => {
            return (
              <label key={index} htmlFor={`contained-button-file-${index}`}>
                <input
                  id={`contained-button-file-${index}`}
                  ref={inputRef}
                  style={{ display: "none" }}
                  type="file"
                  name={props.name}
                  disabled={!props.canRemoveOrReplace}
                  accept={props.acceptedFileTypes.join(",")}
                  onChange={(event) => {
                    const selectedFiles = event.target.files;
                    if (selectedFiles && selectedFiles.length > 0) {
                      const error = genericValidateImage(selectedFiles[0]);
                      if (error.length === 0) {
                        return props.onChange(
                          selectedFiles[0],
                          viewMedias,
                          index
                        );
                      }
                      toast.error(error, toastOptions);
                    }
                  }}
                />
                <Stack gap={2}>
                  {viewMedias && viewMedias[index]?.path ? (
                    viewMedias[index] !== undefined ? (
                      <img
                        alt="product"
                        src={`${
                          viewMedias[index]!.path instanceof File
                            ? URL.createObjectURL(
                                viewMedias[index]!.path as File
                              )
                            : viewMedias[index]!.path
                        }`}
                        width="120px"
                        height="120px"
                      />
                    ) : null
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      component="span"
                      disabled={!props.canRemoveOrReplace}
                      disableRipple
                      disableElevation
                      disableTouchRipple
                      sx={{
                        whiteSpace: "nowrap",
                        height: "120px",
                        width: "120px",
                        border: "1px dashed #C2C7D0",
                        color: "#14151C",
                        background: "#F3F4F6",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        ":hover": { background: "#C2C7D0" },
                      }}
                    >
                      <AddIcon sx={{ color: "#696F88" }} /> Upload an image
                    </Button>
                  )}
                  <Stack gap={1}>
                    <FormHelperText sx={{ fontSize: "11px" }}>
                      JPG, JPEG, GIF or PNG. Max size of 5 MB
                    </FormHelperText>
                    {viewMedias &&
                    viewMedias[index] &&
                    viewMedias[index]?.path !== "" &&
                    props.canRemoveOrReplace ? (
                      <>
                        <Stack
                          direction="row"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <PublishOutlinedIcon fontSize="inherit" />
                          <Typography
                            variant="body2"
                            onClick={() => inputRef.current?.click()}
                          >
                            <label
                              className={classes.rpPicture}
                              htmlFor="contained-button-file2"
                            >
                              Replace picture
                            </label>
                          </Typography>
                        </Stack>
                        <Stack
                          className={classes.rmPicture}
                          direction="row"
                          alignItems="flex-starts"
                          justifyContent="flex-starts"
                          onClick={(e) => {
                            e.preventDefault();
                            props.onRemoveImage &&
                              props.onRemoveImage(index, viewMedias);
                          }}
                        >
                          <DeleteOutlinedIcon fontSize="inherit" />
                          <Typography variant="body2">
                            Remove picture
                          </Typography>
                        </Stack>
                      </>
                    ) : null}
                  </Stack>
                </Stack>
              </label>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
