import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from "axios";
import appActions from "../redux/action";
import { RootState } from "../redux/reducers";
import { TriggerToastMessage } from "../Utils/toastTrigger";
import { store } from "../redux/store";
import api_endpoints, { base_url } from "./end_points";

class APIService {
  api_urls = api_endpoints;
  axiosNoInterceptor: AxiosInstance;
  axiosOptions: AxiosRequestConfig = {
    timeout: 10000 * 10,
    withCredentials: true,
  };

  BaseDomain = {
    APP_ID: process.env.APP_ID,
    APP_VERSION: process.env.APP_VERSION,
  };
  ContentHeaders = {
    Json: "application/json",
    FormData: "multipart/form-data",
    Plain: "text/plain",
    Binary: "application/octet-stream",
  };

  isNotificationCall = false;
  strings: any;
  apiCount = 0;
  showLoader = true;

  constructor() {
    this.axiosNoInterceptor = axios.create();
    axios.interceptors.request.use((request) => this.requestHandler(request));
    axios.interceptors.response.use(
      (response: any) => {
        this.reduceAPICount();
        if (!this.isNotificationCall) {
          return response;
        } else {
          this.isNotificationCall = false;
        }
        return response;
      },
      (error) => {
        this.reduceAPICount();
        if (error.response) {
          // if auth token expires, fetch auth token by passing refresh token
          if (error.response.status === 401) {
            TriggerToastMessage(
              "Your session has expired. Please re-login to continue.",
              "warn"
            );
          }
          if (error.response.status >= 400) {
            return error.response;
          }
        }
        return this.responsePromise(error);
      }
    );

    this.axiosNoInterceptor?.interceptors.response.use(
      (response) => {
        this.reduceAPICount();
        return response;
      },
      (error) => {
        this.reduceAPICount();
        if (error.response.status === 400) {
          return new Promise((resolve, reject) => {
            if (error.response) {
              reject(error.response.data);
            } else {
              reject(error);
            }
          });
        }
        return this.responsePromise(error);
      }
    );
  }

  reduceAPICount() {
    this.apiCount--;
    if (this.apiCount === 0) {
      store.dispatch(appActions.generic_actions.showLoader(false));
    }
  }

  delete(data: {
    customHeaders?: any;
    domain?: string;
    endPoint: string;
    headerType?: string;
    id?: string;
    payLoad?: any;
    showLoader?: boolean;
  }) {
    if (!data.domain) {
      data.domain = base_url;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    this.showLoader = data.showLoader ?? true;

    return axios.delete(data.endPoint, {
      baseURL: data.domain,
      headers: this.getHeadersByType(
        "delete",
        data.headerType,
        data.domain,
        data.customHeaders
      ),
      // data: { ...data.payLoad },
      data: data.payLoad,
    });
  }

  get(data: {
    customHeaders?: any;
    domain: string;
    endPoint: string;
    headerType?: string;
    id?: string;
    noHeadersRequired?: boolean;
    payLoad?: any;
    showLoader?: boolean;
  }) {
    if (!data.domain) {
      data.domain = base_url;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    this.showLoader = data.showLoader ?? true;

    try {
      return axios.get(data.endPoint, {
        baseURL: data.domain,
        timeout: this.axiosOptions.timeout,
        params: data.payLoad,
        headers: data.noHeadersRequired
          ? null
          : this.getHeadersByType(
              "get",
              data.headerType,
              data.domain,
              data.customHeaders,
              "",
              data.endPoint
            ),
      });
    } catch (e) {
      console.error("axios get::", e);
    }
  }

  getHeadersByType(
    requestMethod: string,
    headerType: string | undefined,
    domain: string,
    customHeaders?: any,
    payload?: any,
    endPoint?: string
  ): any {
    let data: any = {};
    switch (headerType) {
      case this.ContentHeaders.Json: {
        data["Content-Type"] = "application/json";
        break;
      }
      case this.ContentHeaders.Plain: {
        data["Content-Type"] = "text/plain";
        break;
      }
      case this.ContentHeaders.FormData: {
        data["Content-Type"] = "multipart/form-data";
        break;
      }
      case this.ContentHeaders.Binary: {
        data["Content-Type"] = "application/octet-stream";
        break;
      }
      default:
        data["Content-Type"] = "application/json";
        break;
    }
    ////console.log(appState, 'appState');
    const appState: RootState = store.getState();

    let authorization = appState.auth ? appState.auth.authToken : "";
    const countryCode = appState.auth?.countryCode || "IN";
    data["Authorization"] = `Bearer ${authorization}`;
    data["country-code"] = countryCode;
    data["userType"] = "SR";
    data["language-code"] = appState.auth.languageCode || "en";
    return { ...data, ...customHeaders };
  }


  isNetworkError = (error: { code: string; response: any }) => {
    return !error.response && error.code !== "ECONNABORTED";
  };

  notificationGet = (data: {
    customHeaders?: any;
    domain: string;
    endPoint: string;
    headerType?: string;
    id?: string;
    noHeadersRequired?: boolean;
    payLoad?: any;
    showLoader?: boolean;
  }) => {
    this.isNotificationCall = true;
    if (!data.domain) {
      data.domain = base_url; //this.BaseDomain.PATIENT_API;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    this.showLoader = data.showLoader ?? true;

    try {
      return axios.get(data.endPoint, {
        baseURL: data.domain,
        timeout: this.axiosOptions.timeout,
        params: data.payLoad,
        headers: data.noHeadersRequired
          ? null
          : this.getHeadersByType(
              "get",
              data.headerType,
              data.domain,
              data.customHeaders
            ),
      });
    } catch (e) {
      console.error("axios get::", e);
    }
  };

  post(data: {
    customHeaders?: any;
    domain?: string;
    endPoint: string;
    headerType?: string;
    payLoad?: any;
    params?: any;
    showLoader?: boolean;
    useNonInterceptor?: boolean;
    responseType?: ResponseType;
  }) {
    if (!data.domain) {
      data.domain = base_url;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    this.showLoader = data.showLoader ?? true;

    if (!data.useNonInterceptor) {
      data.useNonInterceptor = false;
    }

    if (data.useNonInterceptor) {
      let payLoadData =
        data.payLoad instanceof FormData
          ? data.payLoad
          : JSON.stringify(data.payLoad);
      return this.axiosNoInterceptor.post(data.endPoint, payLoadData, {
        timeout: this.axiosOptions.timeout,
        transformRequest: this.axiosOptions.transformRequest,
        baseURL: data.domain,
        headers: this.getHeadersByType(
          data.headerType,
          data.domain,
          data.customHeaders
        ),
      });
    } else {
      let payLoadData =
        data.payLoad instanceof FormData
          ? data.payLoad
          : JSON.stringify(data.payLoad);
      return axios.post(data.endPoint, payLoadData, {
        responseType: data.responseType,
        timeout: this.axiosOptions.timeout,
        transformRequest: this.axiosOptions.transformRequest,
        baseURL: data.domain,
        params: data.params,
        headers: this.getHeadersByType(
          "post",
          data.headerType,
          data.domain,
          data.customHeaders,
          data.payLoad,
          data.endPoint
        ),
      });
    }
  }
  put(data: {
    customHeaders?: any;
    domain?: string;
    endPoint: string;
    headerType?: string;
    id?: string;
    payLoad?: any;
    showLoader?: boolean;
  }) {
    if (!data.domain) {
      data.domain = base_url;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    this.showLoader = data.showLoader ?? true;

    let payLoadData =
      data.payLoad instanceof FormData
        ? data.payLoad
        : JSON.stringify(data.payLoad);
    return axios.put(data.endPoint, payLoadData, {
      timeout: this.axiosOptions.timeout,
      transformRequest: this.axiosOptions.transformRequest,
      baseURL: data.domain,
      headers: this.getHeadersByType(
        "put",
        data.headerType,
        data.domain,
        data.customHeaders,
        data.payLoad
      ),
    });
  }

  patch(data: {
    customHeaders?: any;
    domain?: string;
    endPoint: string;
    headerType?: string;
    id?: string;
    payLoad?: any;
    showLoader?: boolean;
  }) {
    if (!data.domain) {
      data.domain = base_url;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    this.showLoader = data.showLoader ?? true;

    let payLoadData =
      data.payLoad instanceof FormData
        ? data.payLoad
        : JSON.stringify(data.payLoad);
    return axios.patch(data.endPoint, payLoadData, {
      timeout: this.axiosOptions.timeout,
      transformRequest: this.axiosOptions.transformRequest,
      baseURL: data.domain,
      headers: this.getHeadersByType(
        "patch",
        data.headerType,
        data.domain,
        data.customHeaders,
        data.payLoad
      ),
    });
  }
  /**
   * This method is called when
   * @param reqConfig
   */
  reInitiateRequest(reqConfig: {
    baseURL: any;
    method: any;
    params: any;
    url: any;
  }) {
    const { url, method, baseURL, params } = reqConfig;
    const requestBody = {
      endPoint: url,
      domain: baseURL,
      payLoad: params,
      showLoader: true,
    };
    switch (method) {
      case "get":
        return this.get(requestBody);
      case "post":
        return this.post(requestBody);
      case "put":
        return this.put(requestBody);
      case "delete":
        return this.delete(requestBody);
      default:
        return;
    }
  }

  requestHandler(requestObject: AxiosRequestConfig<any>) {
    if (this.apiCount === 0 && this.showLoader) {
      store.dispatch(appActions.generic_actions.showLoader(true));
    }
    this.apiCount++;
    return requestObject;
  }

  responsePromise(res: any) {
    this.apiCount--;
    if (this.apiCount === 0) {
      store.dispatch(appActions.generic_actions.showLoader(false));
    }
    return new Promise((resolve, reject) => {
      if (res.response) {
        resolve(res);
      } else {
        reject(res);
      }
    });
  }

  retry = (config: AxiosRequestConfig) => {
    return axios(config);
  };


  /////for particular queryid get call

  getByID(data: {
    customHeaders?: any;
    domain: string;
    endPoint: string;
    headerType?: string;
    id?: string;
    noHeadersRequired?: boolean;
    payLoad?: any;
    showLoader?: boolean;
  }) {
    if (!data.domain) {
      data.domain = base_url;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    this.showLoader = data.showLoader ?? true;

    try {
      return axios.get(data.endPoint, {
        baseURL: data.domain,
        timeout: this.axiosOptions.timeout,
        params: data.payLoad,
        headers: data.noHeadersRequired
          ? null
          : this.getHeadersByType(
              "get",
              data.headerType,
              data.domain,
              data.customHeaders,
              "",
              data.endPoint
            ),
      });
    } catch (e) {
      console.error("axios get::", e);
    }
  }

  //post by id
  postByID(data: {
    customHeaders?: any;
    domain?: string;
    endPoint: string;
    headerType?: string;
    payLoad?: any;
    showLoader?: boolean;
    useNonInterceptor?: boolean;
  }) {
    if (!data.domain) {
      data.domain = base_url;
    }
    if (!data.headerType) {
      data.headerType = this.ContentHeaders.Json;
    }

    this.showLoader = data.showLoader ?? true;

    if (!data.useNonInterceptor) {
      data.useNonInterceptor = false;
    }

    if (data.useNonInterceptor) {
      let payLoadData =
        data.payLoad instanceof FormData
          ? data.payLoad
          : JSON.stringify(data.payLoad);
      return this.axiosNoInterceptor.post(data.endPoint, payLoadData, {
        timeout: this.axiosOptions.timeout,
        transformRequest: this.axiosOptions.transformRequest,
        baseURL: data.domain,
        headers: this.getHeadersByType(
          data.headerType,
          data.domain,
          data.customHeaders
        ),
      });
    } else {
      let payLoadData =
        data.payLoad instanceof FormData
          ? data.payLoad
          : JSON.stringify(data.payLoad);
      return axios.post(data.endPoint, payLoadData, {
        timeout: this.axiosOptions.timeout,
        transformRequest: this.axiosOptions.transformRequest,
        baseURL: data.domain,
        headers: this.getHeadersByType(
          "post",
          data.headerType,
          data.domain,
          data.customHeaders,
          data.payLoad,
          data.endPoint
        ),
      });
    }
  }
}

export const api_service = new APIService();
