export const StagesToNewProduct = [
    {
        stepLabel: 'General Details',
        stepValue: 'general-details',
        hasErrors: false,
        isFormValid: false
    },
    {
        stepLabel: 'Do\'s & Don\'ts',
        stepValue: 'Do\'s & Don\'ts',
        hasErrors: false,
        isFormValid: false
    },
    {
        stepLabel: 'Features',
        stepValue: 'Features',
        hasErrors: false,
        isFormValid: false
    },
    {
        stepLabel: 'Grower Testimonials',
        stepValue: 'Grower Testimonials',
        hasErrors: false,
        isFormValid: false
    },
    {
        stepLabel: 'Target Threats',
        stepValue: 'Target Threats',
        hasErrors: false,
        isFormValid: false
    },
    {
        stepLabel: 'Crop Calendar Recommendation',
        stepValue: 'Crop Calendar Recommendation',
        hasErrors: false,
        isFormValid: false
    },
];
