import React from "react";
import { Box, TableContainer, Stack } from "@mui/material";
import { DOWNLOAD_SAMPLE_TEMPLATE_TYPE } from "../../Common/Constants";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import { DownLoadSampleTemplate } from "../../Common/DownloadSampleTemplate";
import FiltersAndSort from "../../Common/FilterAndSort";
import PageTitle from "../../Common/PageTitle";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import ModalCreatePopUp from "./ModalCreatePopUp";
import { filterObjType } from "./PlantixCPProducts";
import { useStyles } from "./style";
import ViewPlantixCPProduct from "./ViewPlantixCPProduct";

interface PlantixCPProductsProps {
  searchValue: string;
  activeTab: number;
  limit: number;
  sortId: "0" | "1";
  filterObj: filterObjType;
  currentPage: number;
  totalPages: number;
  open: boolean;
  showCreateOrEditModal: boolean;
  actionType: string;
  showBulkUpload: boolean;
  dataToDisplay: any[];
  showViewModal: boolean;
  onSearchValueChange: (newValue: string) => void;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  onClickAction: (clickAction: string, detail?: any) => void;
  onKnowMorePopUp: (flag: boolean, rowData: any) => void;
  onCloseModal: () => void;
  onShowCreateOrEditModal: (toggle: boolean) => void;
  onShowBulkUpload: (toggle: boolean) => void;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setSortId: React.Dispatch<React.SetStateAction<"0" | "1">>;
  setFilterObj: React.Dispatch<React.SetStateAction<filterObjType>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectAll: (status: boolean) => void;
  onRowSelect: (status: boolean, rowInfo: any) => void;
  buttonExportVisible?: boolean;
}

type ColumnTypes = {
  fieldLabel: string;
  fieldName: string;
};

const DataListColumns: ColumnTypes[] = [
  // {
  //   fieldLabel: "Crop",
  //   fieldName: "crop",
  // },
  {
    fieldLabel: "System Name",
    fieldName: "systemName",
  },
  {
    fieldLabel: "Language",
    fieldName: "languageName",
  },
  {
    fieldLabel: "Last Updated On",
    fieldName: "lastUpdatedOn",
  },
];

const MyUploadColumns: ColumnTypes[] = [
  {
    fieldLabel: "File Name",
    fieldName: "fileName",
  },
  {
    fieldLabel: "Uploaded On",
    fieldName: "cropsUploadDate",
  },
  {
    fieldLabel: "Status",
    fieldName: "comnMyUploadStatus",
  },
];

const PlantixCPProductsView: React.FC<PlantixCPProductsProps> = (props) => {
  const classes = useStyles();

  const renderViewPlantixCPModal = () => {
    return (
      <ViewPlantixCPProduct onClose={() => props.setShowViewModal(false)} />
    );
  };

  return (
    <TableContainer>
      <div>
        <Stack
          direction="row"
          alignItems="center"
          className={classes.pageTitleNSearchStylesHelper}
        >
          <PageTitle title="Plantix CP Products" />
          <SearchBar
            placeholderText="Search by Title, Systemname"
            searchValue={props.searchValue}
            onSearchValueChange={props.onSearchValueChange}
            onEnterClick={() => {}}
            isResetSearchValue={props.searchValue === ""}
          />
        </Stack>
        <Box>
          <Box className={classes.customTabBtmBorder}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomTabs
                value={props.activeTab}
                tabsList={["Data List", "My uploads"]}
                onChange={props.onChange}
              ></CustomTabs>
              {props.activeTab === 1 && (
                <DownLoadSampleTemplate
                  literal={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.HYBRID}
                  displayAsButton={false}
                />
              )}
            </Stack>
          </Box>
          <Stack direction="row" className={classes.rowsPerPageStylesHelper}>
            <RowsPerPage
              pagSize={5}
              onChangeRowsPerPage={(data: number) => props.setLimit(data)}
            />
            <FiltersAndSort
              sortBy={props.sortId}
              onSort={(sortType: any) => {
                props.setSortId(sortType?.toString() ?? "1");
              }}
              onChangeFilters={(data: any) => props.setFilterObj(data)}
              filters={{ ...props.filterObj }}
              hasFilter={props.activeTab === 0}
              isResetFilter={`${props.activeTab}`}
              showLanguageSelection={true}
              showSecondarySort={false}
              showLastUpdatedOn={true}
              showCropSelection={false}
            />
          </Stack>
          <DataTable
            actionsType={ACTION_TYPE.TYPE_CROP}
            columns={props.activeTab === 0 ? DataListColumns : MyUploadColumns}
            enableSelection={props.buttonExportVisible && props.activeTab !== 1}
            data={props.dataToDisplay}
            pageNumber={1}
            onPageChange={() => {}}
            onSelectAll={props.onSelectAll}
            onDeselectAll={() => {}}
            onRowSelect={props.onRowSelect}
            isEditEnabled={props.activeTab === 0}
            isViewButton={props.activeTab === 0}
            isDeleteEnabled={props.activeTab === 0}
            onActionClick={(type, rowInfo) =>
              props.onClickAction(type, rowInfo)
            }
            hasTranslation={true}
            languageKey="languageName"
            handleKnowMorePopup={(flag: boolean, rowData: any) =>
              props.onKnowMorePopUp(flag, rowData)
            }
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <DataTableActions
              actionsType={ACTION_TYPE.TYPE_CP_PRODUCT}
              pageNumber={props.currentPage + 1}
              onPageChange={(selectedPageNo: number) =>
                props.setCurrentPage(selectedPageNo - 1)
              }
              buttonVisible={props.activeTab === 0}
              buttonExportVisible={props.buttonExportVisible}
              onActionClick={(data: string) => props.onClickAction(data)}
              canShowAvatar={props.activeTab === 0}
              activeTab={props.activeTab}
              totalpages={props.totalPages}
            />
          </Stack>
        </Box>
      </div>
      <ModalCreatePopUp
        open={props.open}
        actionType={props.actionType}
        showCreateOrEditModal={props.showCreateOrEditModal}
        showBulkUpload={props.showBulkUpload}
        onCloseModal={props.onCloseModal}
        onShowCreateOrEditModal={props.onShowCreateOrEditModal}
        onShowBulkUpload={props.onShowBulkUpload}
      />
      {props.showViewModal && renderViewPlantixCPModal()}
    </TableContainer>
  );
};

export default PlantixCPProductsView;
