import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import {Box, Button, styled, TextareaAutosize, Typography,} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {toast, ToastOptions} from "react-toastify";
import api_endpoints, {base_url} from "../../../Api/end_points";
import {IRequestBody} from "../../../Constants/interfaces";
import {formatDate} from "../../../Utility/DateFormat";
import {TriggerToastMessage} from "../../../Utils/toastTrigger";
import "../overrides.css";
import {requestReplyQuery, requestUploadQuery} from "../QueryAPICalls";
import {useStyles} from "./style";

type RespondViewProps = {
  setIsFill?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  queryItem: any;
  onClose: Function;
  OnReplySuccess: Function;
};

type ImageObjectType = {
  bae64ToAPI: string;
  bae64ToDisplay: string;
  name: string;
  size: number | null;
};

const defaultFileValidationObj = {
  imageFilesExceeded: false,
  videoFilesExceeded: false,
  audioFilesExceeded: false,
};

const RespondView: React.FC<RespondViewProps> = ({
  setIsFill,
  queryItem,
  onClose,
  OnReplySuccess,
}) => {
  // classes
  const classes = useStyles();
  const { t } = useTranslation();
  const [recordedFiles, setRecordedFiles] = useState<any[]>([]);
  const [mediaList, setMediaList] = useState([]);
  const [mediaResponse, setMediaResponse] = useState();
  const [message, setMessage] = useState("");
  const [currentSelectedFile, setCurrentSelectedFile] = useState({});
  const [fileValidationObj, setFileValidationObj] = useState(
    defaultFileValidationObj
  );
  const [replacingIndex, setReplacingIndex] = useState<number | undefined>();
  const [isReplacing, setIsReplacing] = useState(false);

  let imageObject: ImageObjectType = {
    bae64ToDisplay: "",
    bae64ToAPI: "",
    name: "",
    size: null,
  };

  const Input = styled("input")({
    display: "none",
  });

  const UploadMediaData = (data: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.media_upload;
    apiData.showLoader = true;
    apiData.payLoad = data;

    return requestUploadQuery(apiData, successCallBack, errorCallback);
  };

  const ReplyToQuery = (data: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = `${api_endpoints.query_list}/${queryItem?.queryId}/${api_endpoints.query_reply}`;
    apiData.showLoader = true;
    apiData.payLoad = data;

    return requestReplyQuery(apiData, successCallBack, errorCallback);
  };

  const successCallBack = (response: any, type: string) => {
    if (type === "requestUploadQuery") {
      setMediaResponse(response);
    }
    if (type === "requestReplyQuery") {
      if (response.data === "Reply to query is successful") {
        handleReplySuccess(response.data);
        onClose();
      }
    }
  };

  const errorCallback = (err: any, type: string) => {
    TriggerToastMessage("Unable to process your request", "error");
  };

  useEffect(() => {
    if (message && setIsFill) setIsFill(true);
    if (recordedFiles && recordedFiles.length > 0 && setIsFill) setIsFill(true);
    if (!message && recordedFiles && recordedFiles.length === 0 && setIsFill)
      setIsFill(false);
  }, [message, recordedFiles]);

  const handleReplySuccess = (res: string) => {
    OnReplySuccess(res);
  };

  useEffect(() => {
    if (mediaResponse !== undefined) {
      updateFilesInState(currentSelectedFile, "afterResponse", mediaResponse);
      setCurrentSelectedFile({});
    }

    if (mediaResponse !== undefined && mediaResponse["statusCode"] === 500) {
      TriggerToastMessage("Unable to process your request", "error");
    }
  }, [mediaResponse]);

  const renderMediaList = (mediaList: any) => {
    if (!mediaList?.length) {
      return null;
    }

    const renderMedia = (media: any) => {
      const url = media.path ? media.path : media.url;

      if (media.type === "AUDIO") {
        return (
          <div className={classes.playerDiv}>
            <ReactPlayer
              className="react-player"
              url={url}
              width="500px"
              height="150px"
              playing={false}
              controls={true}
            />
          </div>
        );
      }

      if (media.type === "IMAGE") {
        return (
          <img src={url} alt="Carousel" className={classes.ImageStyleCarosel} />
        );
      }

      return (
        <div className={classes.carouselDiv}>
          <div className={classes.playerWrapper}>
            <ReactPlayer
              className="react-player"
              url={url}
              playing={false}
              controls={true}
            />
          </div>
        </div>
      );
    };

    return (
      <div className={classes.carouselDiv}>
        <Carousel showThumbs={false} width="90%" className={classes.carousel}>
          {mediaList.map((media: any, index: number) => (
            <div className={classes.flexStyleCarousel} key={index}>
              {renderMedia(media)}
            </div>
          ))}
        </Carousel>
      </div>
    );
  };

  const handleMediaUpload = async (evt: any) => {
    let file = evt.target.files[0];
    return await uploadfilesToServer(file);
  };

  useEffect(() => {
    checkValidationBasedonFileTypes();
  }, [recordedFiles]);

  useEffect(() => {
    if (replacingIndex !== undefined && isReplacing) {
      // move the last index from recordedFiles to the replacing index
      const tempRecordedFiles = [...recordedFiles];
      tempRecordedFiles[replacingIndex] = tempRecordedFiles.pop();
      setRecordedFiles(tempRecordedFiles);

      setReplacingIndex(undefined);
      setIsReplacing(false);
    }
  }, [recordedFiles.length]);

  const checkValidationBasedonFileTypes = () => {
    const imageFilesArray = recordedFiles.filter(
      (item: any) => item?.type === "IMAGE"
    );
    const imageFilesLength = imageFilesArray.length;

    const videoFilesArray = recordedFiles.filter(
      (item: any) => item?.type === "VIDEO"
    );
    const videoFilesLength = videoFilesArray.length;

    const audioFilesArray = recordedFiles.filter(
      (item: any) => item?.type === "AUDIO"
    );
    const audioFilesLength = audioFilesArray.length;

    const newFileValidationObj = {
      ...fileValidationObj,
      imageFilesExceeded: imageFilesLength === 5,
      audioFilesExceeded: audioFilesLength === 5,
      videoFilesExceeded: videoFilesLength === 1,
    };

    setFileValidationObj(newFileValidationObj);
  };

  const convertBlobToBase64 = (blob: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  const checkFileType = (file: File) => {
    const options: ToastOptions = {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      autoClose: 2000,
    };

    const imageMimeTypes = ["image/jpeg", "image/jpg", "image/png"];
    const videoMimeTypes = ["video/mp4", "video/3gpp"];
    const audioMimeTypes = ["audio/x-mp3", "audio/mpeg"];

    const fileSizeInMB = parseFloat((file.size / Math.pow(1024, 2)).toFixed(2));

    if (fileSizeInMB > 5) {
      toast.info(
        `Maximum allowed size is 5MB, current size is ${fileSizeInMB}MB`,
        options
      );
      return false;
    }

    if (imageMimeTypes.includes(file.type)) {
      return "IMAGE";
    } else if (videoMimeTypes.includes(file.type)) {
      return "VIDEO";
    } else if (audioMimeTypes.includes(file.type)) {
      return "AUDIO";
    } else {
      toast.info(`File extension is not supported`, options);
      return false;
    }
  };

  const uploadfilesToServer = async (file: any) => {
    const isValid = checkFileType(file);

    if (!isValid) return false;

    const formData = new FormData();
    formData.append("file", file);
    setCurrentSelectedFile(file);
    UploadMediaData(formData);
  };

  const updateFilesInState = async (file: any, from: any, resp: any = null) => {
    const uploadedFileType = await checkFileType(file);

    setRecordedFiles(recordedFiles);
    if (from === "afterResponse" && uploadedFileType === "IMAGE") {
      const responseObj = {
        type: "IMAGE",
        url: resp.data,
      };
      const tempMediaFilesArr: any = [...mediaList];
      tempMediaFilesArr.push(responseObj);
      setMediaList(tempMediaFilesArr);
    } else if (from === "afterResponse" && uploadedFileType === "VIDEO") {
      const responseObj = {
        type: "VIDEO",
        url: resp.data,
      };
      const tempMediaFilesArr: any = [...mediaList];
      tempMediaFilesArr.push(responseObj);
      setMediaList(tempMediaFilesArr);
    } else if (from === "afterResponse" && uploadedFileType === "AUDIO") {
      const responseObj = {
        type: "AUDIO",
        url: resp.data,
      };
      const tempMediaFilesArr: any = [...mediaList];
      tempMediaFilesArr.push(responseObj);
      setMediaList(tempMediaFilesArr);
    }

    if (mediaResponse !== undefined && mediaResponse["statusCode"] === 200) {
      const base64String = await convertBlobToBase64(file);
      const mediaObj = {
        url: base64String,
        type: file.type,
        name: file.name,
      };
      if (uploadedFileType === "IMAGE") {
        const FileObj = {
          type: "IMAGE",
          path: mediaObj.url,
        };
        const updatedRecordedFilesArr: any = [...recordedFiles, FileObj];
        setRecordedFiles(updatedRecordedFilesArr);
      } else if (uploadedFileType === "VIDEO") {
        const FileObj = {
          type: "VIDEO",
          path: mediaObj.url,
        };
        const updatedRecordedFilesArr: any = [...recordedFiles, FileObj];
        setRecordedFiles(updatedRecordedFilesArr);
      } else if (uploadedFileType === "AUDIO") {
        const FileObj = {
          type: "AUDIO",
          path: mediaObj.url,
        };
        const updatedRecordedFilesArr: any = [...recordedFiles, FileObj];
        setRecordedFiles(updatedRecordedFilesArr);
      }
    }
  };

  const handleRespond = () => {
    const ReplyObj = {
      message: message,
      queryMediaList: mediaList,
    };
    ReplyToQuery(ReplyObj);
  };

  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };

  const handleDeleteMedia = (index: number) => {
    if (confirm("Delete file, are you sure?")) {
      const tempRecordedFiles = [...recordedFiles];
      tempRecordedFiles.splice(index, 1);
      setRecordedFiles(tempRecordedFiles);

      const tempMediaFilesArr = [...mediaList];
      tempMediaFilesArr.splice(index, 1);
      setMediaList(tempMediaFilesArr);
    }
  };

  const handleReplaceMedia = async (evt: any, index: number) => {
    try {
      await handleMediaUpload(evt);
      setReplacingIndex(index);
      setIsReplacing(true);
    } catch (error) {
      TriggerToastMessage("Unable to process your request", "error");
      console.log("error", error);
    }
  };

  const RenderMediaInput = ({
    data,
    index,
  }: {
    data: { path: string; type: "AUDIO" | "IMAGE" | "VIDEO" };
    index: number;
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const mediaInput = (
      <Box sx={{ mt: 1, ml: 2 }}>
        <Box sx={{ display: "flex" }}>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/jpeg,image/jpg,image/png,video/mp4,video/3gpp,audio/x-mp3,audio/mpeg"
              id="contained-button-file"
              multiple
              type="file"
              name='replace-media'
              src={imageObject.bae64ToDisplay}
              onChange={(evt: any) => handleReplaceMedia(evt, index)}
              ref={inputRef}
            />
            <Button
              component="span"
              onClick={(evt: any) => {
                evt.preventDefault();
                if (confirm("Replace file, are you sure?")) {
                  inputRef.current?.click();
                }
              }}
            >
              <ReplayIcon sx={{ color: "#696F88" }} />
              <Typography
                sx={{ ml: 1, color: "#228B22" }}
                className={classes.UnderlineTextStyle}
                variant="subtitle2"
              >
                {t("str_replace_media")}
              </Typography>
            </Button>
          </label>
        </Box>
        <Button
          sx={{ display: "flex", mt: 1 }}
          onClick={() => handleDeleteMedia(index)}
        >
          <DeleteIcon sx={{ mr: 1, color: "#696F88" }} />
          <Typography
            variant="subtitle2"
            color="#CF3537"
            className={classes.UnderlineTextStyle}
          >
            {t("str_delete")}
          </Typography>
        </Button>
      </Box>
    );

    switch (data.type) {
      case "IMAGE":
        return (
          <Box sx={{ display: "flex" }} key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img src={data?.path} alt="" className={classes.ImageStyle} />
            </Box>
            {mediaInput}
          </Box>
        );
      case "AUDIO":
        return (
          <Box sx={{ display: "flex" }} key={index}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <ReactPlayer
                className="react-player"
                url={data.path}
                width="200px"
                height="50px"
                playing={false}
                controls={true}
              />
            </Box>
            {mediaInput}
          </Box>
        );
      case "VIDEO":
        return (
          <Box sx={{ display: "flex" }} key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ReactPlayer
                className="react-player"
                url={data.path}
                playing={false}
                controls={true}
                width="150px"
                height="85px"
              />
            </Box>
            {mediaInput}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box>
        <Box
          style={{
            paddingLeft: 32,
            backgroundColor: "#F3F4F6",
          }}
        >
          <Box className={classes.flexStyle}>
            <Typography variant="body2" pt={3} mb={1}>
              {t("str_date_time")}{" "}
              {formatDate(queryItem.createdDate, "DD/MM/YYYY, hh:mm A")}
            </Typography>
          </Box>
          <Box className={classes.flexStyle}>
            <Typography fontSize={14} variant="subtitle2" pb={2}>
              {t("str_grower_message")}
            </Typography>
            <Typography style={{ marginLeft: 8 }} variant="subtitle2" pb={2}>
              {queryItem.message}
            </Typography>
          </Box>

          {renderMediaList(queryItem.queryMediaList)}
        </Box>
        <Box pl={4} pt={2} pb={3}>
          <Typography variant="subtitle2" pb={1}>
            {t("str_support_reply")}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              name='message'
              placeholder={t("str_type_here")}
              style={{ width: 400 }}
              onBlur={(event: any) => {
                handleMessageChange(event);
              }}
              defaultValue={message}
            />
            <label htmlFor="contained-button-file">
              <Input
                accept="image/jpeg,image/jpg,image/png,video/mp4,video/3gpp,audio/x-mp3,audio/mpeg"
                id="contained-button-file"
                // multiple
                type="file"
                src={imageObject.bae64ToDisplay}
                onChange={(evt: any) => handleMediaUpload(evt)}
              />
              <Button
                variant="outlined"
                sx={{
                  ml: 3,
                  border: "1px solid",
                  borderStyle: "dotted",
                  borderRadius: "6px",
                  borderColor: "#C2C7D0",
                }}
                component="span"
                // onClick={(evt: any) => handleMediaUpload(evt)}
              >
                <UploadOutlinedIcon sx={{ color: "#696F88" }} />
                <Typography
                  sx={{ ml: 1, color: "#228B22" }}
                  className={classes.UnderlineTextStyle}
                  variant="subtitle2"
                >
                  {t("str_upload_media")}
                </Typography>
              </Button>
            </label>
          </Box>
          <Box sx={{ display: "flex", gap: 2, mt: 2, flexWrap: "wrap" }}>
            {recordedFiles.length > 0 &&
              recordedFiles.map((media: any, index: number) => (
                <RenderMediaInput data={media} index={index} key={index} />
              ))}
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", paddingTop: "5%" }}
          >
            {renderMediaList(recordedFiles)}
          </Box>
        </Box>
      </Box>
      <Box>
        <Divider light />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mr: 3,
            py: 3,
          }}
        >
          {message.length === 0 && mediaList.length === 0 ? (
            <Button variant="contained" disabled>
              {t("str_respond")}
            </Button>
          ) : (
            <Button variant="contained" onClick={handleRespond} color="success">
              {t("str_respond")}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RespondView;
