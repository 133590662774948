import { Button, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { getIconImage } from '../../Components/Crop';
import { useStyles } from './style';

type DragAndDropProps = {
    name: string;
    multiple?: boolean;
    label?: string;
    disabled?: boolean;
    hoverTitle?: string;
    classes?: string;
    types?: string[];
    maxSize?: number;
    minSize?: number;
    onSizeError?: Function;
    onTypeError?: Function;
    handleChange: Function
}

export const DragAndDrop: React.FC<DragAndDropProps> = (props: DragAndDropProps) => {
    const renderChildren = () => {
        const classes = useStyles();
        return <Button
            fullWidth
            variant="contained"
            size="large"
            component="span"
            disableRipple
            disableElevation
            disableTouchRipple
            sx={{
                whiteSpace: 'nowrap',
                height: '146px',
                border: '1px dashed #C2C7D0',
                color: '#14151C',
                background: '#F3F4F6',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                ':hover': { background: '#C2C7D0' },
            }}
        >
            {getIconImage('ImgageUploader')}
            <Typography component='p' variant='body1' margin='1rem 0 5px 0'>Click or drag file to this area
                to upload</Typography>
            <Typography component="small" variant="caption" className={classes.uploadBtnCaptionText}>
                Support for a single or bulk upload.{' '}
            </Typography>
        </Button>
    }
    return (
        <>
            <Stack>
                <Stack
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    spacing={1}
                    marginTop={3}
                >
                    <label
                        htmlFor="contained-button-file"
                        style={{ width: '80%', marginInline: 'auto' }}
                    >

                        <FileUploader {...props} children={renderChildren()} />
                    </label>
                    {/* <Box width={128} height={128} sx={{background:'red'}}>Image Should Display here</Box> */}
                </Stack>
            </Stack>
        </>
    );
}
