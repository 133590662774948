import React, {useEffect} from "react";
import PlantixMapProductView from "./PlantixMapProduct.View";
import {ActionType, ErrorTableHeaders} from "../../Common/Constants";
import {IRequestBody} from "../../Constants/interfaces";
import api_endpoints, {base_url} from "../../Api/end_points";
import {api_service} from "../../Api/api_service";
import {TriggerToastMessage} from "../../Utils/toastTrigger";
import KnowMore from "../../Common/KnowMoreErrorModal/knowMoreDetails";
import {PlantixPeatidResponseObject} from "./types";
import ViewPlantixMapProduct from "./ViewPlantixMapProduct";
import {formatDate} from "../../Utility/DateFormat";
import _ from "../../Utils/lodash";
import {ConvertJSONToXLS} from "../../Utils/fileHelper";
import {checkExportToExcelAllowed} from "../../Api/generic_apicalls";

export const INITIAL_FILTER_STATE: filterObjType = {
  languageIds: [],
  status: [],
  crop: [],
  category: [],
};

export type filterObjType = {
  languageIds: any[];
  status: any[];
  crop: any[];
  category: any[];
};

const PlantixMapProduct: React.FC = () => {
  const [searchValue, setSearchValue] = React.useState("");
  const [activeTab, setActiveTab] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [sortId, setSortId] = React.useState<"0" | "1" | "">("1");
  const [filterObj, setFilterObj] = React.useState(INITIAL_FILTER_STATE);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [actionType, setActionType] = React.useState("");
  const [showCreateOrEditModal, setShowCreateOrEditModal] =
    React.useState(false);
  const [showBulkUpload, setShowBulkUpload] = React.useState(false);
  const [dataToDisplay, setDataToDisplay] = React.useState<Array<any>>([]);
  const [dataToDisplayIsChecked, setDataToDisplayIsChecked] = React.useState<
    Array<any>
  >([]);

  const [myUploadData, setMyUploadData] = React.useState<any | undefined>(
    undefined
  );
  const [openKnowMore, setOpenKnowMore] = React.useState<boolean>(false);
  const [errorList, setErrorList] = React.useState<Array<any> | undefined>(
    undefined
  );

  const [showViewModal, setShowViewModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<number | undefined>(
    undefined
  );

  const [buttonExportVisible, setButtonExportVisible] =
    React.useState<boolean>(false);

  const handleSearchValueChange = (newValue: string) => {
    setSearchValue(newValue);
  };

  //check or uncheck row
  const checkOrUncheckRow = (status: boolean, data: any) => {
    const filterData: any = dataToDisplay.map((isdata: any) => {
      if (isdata.id === data.id) {
        isdata.checked = status;
      }

      return isdata;
    });

    setDataToDisplay(filterData);
    filterDataBasedOnPageChecked(currentPage, filterData);
  };

  const AssignCheckedFlag = (status: boolean) => {
    const filterData: any = dataToDisplay.map((isdata: any) => {
      isdata.checked = status;
      return isdata;
    });

    filterDataBasedOnPageChecked(currentPage, filterData);
  };

  // Filter data based on page checked
  const filterDataBasedOnPageChecked = (page: number, data: any) => {
    if (dataToDisplayIsChecked.length < 1) {
      setDataToDisplayIsChecked([
        {
          page: page,
          data: data,
        },
      ]);
      return;
    }

    const checkPageIsExist = dataToDisplayIsChecked.some(
      (oldData) => oldData.page === page
    );
    if (checkPageIsExist) {
      const filterData: any = dataToDisplayIsChecked.map((isdata: any) => {
        if (isdata.page === page) {
          return {
            page,
            data,
          };
        }
        return isdata;
      });
      setDataToDisplayIsChecked(filterData);
      return;
    }

    setDataToDisplayIsChecked([
      ...dataToDisplayIsChecked,
      {
        page: page,
        data: data,
      },
    ]);
  };

  const replaceDataAlreadyExistWithChecked = () => {
    if (dataToDisplayIsChecked.length < 1) return;

    const dataCheckedPage = dataToDisplayIsChecked.filter((data) => {
      return data.page === currentPage;
    });
    if (currentPage === dataCheckedPage?.[0]?.page) {
      setDataToDisplay(dataCheckedPage?.[0]?.data);
    }
  };

  const convertMatrictToArray = () => {
    const data = dataToDisplayIsChecked.map((data) => {
      return data.data;
    });

    const dataFromOtherPage = dataToDisplayIsChecked.some((data) => {
      if(data.page !== currentPage) {
        return data.data.some((data: any) => data.checked === true)
      }
      return false
    })

    return {
      data: data.flat(),
      dataFromOtherPage
    }
  };

  // Export to excel
  const handleExportToExcel = () => {
    const {data, dataFromOtherPage} = convertMatrictToArray();

    const selectedCpProductInfo = _.filter(data, { checked: true });
    const publishPendingStatus = selectedCpProductInfo.some((value: any) => value.statusId === 5);

    if (publishPendingStatus) {
      const confirmationExport = confirm('Exporting draft record for \'Publish Pending\' item');
      if(!confirmationExport) return;
    }

    if (dataFromOtherPage) {
      const confirmExport = confirm('CP Product from different page are selected')
      if (!confirmExport) return
    }


    if (selectedCpProductInfo.length === 0) {
      TriggerToastMessage(
        "Please select atleast one line to export",
        "warn"
      );
      return;
    }

    setTimeout(() => {
      const columnsData = [
        {
          label: "variety",
          value: "cropName",
        },
        {
          label: "peat_id",
          value: "crop",
        },
        {
          label: "peat_id",
          value: "peatId",
        },
        {
          label: "eppo_code",
          value: "eppoCode",
        },
        {
          label: "Product System name",
          value: "systemName",
        },
        {
          label: "disease_name",
          value: "diseaseName",
        },
      ];
      const dataObj = [
        {
          columns: columnsData,
          content: selectedCpProductInfo,
          sheet: "General Info",
        },
      ];
      let settings = {
        fileName: "Plantix Map Product_" + new Date().getTime(), // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };
      ConvertJSONToXLS(dataObj, settings);
    }, 500);
  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setFilterObj(INITIAL_FILTER_STATE);
    setSortId("1");
    setSearchValue("");
    setCurrentPage(0);
    setDataToDisplayIsChecked([]);
  };

  const handleClickAction = (clickAction: string, details?: any) => {
    switch (clickAction) {
      case ActionType.VIEW:
        setSelectedId(details?.id);
        setShowViewModal(true);
        break;
      case ActionType.DELETE:
        if (confirm("Are you sure want to delete?"))
          deletePublishedData(details?.id);
        break;
      case ActionType.CREATE:
        handleShowBulkUpload(true);
        break;
      default:
        handleExportToExcel();
        return;
    }
  };

  const handleKnowMorePopUp = (flag: boolean, rowData: any) => {
    setOpenKnowMore(flag);

    getErrorListData(rowData.id);

    setMyUploadData(rowData);
  };

  const getErrorListData = (id: number) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.bulkupload_error_list;
    apiData.endPoint = endPoint.replace("__ID__", `${id}`);
    apiData.showLoader = true;

    api_service
      .get(apiData)
      ?.then((res) => {
        if (res.status === 200) {
          setErrorList(res.data.data);
        }
      })
      .catch(() =>
        TriggerToastMessage("Unable to process your request", "error")
      );
  };

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleCloseViewModal = () => {
    setShowViewModal(false);
  };

  const handleShowCreateOrEditModal = (toggle: boolean) => {
    if (!toggle) {
      // TODO
    }
    setActionType(ActionType.CREATE);
    setOpen(false);
    setShowCreateOrEditModal(toggle);
  };

  const handleShowBulkUpload = (toggle: boolean) => {
    setOpen(false);
    setShowBulkUpload(toggle);
  };

  const fetchDataList = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.plantix_cp_mapping;
    apiData.payLoad = {
      pageNo: currentPage,
      pageSize: limit,
    };

    if (filterObj.crop.length > 0) {
      apiData.payLoad.crop = filterObj.crop
          .map((filterCropObj: any) => {
            return filterCropObj.split(":::")[0];
          })
          .join(",");
    }

    if (searchValue) {
      apiData.payLoad.term = searchValue.toLocaleLowerCase();
    }

    if (sortId) {
      apiData.payLoad.sort = sortId === "1" ? "desc" : "asc";
    }
    apiData.showLoader = true;

    api_service
      .get(apiData)
      ?.then((res) => {
        let listData: PlantixPeatidResponseObject = res.data.data;
        let data: any[] = [];

        console.log(listData);

        listData.products.forEach((item) => {
          data.push({
            id: item.id,
            cropName: item.cropName,
            diseaseName: item.diseaseName,
            systemName: item.productSystemName,
            lastUpdatedOn: item.lastUpdatedDate
              ? formatDate(item.lastUpdatedDate)
              : null,
            eppoCode: item.eppoCode,
            peatId: item.peatId,
          });
        });
        setTotalPages(listData.totalPages);
        setDataToDisplay(data);
      })
      .catch(() => {
        TriggerToastMessage("unable to process your request", "error");
      });
  };

  const fetchMyUploads = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.bulk_upload;
    apiData.payLoad = {
      dataType: "Plantix Peatid Mapping",
      pageNo: currentPage,
      pageSize: limit,
      sortId: parseInt(sortId),
    };

    if (searchValue) {
      apiData.payLoad.searchKey = searchValue.toLocaleLowerCase();
    }

    api_service
      .get(apiData)
      ?.then((res) => {
        let listData = res.data.data;
        let data: any[];

        data = listData.uploadDocumentList?.map((item: any) => {
          return {
            id: item.id,
            fileName: item.fileName,
            cropsUploadDate: formatDate(item.createdDate, "YYYY/MM/DD hh:mm a"),
            comnMyUploadStatus: item.status,
          };
        });

        setTotalPages(Math.ceil(listData.totalElements / listData.pageSize));
        setDataToDisplay(data);
      })
      .catch(() => {
        TriggerToastMessage("unable to process your request", "error");
      });
  };

  const deletePublishedData = (systemName: string) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.plantix_cp_mapping_delete.replace(
      "__systemName__",
      systemName
    );

    api_service
      .delete(apiData)
      .then(() => {
        TriggerToastMessage("Deleted Succesfully", "success");
        fetchData();
      })
      .catch(() => {
        TriggerToastMessage("Unable to process your request", "error");
      });
  };

  useEffect(() => {
    if (currentPage === 0) {
      fetchData();
    } else {
      setCurrentPage(0);
    }
  }, [limit, searchValue, filterObj, sortId, activeTab]);

  useEffect(() => {
    setDataToDisplay([]);
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    setDataToDisplayIsChecked([]);
  }, [limit]);

  useEffect(() => {
    if (dataToDisplay.length < 1) return;
    replaceDataAlreadyExistWithChecked();
  }, [currentPage, dataToDisplay]);

  useEffect(() => {
    checkExportToExcelAllowed("Plantix Map Product")?.then((res) =>
      setButtonExportVisible(res)
    );
  }, []);

  const fetchData = () => {
    setDataToDisplay([]);
    switch (activeTab) {
      case 0:
        // datalist
        fetchDataList();
        break;
      case 1:
        // myupload
        fetchMyUploads();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <PlantixMapProductView
        searchValue={searchValue}
        activeTab={activeTab}
        limit={limit}
        sortId={sortId}
        filterObj={filterObj}
        currentPage={currentPage}
        totalPages={totalPages}
        open={open}
        showCreateOrEditModal={showCreateOrEditModal}
        actionType={actionType}
        showBulkUpload={showBulkUpload}
        onSearchValueChange={handleSearchValueChange}
        onChange={handleChange}
        onClickAction={handleClickAction}
        onKnowMorePopUp={handleKnowMorePopUp}
        onOpenModal={handleOpenModal}
        onCloseModal={handleCloseModal}
        onSelectAll={AssignCheckedFlag}
        onRowSelect={checkOrUncheckRow}
        onShowCreateOrEditModal={handleShowCreateOrEditModal}
        onShowBulkUpload={handleShowBulkUpload}
        setLimit={setLimit}
        setSortId={setSortId}
        setFilterObj={setFilterObj}
        setCurrentPage={setCurrentPage}
        dataToDisplay={dataToDisplay}
        buttonExportVisible={buttonExportVisible}
      />
      {openKnowMore && (
        <KnowMore
          handleKnowMoreClose={(flag: boolean) => {
            setOpenKnowMore(flag);
            setMyUploadData(undefined);
          }}
          title={myUploadData && myUploadData?.fileName}
          tableHeaders={ErrorTableHeaders}
          tableData={errorList}
          openBulkUploadPopup={() => {
            setShowBulkUpload(true);
            setOpenKnowMore(false);
          }}
        />
      )}
      {showViewModal && (
        <ViewPlantixMapProduct
          onClose={handleCloseViewModal}
          selectedId={selectedId}
        />
      )}
    </>
  );
};

export default PlantixMapProduct;
