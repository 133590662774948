import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Fab,
  FormControl,
  MenuItem,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import _ from "lodash";
import { useEffect, useState } from "react";
import api_endpoints, { base_url } from "../../../Api/end_points";
import ButtonWithIcon from "../../../Common/Buttons";
import { DownLoadSampleTemplate } from "../../../Common/DownloadSampleTemplate";
import { DragAndDrop } from "../../../Common/DragAndDrop";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { IRequestBody } from "../../../Constants/interfaces";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import {
  UpdateBulkData,
  ValidateBulkUploadData,
} from "../../Crop/CropsAPICalls";
import { TOASTMASTER_POPUP_TYPE } from "../../../Common/Constants";
import { getCrops } from "../../PushNotifications/AddEditNotification/PushNotificationAPICalls";
import { useStyles } from "./styles";
import getRandomizeValue from "../../../Utils/randomizer";
interface IBulkUploadProps {
  maxFileSize: number;
  templateLiteral: string;
  validationLiteral: string;
  allowedTypes: string[];
  onClose: Function;
  reuploadId?: number | null;
  canByPassValidation: boolean;
  hasCropDD?: boolean;
}
type RenderFieldProps = {
  label: string;
  labelKey?: string;
  name: string;
  onChange: Function;
  options: Array<any>;
  selectedOption?: any;
  value: string | undefined;
  isDisabled?: boolean;
};
export type CropSelectionType = {
  id: number;
  nameTranslation: string;
  name: string;
};
const RenderField = (props: RenderFieldProps) => {
  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    props.onChange(name, value);
  };

  return (
    <FormControl className={classes.formControlMrtHelper} fullWidth>
      <Typography variant="subtitle2">{props.label}</Typography>
      <Select
        name={props.name}
        MenuProps={{ classes: { paper: classes.Select } }}
        onChange={handleChange}
        IconComponent={(props) => (
          <ExpandMoreIcon {...props} className={classes.selectExpandMore} />
        )}
        value={props.selectedOption || props.value}
        renderValue={(value) =>
          (typeof value === "object" &&
            props.labelKey &&
            value[props.labelKey]) ||
          value
        }
        disabled={!!props.isDisabled}
      >
        {props.options.map((option: any, index: number) => {
          return (
            <MenuItem key={index} value={option}>
              <Typography
                className={classes.lineHeightHelper}
                variant="body1"
                fontSize={14}
              >
                {(props.labelKey && option[props.labelKey]) || option}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
const BulkUploadModal = (props: IBulkUploadProps) => {
  const classes = useStyles();
  const [fileList, setFilesList] = useState<File | null>();
  const [crops, setCrops] = useState<Array<any>>([]);
  const [selectedCrop, setSelectedCrop] = useState<CropSelectionType>();


  const submitBulkData = (canOverRideDraft: boolean) => {
    console.log("props.validationLiteral:" + props.validationLiteral);
    let apiData = {} as IRequestBody;
    apiData.endPoint = api_endpoints.bulk_upload;
    /** payload */
    let formData: any = new FormData();
    formData.append("file", fileList);
    formData.append("dataType", props.validationLiteral);
    if (canOverRideDraft) {
      /** if not re-uploading */
      formData.append("isDuplicateDraftExist", true);
    } else {
      /** if re-uploading */
      formData.append("isDuplicateDraftExist", false);
    }
    if (props.reuploadId) {
      formData.append("bulkUploadId", props.reuploadId);
    }
    /** When CROP DD enabled: like in CP Product */
    if (props.hasCropDD) {
      formData.append("cropSystemName", selectedCrop?.name);
    }
    apiData.payLoad = formData;
    UpdateBulkData(apiData, successCallBack, errorCallback);
  };

  const successCallBack = (response: any, type: string) => {
    switch (type) {
      case "validate_bulk": {
        /** if response.data is not available, than
         * cosidering it as a new record else as a duplidate record
         */
        console.log("RES:" + JSON.stringify(response));
        if (response.statusCode === 200 && !response.data) {
          TriggerToastMessage(
            response.message
              ? response.message
              : "New file uploaded successfully",
            TOASTMASTER_POPUP_TYPE.SUCCESS
          );
          props.onClose(1);
        } else if (response.statusCode === 200) {
          let msg: string = "";
          if (response.data && response.data.length === 1) {
            msg =
              "Alert!! Single language Draft record is observed while uploading the file.. do you want to overwrite them ?";
          } else {
            msg =
              "Alert!! Multiple language Draft records are observed while uploading the file.. do you want to overwrite them ?";
          }
          const canOverRide: boolean = confirm(msg);
          if (canOverRide) {
            submitBulkData(canOverRide);
          }
        } else {
          /** Handlig Something wrong with uploaded file scenerio */
          TriggerToastMessage(
            response.errorMessage,
            TOASTMASTER_POPUP_TYPE.ERROR
          );
        }
        break;
      }
      case "update_bulk": {
        if (response.statusCode === 200) {
          TriggerToastMessage(response.message, TOASTMASTER_POPUP_TYPE.SUCCESS);
          setFilesList(null);
          props.onClose(1);
        } else {
          /** Handlig Something wrong with uploaded file scenerio */
          TriggerToastMessage(
            response.errorMessage,
            TOASTMASTER_POPUP_TYPE.ERROR
          );
        }
        break;
      }
      case "crops": {
        let cropsData = response.data.cropsList;
        setCrops(_.orderBy(cropsData, ["name"], ["asc"]));
        break;
      }
      default:
        break;
    }
  };

  const errorCallback = (_err: any) => {
    console.log("_err:" + JSON.stringify(_err));
    TriggerToastMessage(
      _err.errorMessage ? _err.errorMessage : "Unable to process your request.",
      TOASTMASTER_POPUP_TYPE.ERROR
    );
    return;
  };

  const validateUploadedFileContent = () => {
    console.log("validateUploadedFileContent");
    if (fileList) {
      if (props.reuploadId || props.canByPassValidation) {
        console.log("validateUploadedFileContent1");
        submitBulkData(false);
        return;
      }
      console.log("validateUploadedFileContent2");
      let apiData = {} as IRequestBody;
      apiData.endPoint = api_endpoints.validate_bulk_upload;
      /** payload */
      console.log("props.validationLiteral:" + props.validationLiteral);
      let formData: any = new FormData();
      formData.append("file", fileList);
      formData.append("dataType", props.validationLiteral);
      /** When CROP DD enabled: like in CP Product */
      if (props.hasCropDD) {
        formData.append("cropSystemName", selectedCrop?.name);
      }
      apiData.payLoad = formData;
      ValidateBulkUploadData(apiData, successCallBack, errorCallback, fileList);
    }
  };
  const fetchCropList = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.crops;
    apiData.payLoad = {
      pageNo: 0,
      pageSize: 150,
    };
    apiData.showLoader = true;
    return getCrops(apiData, successCallBack, errorCallback);
  };
  useEffect(() => {
    if (props.hasCropDD) {
      fetchCropList();
    }
  }, [props.hasCropDD]);
  return (
    <>
      <div>
        <Modal open={true} disableEscapeKeyDown={true} onClose={() => {}}>
          <Paper className={classes.formPaperBulkUpload}>
            <Paper elevation={0} square>
              <ModalHeader
                header={props.validationLiteral}
                showDelete={false}
                showEdit={false}
                onClose={() => {
                  props.onClose();
                }}
              />
              <Box className={classes.bulkUploadModalContainerHelper}>
                <Box>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    className={classes.bulkDataBorderBottom}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Fab
                        disableFocusRipple
                        disableTouchRipple
                        disableRipple
                        color="primary"
                        className={classes.fabBtnShadowHelper}
                        size="small"
                      >
                        1
                      </Fab>
                      <Typography color="primary" variant="subtitle2">
                        Download the sample template
                      </Typography>
                    </Stack>
                    <DownLoadSampleTemplate
                      literal={props.templateLiteral}
                      displayAsButton={true}
                    />
                  </Stack>
                  {props.hasCropDD && (
                    <Stack className={classes.bulkDataBorderBottom}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Fab
                          disableFocusRipple
                          disableTouchRipple
                          disableRipple
                          color="primary"
                          className={classes.fabBtnShadowHelper}
                          size="small"
                        >
                          2
                        </Fab>
                        <Box width="100%">
                          <Typography color="primary" variant="subtitle2">
                            Select Crop for bulk upload
                          </Typography>
                          <RenderField
                            name="crop"
                            label=""
                            labelKey="name"
                            selectedOption={selectedCrop?.name}
                            value={""}
                            options={crops}
                            onChange={(
                              name: string,
                              selectedType: CropSelectionType
                            ) => {
                              setSelectedCrop(selectedType);
                            }}
                          />
                        </Box>
                      </Stack>
                    </Stack>
                  )}
                  {(!props.hasCropDD ||
                    (props.hasCropDD &&
                      selectedCrop &&
                      selectedCrop?.name)) && (
                    <Stack width="100%">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Fab
                          disableFocusRipple
                          disableTouchRipple
                          disableRipple
                          color="primary"
                          className={classes.fabBtnShadowHelper}
                          size="small"
                        >
                          {props.hasCropDD ? 3 : 2}
                        </Fab>
                        <Typography color="primary" variant="subtitle2">
                          Upload the completed template
                        </Typography>
                      </Stack>
                      <DragAndDrop
                        maxSize={props.maxFileSize}
                        types={props.allowedTypes}
                        multiple={false}
                        label="Click or Drop your file here"
                        name={"bulk upload in crop"}
                        key={fileList?.name || getRandomizeValue()}
                        handleChange={(selectedFileInfo: any) => {
                          // setFileData(onChangeInfo);
                          /** Max limit for file size validation */
                          let fileMB = selectedFileInfo.size / 1000 / 1000;
                          if (fileMB <= 5) {
                            setFilesList(selectedFileInfo);
                            return;
                          }
                        }}
                        onTypeError={() => {
                          TriggerToastMessage(
                            `Unsupported file type.`,
                            TOASTMASTER_POPUP_TYPE.WARN
                          );
                        }}
                        onSizeError={() => {
                          TriggerToastMessage(
                            `File exceeds max file size of 5  MB`,
                            TOASTMASTER_POPUP_TYPE.WARN
                          );
                        }}
                      />
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        minWidth="250px"
                        className={classes.bulkUploadMainBtn}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          width="100%"
                          justifyContent="space-between"
                        >
                          {fileList && (
                            <>
                              <Typography
                                color="primary"
                                variant="body1"
                                component="span"
                              >
                                {fileList.name}
                              </Typography>
                              <Box>
                                <CloseOutlinedIcon
                                  onClick={() => setFilesList(null)}
                                />
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Stack>
                      <Stack
                        width="330px"
                        className={classes.bulkUploadActionBtnHelper}
                      >
                        <ButtonWithIcon
                          label={"Submit"}
                          type="MODAL CREATE"
                          onClick={() => {
                            validateUploadedFileContent();
                          }}
                        />
                      </Stack>
                    </Stack>
                  )}
                </Box>
              </Box>
            </Paper>
          </Paper>
        </Modal>
      </div>
    </>
  );
};

export default BulkUploadModal;
