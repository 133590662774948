import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {UploadFile} from "../../../Common/UploadFile";
import {Box, FormControl, FormHelperText, Modal, Paper, TextField,} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {ErrorMessage, Field, Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import api_endpoints, {base_url} from "../../../Api/end_points";
import {getFileUploadPayload} from "../../../Api/generic_apicalls";
import {ModuleImageUpload} from "../../../Common/Constants";
import ReactDatePicker from "../../../Common/DateAndTimePickers/DatePicker";
import {LanguageType} from "../../../Common/LanguageDropdown/types";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import {Category, MediaType} from "../../../Constants/contants";
import {IRequestBody} from "../../../Constants/interfaces";
import {RootState} from "../../../redux/reducers";
import {genericValidateImage} from "../../../Utils/fileHelper";
import {TriggerToastMessage} from "../../../Utils/toastTrigger";
import {getDashboardStates, saveDashboardMedia, viewDashboardMedia,} from "../DashboardMediaApiCalls";
import {useStyles} from "./style";
import {NewDashboardValidation} from "./ValidationSchemaDashboard";
import RenderField from "../../../Common/RenderField";

import moment from "moment";
const formatDate = "DD/MM/YYYY";


export interface ICreateDashboardProps {
  action: string;
  dashboardMediaDetails?: any;
  onCloseCreateDashboardMedia: Function;
  onCreateDashboard: Function;
  showCreateProgress?: boolean;
  isDraftRecord: boolean;
  uniqueKey: number;
}


export type DashboardMediaType = {
  mediatitle: string;
  mediaType: string;
  endDate: Date | null;
  languageName: any;
  id: number | undefined;
  startDate: Date | any;
  stateId: Array<number> | undefined;
  mediaurl: string;
  stateNames: string;
  category: string;
  displayOrder: string;
  image: any;
};

const initialState: DashboardMediaType = {
  languageName: "",
  stateNames: "",
  stateId: undefined,
  startDate: null,
  endDate: null,
  mediatitle: "",
  mediaurl: "",
  id: undefined,
  mediaType: "",
  category: "",
  displayOrder: "",
  image: "",
};

type LangObjectType = {
  languageId: any;
  languageName: string;
  languageCode: string;
};

const AddEditDashboard: React.FC<ICreateDashboardProps> = (props) => {
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
      primaryLanguageCode: state.auth.languageCode,
    };
  });

  // variables
  const isEdit = props.action === "Edit_Push";
  const classes = useStyles();

  // states
  const [showCreateDashboardMedia, setShowCreateDashboardMedia] =
    useState<boolean>(true);
  const [languageList, setLanguageList] = useState<LanguageType[]>(
    storeData.languages
  );
  const [selectedLangObj, setSelectedLangObj] = useState<LangObjectType>({
    languageId: "",
    languageName: "",
    languageCode: "",
  });
  const [selectedStateId, setSelectedStateId] = useState<any>("");
  const [dashboardMedia, setDashboardMedia] =
    useState<DashboardMediaType>(initialState);

  const [dashboardStateList, setDashboardStateList] = useState<Array<any>>([]);
  const [resetStateNames, setResetStateNames] = useState(true);
  // variables

  const header: string = (isEdit && "Edit Media") || "New Dashboard Media";
  /**state variable to store total sms users count*/
  // exctract props
  const { onCloseCreateDashboardMedia } = props;

  const handleClose = (isCreatSuccess?: boolean, isEdit?: boolean) => {
    setDashboardMedia(initialState);
    setShowCreateDashboardMedia(false);
    onCloseCreateDashboardMedia(isCreatSuccess, isEdit);
  };

  const validateMediaUrl = (value: string) => {
    let error;
    if (value.length > 255) {
      error = "URL should be less than 255 characters";
    }
    return error;
  };

  const handleLanguageChange = (languageName: string) => {
    let langData: any = {};
    languageList.map((data) => {
      if (data.languageName === languageName) {
        langData = {
          languageId: data.languageId,
          languageName: data.languageName,
          languageCode: data.languageCode,
        };
        return;
      }
    });
    updatedashboardMediaDetails("languageName", languageName);
    setSelectedLangObj(langData);
  };

  const validationTrimUrl = (url: string): string => {
    const trimmedUrl = url.trim();
    return trimmedUrl.replace(/\s/g, "");
  };

  const updatedashboardMediaDetails = (
    name: string,
    value: string | boolean | Date | string[]
  ) => {
    setDashboardMedia((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStateChange = (value: any, name: string) => {
    let stateId = dashboardStateList.find(
      (state) => state.stateName === value
    )?.stateId;

    setSelectedStateId(stateId);
    updatedashboardMediaDetails("stateNames", value);
  };
  /** Filed level validation for Image file upload */
  const validateImage = (value: any) => {
    let errorResult = genericValidateImage(value);
    if (errorResult === "Image required") {
      // Image field is optional
      return "Image required";
    } else {
      return errorResult;
    }
  };


  // for drop-down api calls
  const successCallBack = (response: any, type: string) => {
    switch (type) {
      case "languages": {
        break;
      }
      case "Dashboard_states": {
        setDashboardStateList(response.data);

        // if (
        //   (response.data as string[]).length ===
        //   dashboardMedia.stateNames.length
        // ) {
        //   setDashboardMedia((prev) => ({
        //     ...prev,
        //     stateNames: [...prev.stateNames, "select all"],
        //   }));
        // }
        break;
      }
      case "save_dashboardmedia":
        /** closing the popup after getting success code*/
        if (response.statusCode === 200) {
          handleClose(true, isEdit);
        } else {
          /**Uncomment it before release */
          handleClose(false, isEdit);
        }

        TriggerToastMessage(response.message, "success");
        break;
      case "view_dashboardmedia":
        if (response.statusCode === 200) {
          let responseData = response?.data;

          let updatedObject: DashboardMediaType | null = null;

          if (props.isDraftRecord) {
            updatedObject = {
              languageName: responseData.languageName,
              startDate: responseData.startDate,
              endDate: responseData.endDate,
              stateNames: responseData.payload.stateName,
              mediatitle: responseData.payload.title,
              mediaurl: responseData.payload.feedurl,
              displayOrder: responseData.payload.displayOrder,
              category: responseData.payload.category,
              mediaType: responseData.payload.type,
              image: responseData.payload.feedurl,
              id: responseData.id,
              stateId: responseData.languageId,
            };
          } else {
            updatedObject = {
              languageName: responseData.languageName,
              startDate: responseData.startDate,
              endDate: responseData.endDate,
              stateNames: responseData.payload.stateName,
              mediatitle: responseData.payload.title,
              mediaurl: responseData.payload.feedurl,
              displayOrder: responseData.payload.displayOrder,
              category: responseData.payload.category,
              mediaType: responseData.payload.type,
              image: responseData.payload.feedurl,
              id: responseData.payload.id,
              stateId: responseData.languageId,
            };
          }

          let langData: any = {};
          languageList.map((data) => {
            if (data.languageName === responseData.languageName) {
              langData = {
                languageId: data.languageId,
                languageName: data.languageName,
                languageCode: data.languageCode,
              };
            }
          });

          if (!updatedObject) return;
          setDashboardMedia(updatedObject);
          setSelectedLangObj(langData);
          setResetStateNames(false);

          if (isEdit && responseData.payload.uniqueStateId) {
            setSelectedStateId(responseData.payload.uniqueStateId);
          }
        }
        break;
      default: {
        return;
      }
    }
  };

  const errorCallback = (_error: any, type: string) => {
    switch (type) {
      case "save_update": {
        /** Closing the popup, passing false to display failed creation */
        handleClose(false);
        break;
      }
      case "view_dashboardmedia":
        {
          TriggerToastMessage("Unable to process your request", "error");
        }
        break;
      default: {
        /**Generic alert to display API fail */
        TriggerToastMessage(
          "Unable to process your request!!!" + type,
          "error"
        );
      }
    }
  };

  const fetchDashboardStates = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.country_wise_states;
    apiData.showLoader = true;
    if (selectedLangObj.languageCode) {
      apiData.customHeaders = {
        "language-code": selectedLangObj.languageCode,
      };
    }
    return getDashboardStates(apiData, successCallBack, errorCallback);
  };

  /**
   * @description performing saving and updation of dashboardMedia
   * @returns success or error call back response object
   */
  const saveUpdateDashboardMedia = (formikData: any): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.create_dashboard_media;
    apiData.showLoader = true;
    apiData.payLoad = getDashboardPayload(formikData);

    if (!props.isDraftRecord) {
      apiData.payLoad.id = null;
    }

    saveDashboardMedia(apiData, successCallBack, errorCallback);
  };

  const getDashboardPayload = (formikData: any) => {
    let data: any = {};
    // if (isEdit === true && props.isDraftRecord === true) {
    //   // data.id = formikData.id
    //   data.id = props.dashboardMediaDetails.id;
    //   data.statusId = dashboardMediaDetails.statusId;
    // } else {
    //   data.id = null;
    //   data.statusId = 0;
    // }

    if (isEdit) {
      data.id = dashboardMedia.id;
    } else {
      data.id = null;
    }

    /** language related payload */
    data.languageId = selectedLangObj.languageId;
    data.languageName = selectedLangObj.languageName;
    data.languageCode = selectedLangObj.languageCode;
    data.payload = {
      category: formikData.category,
      displayOrder: formikData.displayOrder,
      id: isEdit ? formikData.id : null,
      // id: isEdit ? dashboardMediaDetails.id : null,
      languageId: selectedLangObj.languageId,
      languageName: selectedLangObj.languageName,
      stateName: formikData.stateNames,
      title: formikData.mediatitle,
      type: formikData.mediaType.toUpperCase(),
      uniqueStateId: selectedStateId,
    };
    data.smartGrowerDashboardId = formikData.id;
    data.payload = { ...data.payload, thumbnail: formikData.thumbnail || "" };

    if (formikData.mediaType.toUpperCase() === "VIDEO") {
      data.payload = {
        ...data.payload,
        feedurl: formikData.mediaurl || "",
      };
    }

    if (formikData.mediaType.toUpperCase() === "IMAGE") {
      data.payload = {
        ...data.payload,
        feedurl: formikData.thumbnail || "",
      };
    }

    data.fromDate = formikData.startDate
      ? moment(formikData.startDate, "DD/MM/YYYY HH:mm:ss")
          .utc()
          .format(formatDate)
      : null;
    data.toDate = formikData.startDate
      ? moment(formikData.endDate, "DD/MM/YYYY HH:mm:ss")
          .utc()
          .format(formatDate)
      : null;
    return data;
  };

  useEffect(() => {
    if (isEdit) {
      getMediaDetailsByID(props.dashboardMediaDetails.id);
    }
  }, []);

  useEffect(() => {
    if (selectedLangObj.languageCode) {
      fetchDashboardStates();
    }
  }, [selectedLangObj]);

  useEffect(() => {
    if (resetStateNames) {
      handleStateChange("", "stateNames");
    }
  }, [resetStateNames]);

  const getMediaDetailsByID = (feedId: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.view_dashboard_media.replace(
      "__FEEDID__",
      feedId
    );
    if (props.isDraftRecord) {
      apiData.endPoint = api_endpoints.get_draft_media_content.replace(
        "__MEDIA_ID__",
        feedId
      );
    }
    apiData.showLoader = true;
    return viewDashboardMedia(apiData, successCallBack, errorCallback);
  };
  return (
    <Formik
      initialValues={dashboardMedia}
      enableReinitialize={true}
      validationSchema={NewDashboardValidation}
      onSubmit={(values: any, _onSubmitProps: any) => {
        if (
          values.mediaType.toLocaleLowerCase() === "image" &&
          typeof values.image === "object"
        ) {
          getFileUploadPayload(
            values.image,
            "",
            ModuleImageUpload.DASHBOARD_ASSET_UPLOAD,
            (response: any) => {
              values.thumbnail = response.data.imageUrl;
              saveUpdateDashboardMedia(values);
              _onSubmitProps.resetForm();
            },
            () => {
              _onSubmitProps.setFieldError("image", "Unable to upload file.");
              _onSubmitProps.setSubmitting(false);
              return;
            }
          );
        } else {
          if (values.mediaType.toLocaleLowerCase() === "image") {
            values.thumbnail = values.image;
          }
          saveUpdateDashboardMedia(values);
          _onSubmitProps.resetForm();
        }
      }}
    >
      {({ values }) => (
        <Modal open={showCreateDashboardMedia}>
          <Form className={classes.formOutlineHelper}>
            <Paper className={classes.formPaper}>
              <ModalHeader
                header={header}
                onClose={() => {
                  if (confirm("You may lose your data.")) {
                    handleClose();
                  }
                }}
              />
              <Box className={classes.formContainer}>
                <div className={classes.formFieldsHelper}>
                  <Field name="languageName">
                    {(_props: any) => {
                      const { form } = _props;
                      const { setFieldValue } = form;
                      return (
                        <FormControl fullWidth>
                          <Typography variant="subtitle2">
                            Language *
                          </Typography>
                          <Select
                            value={values.languageName}
                            MenuProps={{ classes: { paper: classes.Select } }}
                            onChange={(event: any) => {
                              handleLanguageChange(event.target.value);
                              setResetStateNames(true);
                              setFieldValue("languageName", event.target.value);
                            }}
                            IconComponent={() => (
                              <ExpandMoreIcon
                                className={classes.selectExpandMore}
                              />
                            )}
                            variant="outlined"
                          >
                            {languageList?.map((o) => {
                              return (
                                <MenuItem
                                  key={o.languageName}
                                  value={`${o.languageName}`}
                                >
                                  {o.languageName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      );
                    }}
                  </Field>
                  <ErrorMessage name="languageName">
                    {(
                      errorMsg:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                    ) => (
                      <FormHelperText error={true}>{errorMsg}</FormHelperText>
                    )}
                  </ErrorMessage>

                  <Field name="stateNames">
                    {(stateNameProperties: any) => {
                      const { form } = stateNameProperties;
                      const { setFieldValue } = form;
                      return (
                        <FormControl fullWidth>
                          <Typography variant="subtitle2">State *</Typography>
                          <Select
                            disabled={!values.languageName}
                            multiple={false}
                            value={values.stateNames}
                            MenuProps={{ classes: { paper: classes.Select } }}
                            onChange={(event: SelectChangeEvent<string>) => {
                              const {
                                target: { value },
                              } = event;

                              // let data = (value as string[]).filter(
                              //   (item) => item !== "select all"
                              // );

                              // if (data.length === dashboardStateList.length) {
                              //   data.push("select all");
                              // }

                              // if (
                              //   value.indexOf("select all") > -1 &&
                              //   values.stateNames.indexOf("select all") === -1
                              // ) {
                              //   data = dashboardStateList.map(
                              //     (item) => item.stateName
                              //   );
                              //   data.push("select all");
                              // }

                              // if (
                              //   value.indexOf("select all") === -1 &&
                              //   values.stateNames.indexOf("select all") > -1
                              // ) {
                              //   data = [];
                              // }

                              handleStateChange(value, "stateNames");
                              setFieldValue("stateNames", value);
                            }}
                            IconComponent={() => (
                              <ExpandMoreIcon
                                className={classes.selectExpandMore}
                              />
                            )}
                            variant="outlined"
                            renderValue={(value) => value}
                            // renderValue={(value) =>
                            //   value
                            //     .filter((value) => value !== "select all")
                            //     .join(", ")
                            // }
                          >
                            {/* {dashboardStateList.length > 1 && (
                              <MenuItem key="select all" value="select all">
                                <Checkbox
                                  checked={
                                    values.stateNames.indexOf("select all") > -1
                                  }
                                />
                                Select All
                              </MenuItem>
                            )} */}
                            {dashboardStateList?.map((o) => {
                              return (
                                <MenuItem
                                  key={o.stateName}
                                  value={`${o.stateName}`}
                                >
                                  {o.stateName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      );
                    }}
                  </Field>

                  <ErrorMessage name="stateNames">
                    {(
                      errorMsg:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                    ) => (
                      <FormHelperText error={true}>{errorMsg}</FormHelperText>
                    )}
                  </ErrorMessage>
                  <Field name="mediaType" id="mediaType">
                    {(_props: any) => {
                      const { form } = _props;
                      const { setFieldValue } = form;
                      return (
                        <FormControl
                          className={classes.formControlMrtHelper}
                          fullWidth
                        >
                          <RenderField
                            label={"Media Type *"}
                            labelKey={"label"}
                            name={"mediaType"}
                            onChange={(name: string, value: any) => {
                              setFieldValue("mediaType", value.label);
                              updatedashboardMediaDetails(
                                "mediaType",
                                value.label
                              );
                              return;
                            }}
                            options={MediaType}
                            selectedOption={values.mediaType}
                            value={""}
                            isDisabled={false}
                            displayRadioButton={true}
                          />
                        </FormControl>
                      );
                    }}
                  </Field>
                  <FormHelperText error={true}>
                    <ErrorMessage name="mediaType" />
                  </FormHelperText>
                  <Stack flex={1}>
                    <FormControl className={classes.formControlMrtHelper}>
                      <Typography fontSize={14} variant="subtitle2">
                        Media Title *
                      </Typography>
                      <Field name="mediatitle">
                        {(titleProps: any) => {
                          const { form } = titleProps;
                          const { setFieldValue } = form;
                          return (
                            <TextField
                              fullWidth
                              name="mediatitle"
                              onChange={(event) => {
                                setFieldValue("mediatitle", event.target.value);
                                updatedashboardMediaDetails(
                                  "mediatitle",
                                  event.target.value
                                );
                              }}
                              inputProps={{ maxLength: 50 }}
                              value={values?.mediatitle}
                              //key={dashboardMedia?.mediatitle}
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage name="mediatitle">
                        {(
                          errorMsg:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined
                        ) => (
                          <FormHelperText error={true}>
                            {errorMsg}
                          </FormHelperText>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  </Stack>
                  {
                    <FormControl
                      className={classes.formControlMrtHelper}
                      fullWidth
                    >
                      <Stack direction="row" spacing={1}>
                        <Stack>
                          <Typography
                            fontSize={14}
                            variant="subtitle2"
                            component="p"
                          >
                            Start Date
                          </Typography>
                          <Field name="startDate">
                            {(startDateProps: any) => {
                              const { form } = startDateProps;
                              const { setFieldValue } = form;
                              return (
                                <ReactDatePicker
                                  selected={values.startDate}
                                  minDate={new Date()}
                                  maxDate={null}
                                  placeholderText={`Start Date *`}
                                  datePickerCallBack={(newDate: Date) => {
                                    setFieldValue("startDate", newDate);
                                    /** Resetting the end date */
                                    if (
                                      newDate.getTime() &&
                                      dashboardMedia.endDate &&
                                      newDate.getTime() >
                                        dashboardMedia.endDate.getTime()
                                    ) {
                                      setDashboardMedia((prevState) => ({
                                        ...prevState,
                                        endDate: null,
                                      }));
                                      setFieldValue("endDate", null);
                                    }
                                    setDashboardMedia((prevState) => ({
                                      ...prevState,
                                      startDate: newDate,
                                    }));
                                  }}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="startDate">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </Stack>
                        {
                          <Stack>
                            <Typography
                              fontSize={14}
                              variant="subtitle2"
                              component="p"
                            >
                              End Date
                            </Typography>
                            <Field name="endDate">
                              {(endDateProps: any) => {
                                const { form } = endDateProps;
                                const { setFieldValue } = form;
                                return (
                                  <ReactDatePicker
                                    selected={values.endDate}
                                    minDate={
                                      dashboardMedia.startDate || new Date()
                                    }
                                    maxDate={null}
                                    placeholderText={`Start Date *`}
                                    datePickerCallBack={(newDate: Date) => {
                                      setFieldValue("endDate", newDate);
                                      setDashboardMedia((prevState) => ({
                                        ...prevState,
                                        endDate: newDate,
                                      }));
                                    }}
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage name="endDate">
                              {(
                                errorMsg:
                                  | boolean
                                  | React.ReactChild
                                  | React.ReactFragment
                                  | React.ReactPortal
                                  | null
                                  | undefined
                              ) => (
                                <FormHelperText error={true}>
                                  {errorMsg}
                                </FormHelperText>
                              )}
                            </ErrorMessage>
                          </Stack>
                        }
                      </Stack>
                    </FormControl>
                  }
                  {values.mediaType.toLocaleLowerCase() === "image" && (
                    <>
                      <Field name="image" validate={validateImage}>
                        {(imageProps: any) => {
                          const { form } = imageProps;
                          const { setFieldValue } = form;
                          return (
                            <UploadFile
                                isRequired={true}
                              acceptedFileTypes={[
                                "image/png",
                                "image/jpg",
                                "image/jpeg",
                              ]}
                              onChange={(data: any) => {
                                setFieldValue("image", data);
                                updatedashboardMediaDetails("image", data);
                              }}
                              maxFileSize={5}
                              name="image"
                              canRemoveOrReplace={true}
                              imageUrl={values.image}
                            />
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="image" />
                      </FormHelperText>
                    </>
                  )}
                  {values.mediaType.toLocaleLowerCase() === "video" && (
                    <Stack flex={1}>
                      <FormControl className={classes.formControlMrtHelper}>
                        <Typography fontSize={14} variant="subtitle2">
                          Media URL *
                        </Typography>
                        <Field name="mediaurl" validate={validateMediaUrl}>
                          {(mediaurlProps: any) => {
                            const { form } = mediaurlProps;
                            const { setFieldValue } = form;

                            return (
                              <TextField
                                fullWidth
                                inputProps={{
                                  maxlength: 255,
                                }}
                                onBlur={(event) => {
                                  event.preventDefault();
                                  const url = validationTrimUrl(
                                    event.target.value
                                  );
                                  setFieldValue("mediaurl", url);
                                  updatedashboardMediaDetails("mediaurl", url);
                                }}
                                name="mediaurl"
                                defaultValue={values?.mediaurl}
                                key={values?.mediaurl}
                              />
                            );
                          }}
                        </Field>
                        <ErrorMessage name="mediaurl">
                          {(
                            errorMsg:
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined
                          ) => (
                            <FormHelperText error={true}>
                              {errorMsg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    </Stack>
                  )}

                  <Stack flex={1}>
                    <FormControl className={classes.formControlMrtHelper}>
                      <Typography fontSize={14} variant="subtitle2">
                        Display Order *
                      </Typography>
                      <Field name="displayOrder">
                        {(mediaurlProps: any) => {
                          const { form } = mediaurlProps;
                          const { setFieldValue } = form;
                          return (
                            <TextField
                              fullWidth
                              onKeyDown={(
                                event: React.KeyboardEvent<HTMLInputElement>
                              ) => {
                                const digits = /[0-9]/;

                                // Minus
                                if (
                                  event.key === "-" &&
                                  (event.target as HTMLInputElement).value
                                    .length === 0
                                ) {
                                  return;
                                }

                                if (event.key === "Backspace") {
                                  return;
                                }

                                if (!digits.test(event.key)) {
                                  return event.preventDefault();
                                }
                              }}
                              onBlur={(event) => {
                                setFieldValue(
                                  "displayOrder",
                                  event.target.value
                                );
                                updatedashboardMediaDetails(
                                  "displayOrder",
                                  event.target.value
                                );
                              }}
                              name="displayorder"
                              inputProps={{ maxLength: 50 }}
                              defaultValue={values?.displayOrder}
                              key={values?.displayOrder}
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage name="displayOrder">
                        {(
                          errorMsg:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined
                        ) => (
                          <FormHelperText error={true}>
                            {errorMsg}
                          </FormHelperText>
                        )}
                      </ErrorMessage>
                    </FormControl>
                    <Field name="category" id="category">
                      {(_props: any) => {
                        const { form } = _props;
                        const { setFieldValue } = form;
                        return (
                          <FormControl
                            className={classes.formControlMrtHelper}
                            fullWidth
                          >
                            <RenderField
                              name="category"
                              label="Category *"
                              labelKey="label"
                              value={values.category}
                              options={Category}
                              onChange={(name: string, selectedType: any) => {
                                setFieldValue("category", selectedType.label);
                                updatedashboardMediaDetails(
                                  "category",
                                  selectedType.label
                                );
                              }}
                              key={props.uniqueKey}
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="category" />
                    </FormHelperText>
                  </Stack>
                </div>
              </Box>
              <Divider />
              <div className={classes.formButtonHelper}>
                <Stack direction="row" spacing={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    data-testid="saveButton"
                    disableElevation
                  >
                    {(isEdit && "Save") || "Create"}
                  </Button>
                </Stack>
              </div>
            </Paper>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddEditDashboard;
