import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useDispatch } from "react-redux";
import { loginRequest } from "../../Config/authConfig";
import { ASSETS_PATH } from "../../Constants/contants";
import appActions from "./../../redux/action/index";
import { useStyles } from "./loginStyles";

type LoginProps = {
  onSignInSuccess: Function;
};

const Login: React.FC<LoginProps> = ({ onSignInSuccess }) => {
  // classes
  const classes = useStyles();
  const dispatch = useDispatch();
  // States
  const [userName, setUserName] = React.useState<string>("");
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleResponse = async (response: any) => {
    if (response) {
      if (isAuthenticated) {
        await dispatch(appActions.apiData.userAuthToken(response.idToken));
        await dispatch(
          appActions.userDetails.userDetails({
            name: response.account.name,
          })
        );
      }
      onSignInSuccess(response);
    }
  };

  instance.handleRedirectPromise().then(handleResponse);

  const handleSignIn = (msalInst: any) => {
    if (userName) {
      msalInst.loginRedirect({
        ...loginRequest,
        loginHint: `${userName}@syngenta.com`,
        forceRefresh: true,
      });
    }
  };

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUserName(value);
  };

  return (
    <div className={classes.contentHelper}>
      <div>
        <Box className={classes.loginContainerHelper}>
          <div>
            <img
              style={{ height: "120px" }}
              src={`${ASSETS_PATH}Web_Portal_Logo.svg`}
              alt="Smartgrower admin web portal"
            />
          </div>
        </Box>
      </div>
      <Paper className={classes.paperHelper}>
        <div className={classes.innerContentHelper}>
          <Icon className={classes.iconHelper}>
            <img src={`${ASSETS_PATH}Plant_Icon.svg`} alt="Plant" />
          </Icon>
          <Typography variant="subtitle1">Hello There,</Typography>
          <Typography fontSize={14}>
            Please sign in using your Syngenta credentials
          </Typography>
          <FormControl variant="standard" fullWidth>
            <Typography
              className={classes.emailLabelHelper}
              variant="subtitle2"
            >
              Email
            </Typography>
            <TextField
              InputProps={{
                style: {
                  height: "40px",
                  background: "#F3F4F6",
                  color: "#696F88",
                },
                endAdornment: <>@syngenta.com</>,
              }}
              fullWidth
              value={userName}
              onChange={handleUserNameChange}
            />
          </FormControl>
          <Button
            color="success"
            className={classes.signInButtondHelper}
            variant="contained"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              handleSignIn(instance);
            }}
          >
            <Typography fontSize={12} variant="subtitle2">
              Sign in
            </Typography>
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default Login;
