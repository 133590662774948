import api_endpoints, { base_url } from '../../Api/end_points';
import { api_service } from './../../Api/api_service';
import { IRequestBody } from './../../Constants/interfaces';

/**
 * @description function triggers an api call to fetch list of languages
 * @param data
 * @param successCallBack function
 * @param errorCallBack funciton
 */

export const GetCropsDataList = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'crops_data_list');
  }).catch((err) => {
    errorCallBack(err, 'crops_data_list');
  });
};
export const GetMyUploadList = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'crops_upload_list');
  }).catch((err) => {
    errorCallBack(err, 'crops_upload_list');
  });
};

export const GetCropDetails = (data: IRequestBody) => {
  return new Promise((resolve, reject) => {
    api_service.get(data)?.then((response) => {
      return resolve(response.data);
    }).catch((err) => {
      return reject(err);
    });
  });
}

export const UpdateCropStatus = (systemName: string, flag: boolean) => {
  const apiData = {} as IRequestBody;
  apiData.domain = base_url;
  const endPoint: string = api_endpoints.update_crop_status.replace('__SYSTEM__NAME__', systemName);
  apiData.endPoint = endPoint;
  apiData.payLoad = {
    isActive: flag
  };
  return new Promise((resolve, reject) => {
    api_service.patch(apiData)
      .then((response) => {
        return resolve(response.data)
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

/** save crop data */
export const SaveCropData = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.post(data)?.then((response) => {
    successCallBack(response.data, 'save_crop');
  }).catch((err) => {
    errorCallBack(err, 'save_crop');
  });
};

/** update crop data */
export const UpdateCropData = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.post(data)?.then((response) => {
    successCallBack(response.data, 'update_crop');
  }).catch((err) => {
    errorCallBack(err, 'update_crop');
  });
};

/** get states country wise */
export const GetStates = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'states', '');
  }).catch((err) => {
    errorCallBack(err, 'states');
  });
};

/** remove image list api */
export const RemoveImageList = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.delete(data)?.
    then((res) => successCallBack(res.data, 'remove_img')).
    catch((err) => errorCallBack(err, 'remove_img'))
}

/** validate bulk upload data 
 * or 
 * create a new crop data by bulk upload
 * */
export const ValidateBulkUploadData = (data: IRequestBody, successCallBack: any, errorCallBack: any, file: Blob | any) => {
  api_service.post(data)?.
    then((res) => successCallBack(res.data, 'validate_bulk', file)).
    catch((err) => errorCallBack(err, 'validate_bulk'))
}

/** update existing bulk upload data */
export const UpdateBulkData = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.post(data)?.
    then((res) => successCallBack(res.data, 'update_bulk')).
    catch((err) => errorCallBack(err, 'update_bulk'))
}

/** Get all error list for bulk uploaded data */
export const GetErrorList = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.
    then((res) => successCallBack(res.data, 'error_list')).
    catch((err) => errorCallBack(err, 'error_list'))
}

export const getFiltersForCrop = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.
    then((res) => successCallBack(res.data, 'filter_list')).
    catch((err) => errorCallBack(err, 'filter_list'))
}