import { Box, Divider, Modal, Paper, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { TableComponent } from "../../../Common/BasicDataTable/BasicDataTable";
import { ActionType } from "../../../Common/Constants";
import CustomTabs from "../../../Common/CustomTabs";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { IRequestBody } from "../../../Constants/interfaces";
import { RootState } from "../../../redux/reducers";
import { LanguageRecordType } from "../../../redux/reducers/generic_reducer";
import { GetCropDetails } from "../CropsAPICalls";
import { StagesToCreateACrop } from "../stepperStages";
import { useStyles } from "../style";
import {
  CropCalendar,
  CropGrowthStages,
  CropStageCalendar,
  FilterKeys,
} from "./TableHeaders";

type CropDataTypes = {
  fieldName: string;
  fieldLabel: string;
};

const CropData: Array<CropDataTypes> = [
  {
    fieldName: "imageUrl",
    fieldLabel: "Crop Image",
  },
  {
    fieldName: "cropName",
    fieldLabel: "Crop Title",
  },
  {
    fieldName: "systemName",
    fieldLabel: "System Name",
  },
  {
    fieldName: "states",
    fieldLabel: "Available States",
  },
];

const getLabel = (label: string) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.viweCropLabelMrbHelper}
      variant="subtitle2"
      fontSize={14}
    >
      {label}
    </Typography>
  );
};

interface RenderFieldProps extends CropDataTypes {
  data: any;
}

const RenderField = (props: RenderFieldProps) => {
  const { fieldName, fieldLabel, data } = props;
  const classes = useStyles();
  switch (fieldName) {
    case "states":
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography className={classes.viweCropLabelMrbHelper} fontSize={14}>
            {data &&
              data[fieldName] &&
              data[fieldName].map(
                (state: { id: number; name: string }, index: number) => {
                  return (
                    <>
                      {data[fieldName].length - 1 === index
                        ? state.name
                        : `${state.name}, `}
                    </>
                  );
                }
              )}
          </Typography>
        </>
      );
    case "imageUrl":
      return (
        <>
          <Stack direction="row" spacing={10}>
            {getLabel(fieldLabel)}
            <div className={classes.viewImage}>
              {data && data[fieldName] && <img alt={'Image'} src={data && data[fieldName]} />}
            </div>
          </Stack>
        </>
      );
    default:
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography
            className={classes.viweCropLabelMrbHelper}
            fontSize={14}
            variant="body1"
          >
            {data && data[fieldName] ? data[fieldName] : "N/A"}
          </Typography>
        </>
      );
  }
};
const ViewCrop: React.FC<any> = (props) => {
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
    };
  });
  // states
  const [activeTab, setActiveTab] = useState<number>(0);
  const [cropDetails, setCropDetails] = useState<any>({});
  // variables
  const classes = useStyles();
  const handleClose = () => {
    props.onCloseViewCrop();
  };
  const handleEditCrop = () => {
    props.onCloseViewCrop();
    props.onEditClick(ActionType.UPDATE, {
      id: props.id,
      languageId: cropDetails.GeneralDetails.languageId,
      name: cropDetails.GeneralDetails.systemName,
      languageCode: cropDetails.GeneralDetails.languageCode,
    });
  };
  const getStepStages = () => {
    return StagesToCreateACrop.map((stepObj: any) => {
      return stepObj.stepLabel;
    });
  };

  const changeActiveTab = (_ = "", newValue: number) => {
    setActiveTab(newValue);
  };

  const processCropStageCalendarSystemInfo = (response: any) => {
    let data: any = response.payload;
    let cropCalendarStagesArray: string | any[] = [];
    data.cropCalendars.map((cropCalendarObj: any) => {
      if (
        cropCalendarObj.cropStagesCalendar &&
        cropCalendarObj.cropStagesCalendar.length > 0
      ) {
        [...cropCalendarObj.cropStagesCalendar].forEach(
          (cropCalendarStageObj) => {
            cropCalendarStageObj["calendarName"] =
              cropCalendarObj["calendarName"];
          }
        );
        cropCalendarStagesArray = cropCalendarStagesArray.concat(
          cropCalendarObj.cropStagesCalendar
        );
        cropCalendarStagesArray = _.sortBy(
          cropCalendarStagesArray,
          "displayOrder"
        );
      }
    });
    let selectedLanguage: any = storeData.languages.filter(
      (language: LanguageRecordType) => language.languageId === data.languageId
    );
    const CropInfoObj = {
      GeneralDetails: {
        systemName: data.systemName,
        cropName: data.cropName,
        imageUrl: data.imageUrl,
        languageId: data.languageId,
        states: data.cropStates,
        cropId: data.cropId,
        languageName: selectedLanguage[0].languageName,
        languageCode: response.languageCode,
      },
      cropStages: _.sortBy(data.cropStages, "displayOrder"),
      filterKeys: _.sortBy(data.filterKeys, "displayOrder"),
      cropCalendar: [...data.cropCalendars],
      cropCalendarStages: [...cropCalendarStagesArray],
      statusId: response.statusId,
    };
    setCropDetails({ ...CropInfoObj });
  };

  const renderGeneralDetailsTab = () => {
    // const classes = useStyles();
    return (
      <>
        <Box className={classes.formContainer}>
          <Box className={classes.formFieldsHelper}>
            <Box className={classes.formFieldsHelperOne}>
              <LanguageDropdown
                isDisabled={true}
                selectedLanguage={cropDetails?.GeneralDetails?.languageName}
                key={cropDetails?.GeneralDetails?.languageName}
              />
            </Box>
            <Box className={classes.formFieldsHelperUnique}>
              {CropData.map((notification: CropDataTypes, index: number) => {
                return (
                  <>
                    <RenderField
                      fieldName={notification.fieldName}
                      fieldLabel={notification.fieldLabel}
                      data={cropDetails?.GeneralDetails}
                    />
                    {index < CropData.length - 1 && (
                      <Divider className={classes.viweCropLabelMrbHelper} />
                    )}
                  </>
                );
              })}
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const renderCropGrowthStages = () => {
    return (
      <>
        <TableComponent
          headers={[...CropGrowthStages]}
          data={cropDetails.cropStages || []}
        />
      </>
    );
  };

  const renderFilterKeys = () => {
    return (
      <>
        <TableComponent
          headers={[...FilterKeys]}
          data={cropDetails.filterKeys || []}
        />
      </>
    );
  };

  const renderCropCalender = () => {
    return (
      <>
        {" "}
        <TableComponent
          headers={[...CropCalendar]}
          data={cropDetails.cropCalendar || []}
        />
      </>
    );
  };

  const renderCropStageCalender = () => {
    return (
      <>
        <TableComponent
          headers={[...CropStageCalendar]}
          data={cropDetails.cropCalendarStages || []}
        />
      </>
    );
  };

  const fetchCropDetails = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = "";
    if (props.lngId) {
      /** this api call for published crops view data */
      apiData.endPoint = api_endpoints.crop_details.replace(
        "__SYSTEM__NAME__",
        props.sysNm
      );
      const cropLanguageObject: LanguageRecordType[] =
        storeData.languages.filter((lngRcd: LanguageRecordType) => {
          return lngRcd.languageId === props.lngId;
        });
      if (cropLanguageObject.length > 0) {
        apiData.customHeaders = {
          "language-code": cropLanguageObject[0].languageCode,
        };
      }
    } else {
      /** this api call for un-published/newly-created crops view data */
      apiData.endPoint = `crops/crop-drafts/${props.id}`;
    }

    GetCropDetails(apiData)
      .then((response: any) => {
        if (response.statusCode === 200) {
          processCropStageCalendarSystemInfo(response.data);
          return;
        }
        throw new Error(response.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchCropDetails();
  }, []);
  return (
    <Modal
      open={true}
      disableEscapeKeyDown={true}
      className={classes.modalStylesHelper}
    >
      <Paper className={classes.formPaper}>
        <Paper elevation={3} square>
          <ModalHeader
            header={`Crop: ${cropDetails?.GeneralDetails?.cropName}`}
            onClose={() => handleClose()}
            showEdit={
              !(cropDetails.statusId && cropDetails.statusId === 2)
            }
            editHandler={() => handleEditCrop()}
          />
          <Box>
            <CustomTabs
              onChange={changeActiveTab}
              tabsList={getStepStages()}
              value={activeTab}
            />
          </Box>
        </Paper>
        <Box className={classes.viewCropFormContentHelper}>
          {activeTab === 0 && renderGeneralDetailsTab()}
          {activeTab === 1 && renderCropGrowthStages()}
          {activeTab === 2 && renderFilterKeys()}
          {activeTab === 3 && renderCropCalender()}
          {activeTab === 4 && renderCropStageCalender()}
          {/* <LanguageDropdown selectedLanguage={'IN'} /> */}
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewCrop;
