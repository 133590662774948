import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Modal,
  Paper,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import {ISO_8601} from "moment";
import React, {ChangeEvent, useCallback, useEffect, useRef, useState,} from "react";
import api_endpoints, {base_url} from "../../../Api/end_points";
import ButtonWithIcon, {CANCEL_TYPE} from "../../../Common/Buttons";
import ReactDatePicker from "../../../Common/DateAndTimePickers/DatePicker";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import MultiSelect from "../../../Common/MultiSelect";
import {IRequestBody} from "../../../Constants/interfaces";
import {TriggerToastMessage} from "../../../Utils/toastTrigger";
import {genericValidateImage} from "../../../Utils/fileHelper";
import {
  dayData,
  dayOfMonthData,
  fetchTimezoneOffset,
  frequencyData,
  getCrops,
  getCropsStates,
  getListOfNotificationTypes,
  getNotificationByID,
  getSMSCost,
  getStatesDistricts,
  getUserCount,
  saveNotification,
  StateDistrictMapType,
  updateNotification,
} from "./PushNotificationAPICalls";
import {useStyles} from "./style";
import {ValidationSchema} from "./ValidationSchema";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reducers";
import _ from "lodash";
import {toast, ToastOptions} from "react-toastify";

import moment from "moment";
import RenderField from "../../../Common/RenderField";
const formatDate = "DD/MM/YYYY";
const formatTime = "HH:mm";
const Input = styled("input")({
  display: "none",
});

interface ICreateNotificationProps {
  action: string;
  notificationDetails?: NotificationType;
  onCloseCreatePushNotification: Function;
  onCreatePush: Function;
  showCreateProgress?: boolean;
}


type notificationTypesProps = {
  id: string;
  notificationTypeName: string;
};

type AvailableCropType = {
  checked: boolean;
  label: string;
};


export type NotificationType = {
  dayOfMonth: string;
  dayOfWeek: string;
  endDate: Date | null;
  filteredCrops: Array<any> | null;
  frequency: string;
  languageId: number | undefined;
  message: string | string[];
  notificationTypeName: string;
  selectedDistrictObj: Array<number>;
  distIds?: Array<number> | null;
  sendAsSMS: boolean;
  smsCost: string | undefined;
  title: string | string[];
  hyperlink: string | string[];
  id: number | undefined;
  startDate: Date | any;
  stateId: Array<number> | undefined;
  stateNames: Array<string>;
  time: null;
};

const initialState: NotificationType = {
  notificationTypeName: "",
  languageId: undefined,
  stateNames: [],
  stateId: undefined,
  selectedDistrictObj: [],
  distIds: null,
  startDate: null,
  endDate: null,
  frequency: "",
  message: [],
  sendAsSMS: false,
  dayOfWeek: "",
  dayOfMonth: "",
  filteredCrops: [],
  time: null,
  smsCost: undefined,
  title: [],
  hyperlink: [],
  id: undefined,
};

type StatesType = {
  id: number;
  name: string;
  shortCode: SVGStringList;
};

type DistrictType = {
  displayName: string;
  id: number;
};

type StateTypeResponse = {
  data: Array<StatesType>;
};

type DistrictTypeResponse = {
  data: Array<DistrictType>;
};

type CropIdType = {
  id: number;
};

type NotificationTypes = {
  id: number;
  notificationTypeName: string;
};

type UserCountPayload = {
  cropIds: Array<number>;
  districtIds: Array<number>;
  stateIds: Array<number> | undefined;
  mobileNo: Array<number>;
  languageIds: Array<number>;
};

type SMSCostPayload = {
  characterCount: number;
  userCount: number | undefined;
};

type ImageDataType = {
  file: any;
  imageName: string | undefined;
  imagePreview: string | undefined;
};

type LangObjectType = {
  languageId: number | undefined;
  languageName: string;
  languageCode: string;
};

const initialValues = {
  mobile: "",
  language: [],
  notificationTypeID: "",
  image: "",
  filteredCrops: null,
  stateNames: null,
  districts: null,
  frequency: "",
  startDate: null,
  endDate: null,
  dayOfWeek: "",
  time: "",
  title: "",
  message: "",
  hyperLink: "",
  sendAsSMS: false,
  smsCost: "",
};
const editData: any = {
  language: [],
  notificationTypeID: "",
  image: "",
  filteredCrops: null,
  stateNames: null,
  districts: null,
  frequency: "",
  startDate: null,
  endDate: null,
  dayOfWeek: "",
  time: "",
  title: "",
  message: "",
  hyperLink: "",
  sendAsSMS: false,
  smsCost: "",
};
const AddEditNotification: React.FC<ICreateNotificationProps> = (props) => {
  const storeData = useSelector((state: RootState) => {
    return {
      primaryLangCode: state.auth.languageCode,
      all: state,
      primaryLang: state.generic.ListOfLanguages.languageList.find(
        (lang) => lang.languageCode === state.auth.languageCode
      ),
    };
  });
  // variables
  const isEdit = props.action === "Edit_Push";
  const { notificationDetails } = props;
  const classes = useStyles();
  // states
  const [showCreatePushNotification, setShowCreatePushNotification] =
    useState<boolean>(true);

  const [selectedLangObj, setSelectedLangObj] = useState<LangObjectType[]>([]);
  const notificatioDetails =
    (isEdit && props.notificationDetails) || initialState;
  const [notification, setNotification] =
    useState<NotificationType>(notificatioDetails);
  const [selectedNotification, setSelectedNotification] = useState<any>("");
  const [crops, setCrops] = useState<Array<AvailableCropType>>([]);
  const [stateDistricts, setStateDistricts] = useState<
    Array<StateDistrictMapType>
  >([]);
  const [listOfNotificationTypes, setListOfNotificationTypes] = useState<
    Array<NotificationTypes>
  >([]);
  const [cropsStateList, setCropsStateList] = useState<Array<any>>([]);
  // const [, setSelectedCropsIds] = useState<Array<number>>();
  const [getByIDCount, setGetByIDCount] = useState<number>(1);
  const [timeZoneOffset, setTimezoneOffset] = useState<string>("+0:00");
  // variables
  let selectedCrops: Array<string> = crops
    .filter(
      (crop: AvailableCropType) => crop.label !== "Select all" && crop.checked
    )
    .map((crop: AvailableCropType) => crop.label);
  const header: string =
    (isEdit && "Edit Notification") || "Create New Push Notification";
  /**state variable to store total sms users count*/
  const [userCount, setUserCount] = useState<number>();
  // exctract props
  const { onCloseCreatePushNotification } = props;
  const formRef = useRef<FormikProps<any>>(null);

  const handleClose = (isCreatSuccess?: boolean, isEdit?: boolean) => {
    setShowCreatePushNotification(false);
    onCloseCreatePushNotification(isCreatSuccess, isEdit);
  };

  const validationTrimUrl = useCallback((url: string) => {
    const trimmedUrl = url.trim();
    return trimmedUrl.replace(/\s/g, "");
  }, []);

  // const handleLanguageChange = (language: string) => {
  //   let langArrData = language.split("##");
  //   let langData: LangObjectType = {
  //     languageId: parseInt(langArrData[0]),
  //     languageName: langArrData[1],
  //     languageCode: langArrData[2],
  //   };
  //   updateNotificationDetails("languageId", `${langData.languageId}`);
  //   // setSelectedLangObj(langData);
  // };

  const updateNotificationDetails = (
    name: string,
    value: string | boolean | Date
  ) => {
    setNotification((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeSendAsSMS = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    updateNotificationDetails(name, checked);
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    updateNotificationDetails(name, value);
  };

  const handleTypeChange = (
    name: string,
    selectedType: notificationTypesProps
  ) => {
    updateNotificationDetails(name, selectedType.notificationTypeName);
    setSelectedNotification(selectedType);
  };
  /** All crops drop-down related code */
  const handleCropsSelection = (checked: boolean) => {
    let allCropList: any = crops;
    allCropList.map((crop: any) => {
      crop.checked = checked;
    });
    // setCropsStateList(allCropList);
  };

  const handleCropsCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;

    if (value === "Select all") {
      handleCropsSelection(checked);
    } else {
      if (crops[0].checked && !checked) {
        crops[0].checked = false;
      }
      const index: number = crops.findIndex(
        (crop: AvailableCropType) => crop.label === value
      );
      crops[index].checked = checked;
      setCrops([...crops]);
    }
  };

  const handleSatesSelection = (checked: boolean) => {
    let allStateList: any = cropsStateList;
    allStateList.map((state: any) => {
      state.checked = checked;
    });
    setCropsStateList(allStateList);
  };

  const handleStatesCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;
    if (value === "Select all") {
      handleSatesSelection(checked);
    } else {
      if (cropsStateList[0].checked && !checked) {
        cropsStateList[0].checked = false;
      }
      const index: number = cropsStateList.findIndex(
        (state: any) => state.stateName === value
      );
      cropsStateList[index].checked = checked;

      setCropsStateList([...cropsStateList]);
    }
  };

  const handleStateClearSelection = () => {
    handleSatesSelection(false);
  };

  let selectedDistIds: Array<any> | null = [];

  const handleDistrictChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (value !== "Select all") {
      if (stateDistricts[0].checked && !checked) {
        stateDistricts[0].checked = false;
      }
      const index: number = stateDistricts.findIndex(
        (district: any) => district.districtName === value
      );
      stateDistricts[index].checked = checked;
    } else if (value === "Select all") {
      stateDistricts.forEach((district: any) => {
        district.checked = checked;
      });
    }
    setStateDistricts([...stateDistricts]);

    let filteredDistricts: Array<any> = [];
    let ids: Array<number> = [];
    stateDistricts.forEach((district: any) => {
      if (district.districtName !== "Select all" && district.checked) {
        filteredDistricts.push(district);
        ids.push(district.districtId);
      }
    });
    setNotification((prevState) => ({
      ...prevState,
      selectedDistrictObj: filteredDistricts,
    }));
    selectedDistIds = ids?.length ? ids : null;
  };

  const handleClearDistrictSelection = (checked: boolean = false) => {
    setStateDistricts(
      stateDistricts.map((district: any) => {
        return { ...district, checked };
      })
    );
    setNotification((prevState) => ({
      ...prevState,
      selectedDistrictObj: [],
    }));
  };

  // for drop-down api calls
  const successCallBack = (response: any, type: string) => {
    switch (type) {
      case "languages": {
        break;
      }
      case "crops": {
        let cropsData = response.data.cropsList;
        let allCropsList: AvailableCropType = {
          label: "Select all",
          checked: false,
        };
        let withoutEnrolled = {
          label: "Growers Without Crops",
          checked: false,
          id: 0,
        };
        let selectedCropsIDs: Array<number> = [];
        notification.filteredCrops?.forEach((eachCrop: any) => {
          selectedCropsIDs.push(eachCrop.id);
        });

        cropsData.map((eachCrop: any) => {
          eachCrop.label = eachCrop.nameTranslation;
          eachCrop.id = eachCrop.id;
          /** if item already selected, show as selected */
          selectedCropsIDs?.indexOf(eachCrop.id) !== -1
            ? (eachCrop.checked = true)
            : (eachCrop.checked = false);
        });
        cropsData.unshift(allCropsList, withoutEnrolled);
        setCrops(cropsData);
        break;
      }
      case "crops_states": {
        let allStates = _.sortBy(response.data, "name");
        let allCropsList: any = {
          stateName: "Select all",
          checked: false,
        };
        if (allStates && allStates.length) {
          allStates.map((state: any) => {
            /** if item already selected, show as selected */
            // notification.stateId?.indexOf(state.uniqueStateId) !== -1
            //   ? (state.checked = true)
            //   : (state.checked = false);
            state.checked = false;
          });
        }
        allStates.unshift(allCropsList);
        setCropsStateList(allStates);
        break;
      }
      case "state_districts": {
        let selectedDistrictsIDs: Array<number> = [];
        notification.selectedDistrictObj.forEach((eachDistrict: any) => {
          selectedDistrictsIDs.push(eachDistrict.districtId);
        });
        let allCropsList: any = {
          districtName: "Select all",
          checked: false,
        };
        let allDistricts = response.data;
        if (allDistricts && allDistricts.length) {
          allDistricts.map((district: any) => {
            /** if item already selected, show as selected */
            selectedDistrictsIDs?.indexOf(district.districtId) !== -1
              ? (district.checked = true)
              : (district.checked = false);
          });
        }
        allDistricts.unshift(allCropsList);
        setStateDistricts(allDistricts);
        break;
      }
      case "notification_types":
        setListOfNotificationTypes([...response.data]);
        break;
      case "save_update":
        /** closing the popup after getting success code*/
        if (response.statusCode === 200) {
          handleClose(true, isEdit);
        } else {
          /**Uncomment it before release */
          handleClose(false, isEdit);
        }
        break;
      case "sms_cost": {
        let cost = response.data.smsCost;
        setNotification((prevState) => ({
          ...prevState,
          smsCost: cost,
        }));
        break;
      }
      case "user_count": {
        /** totalPages in response will be treated as total user counts*/
        let latestCount = response.data.totalPages; //Math.floor(Math.random() * 10)
        setUserCount(latestCount);
        break;
      }
      case "data_by_id": {
        if (response.statusCode === 200) {
          let data = response.data;
          /**for from date */
          let newFromDate: Date = new Date();
          let toDateArrObj = data?.fromDate.split("/");
          newFromDate.setFullYear(toDateArrObj[2]);
          newFromDate.setMonth(toDateArrObj[1] - 1);
          newFromDate.setDate(toDateArrObj[0]);

          /**for to date */
          let newToDate: Date = new Date();
          if (data.frequency !== "Once") {
            let toDateArrObj = data?.toDate.split("/");
            newToDate.setFullYear(toDateArrObj[2]);
            newToDate.setMonth(toDateArrObj[1] - 1);
            newToDate.setDate(toDateArrObj[0]);
          }
          let timeArrObj =
            data &&
            moment(data.notificationTime, "HH:mm:ss")
              .add(timeZoneOffset, "minutes")
              .toDate();
          /** crops, states and districts */
          editData.mobile = data.mobileNo;
          /**CROPS */
          /**For Select all checked crops status */
          selectedCrops = data.cropNames.split(",");
          if (crops.length - 1 === data.cropIds?.length)
            crops[0].checked = true;
          let filteredCrops: any = [];
          crops.forEach((eachCrop: any) => {
            if (data.cropIds.indexOf(eachCrop.id) !== -1) {
              eachCrop.checked = true;
              filteredCrops.push(eachCrop);
            } else {
              eachCrop.checked = false;
            }
          });

          editData.filteredCrops = filteredCrops;
          /**STATES */
          /**For Select all checked state status */
          if (cropsStateList.length - 1 === data.stateIds?.length)
            cropsStateList[0].checked = true;
          cropsStateList.forEach((eachState: any) => {
            eachState.checked = data.stateIds.indexOf(eachState.uniqueStateId) !== -1;
          });
          editData.stateNames = data.stateNames.split(",");
          /** DISTRICTS */
          editData.districts = data.districtIds;
          if (!data.mobileNo) {
            setSelectedLangObj([
              {
                languageId: data.languageId,
                languageName: data.languageName,
                languageCode: data.languageCode,
              },
            ]);
          }
          /**Normal edit */
          const langValue = `${data.languageId}##${data.languageName}##${data.languageCode}`;
          editData.language = [langValue];
          editData.notificationTypeID = data.notificationType.id;
          editData.sendAsSMS = data.sendSms;

          editData.title = data.notificationTitle;
          editData.message = data.message;
          editData.hyperLink = data.hyperlink ?? "";

          editData["title-0"] = data.notificationTitle;
          editData["message-0"] = data.message;
          editData["hyperlink-0"] = data.hyperlink ?? "";

          editData.titles = [`${langValue}-${data.notificationTitle}`];
          editData.messages = [`${langValue}-${data.message}`];
          editData.hyperlinks = data.hyperlink
            ? [`${langValue}-${data.hyperlink}`]
            : [];

          editData.smsCost = data.smsCost;
          editData.frequency = data.frequency;
          editData.startDate = newFromDate;
          editData.endDate = data.frequency !== "Once" ? newToDate : null;
          editData.dayOfWeek = data.dayOfWeek;
          editData.dayOfMonth =
            data.dayOfMonth || data.dayOfMonth === 0
              ? "last_day_of_month"
              : data.dayOfMonth;
          editData.time = timeArrObj;
          /**Language related */
          // let langData: LangObjectType = {
          //   languageId: data.languageId,
          //   languageName: data.languageName,
          //   languageCode: data?.languageCode,
          // };
          // setSelectedLangObj(langData);
          setSelectedNotification(data.notificationType.notificationTypeName);
          editData.image = data.imageUrl;
          /**Updating local and state variables as per api response*/
          setNotification((prevState) => ({
            ...prevState,
            filteredCrops: filteredCrops,
            languageId: data.languageId,
            notificationTypeName: data.notificationType.notificationTypeName,
            sendAsSMS: data.sendSms,
            title: data.notificationTitle,
            message: data.message,
            hyperlink: data.hyperlink ?? "",
            smsCost: data.smsCost,
            frequency: data.frequency,
            startDate: newFromDate,
            endDate: data.frequency !== "Once" ? newToDate : null,
            dayOfWeek: data.dayOfWeek,
            dayOfMonth:
              data.dayOfMonth || data.dayOfMonth === 0
                ? "Last day of month"
                : data.dayOfMonth,
            time: timeArrObj,
            stateId: data.stateIds,
            stateNames: data.stateNames.split(","),
            distIds: data.districtIds,
          }));
        }
        break;
      }
      case "timezone_offset":
        setTimezoneOffset(response.data);
        break;
      default: {
      }
    }
  };

  const errorCallback = (_error: any, type: string) => {
    switch (type) {
      case "save_update": {
        /** Closing the popup, passing false to display failed creation */
        handleClose(false);
        break;
      }
      case "state_districts":
        {
          TriggerToastMessage(
            "Unable to process your request!!!" +
              "There are no districts mapped with given State",
            "error"
          );
        }
        break;
      default: {
        /**Generic alert to display API fail */
        TriggerToastMessage(
          "Unable to process your request!!!" + type,
          "error"
        );
      }
    }
  };

  /**
   * @description this fetch method is for list of crops
   * @returns success or error call back response object
   */
  const fetchCrops = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.crops;
    apiData.showLoader = true;
    apiData.customHeaders = {
      "language-code": storeData.primaryLangCode,
    };
    apiData.payLoad = {
      status: true,
      pageNo: 0,
      pageSize: 100,
    };
    return getCrops(apiData, successCallBack, errorCallback);
  };
  /**
   * @description this fetch method is to get states based on crop selection
   * @param selectedCropsIds
   * @returns success or error call back response object
   */
  const fetchCropStates = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.country_wise_states;
    apiData.showLoader = true;
    // apiData.payLoad = { ids: selectedCropsIds };
    apiData.customHeaders = {
      "language-code": storeData.primaryLangCode,
    };
    return getCropsStates(apiData, successCallBack, errorCallback);
  };
  /**
   * @description this fetch method is to get district list based on state selection
   * @param selectedStateIds
   * @returns success or error call back response object
   */
  const fetchStateDistricts = (selectedStateIds: Array<number | undefined>) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.state_districts;
    apiData.showLoader = true;
    apiData.payLoad = { ids: selectedStateIds };
    apiData.customHeaders = {
      "language-code": storeData.primaryLangCode,
    };
    return getStatesDistricts(apiData, successCallBack, errorCallback);
  };

  /**
   * @description fetch method to call list of notifications
   * @returns success or error call back response object
   */
  const fetchNotificationList = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.notification_types;
    apiData.showLoader = true;
    return getListOfNotificationTypes(apiData, successCallBack, errorCallback);
  };
  /**
   *
   * @returns success or error call back response object
   */
  const calculateUserCount = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.userCountController;
    /** adding additional params */
    endPoint += `?pageNo=0&pageSize=10`;
    apiData.endPoint = endPoint;
    apiData.showLoader = true;
    let selectedCropsIDs: Array<number> = [];
    notification.filteredCrops?.forEach((eachCrop: any) => {
      selectedCropsIDs.push(eachCrop.id);
    });
    let selectedDistrictsIDs: Array<number> = [];
    notification.selectedDistrictObj.forEach((eachDistrict: any) => {
      selectedDistrictsIDs.push(eachDistrict.districtId);
    });
    let mobileNo: Array<number> = formRef.current?.values.mobile.split(",");
    let languageIds: Array<number> = selectedLangObj.map(
      (item: LangObjectType) => item.languageId
    ) as Array<number>;

    apiData.payLoad = {
      cropIds: selectedCropsIDs,
      stateIds: notification.stateId,
      districtIds: selectedDistrictsIDs,
      mobileNo: mobileNo,
      languageIds: languageIds,
    };
    return getUserCount(apiData, successCallBack, errorCallback);
  };

  // let userCountToken: number | undefined = undefined;
  /**
   * @description this will return the cost for SMS as per
   * userCount and message length
   * @returns success or error call back response object
   */
  const calculateSMSCost = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.sms_cost;
    apiData.showLoader = true;
    apiData.payLoad = {
      userCount: userCount,
      characterCount: notification.message?.length,
    };
    return getSMSCost(apiData, successCallBack, errorCallback);
  };
  /** Get push notification API call */
  const getNotificationById = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = `${api_endpoints.create_edit_notification}/${notificationDetails?.id}`;
    apiData.showLoader = true;
    return getNotificationByID(apiData, successCallBack, errorCallback);
  };
  /**
   * @description performing saving and updation of Notification
   * @returns success or error call back response object
   */
  const saveUpdateNotification = (formikData: any): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.create_edit_notification;
    apiData.showLoader = true;

    if (formikData.mobile) {
      apiData.payLoad = getNotificationPayload(formikData);
      if (!isEdit) {
        saveNotification(apiData, successCallBack, errorCallback);
      } else {
        updateNotification(apiData, successCallBack, errorCallback);
      }
    } else {
      for (const lang of formikData.language) {
        apiData.payLoad = getNotificationPayload(formikData, lang);

        if (!isEdit) {
          saveNotification(apiData, successCallBack, errorCallback);
        } else {
          updateNotification(apiData, successCallBack, errorCallback);
        }
      }
    }
  };
  const getNotificationPayload = (formikData: any, langValue?: string) => {
    let data: any = {};

    /** language related payload */
    if (langValue) {
      const language = langValue.split("##");
      data.languageId = language[0];
      data.languageName = language[1];
      data.languageCode = language[2];
    } else {
      data.languageId = storeData.primaryLang?.languageId;
      data.languageName = storeData.primaryLang?.languageName;
      data.languageCode = storeData.primaryLang?.languageCode;
    }
    data.notificationTypeId = formikData.notificationTypeID;
    /**sms cost and sms checkbox related payload */
    data.sendSms = formikData.sendAsSMS;
    if (formikData.sendAsSMS) {
      data.smsCost = parseFloat(`${notification.smsCost}`);
    }
    /** Crops payload */
    let selectedCropsIDs: Array<number> = [];
    let selectedCropNms: string = "";
    formikData.filteredCrops.forEach((eachCrop: any, index: number) => {
      selectedCropsIDs.push(eachCrop.id);
      /** names in comma seperated string format */
      index !== formikData.filteredCrops.length - 1
        ? (selectedCropNms += `${eachCrop.nameTranslation},`)
        : (selectedCropNms += `${eachCrop.nameTranslation}`);
    });
    if (formikData.mobile) {
      data.cropIds = [];
      data.cropNames = "NoCrop";
    } else {
      data.cropIds = selectedCropsIDs;
      data.cropNames = selectedCropNms;
    }
    /** States payload */
    let selectedStates = notification.stateId;
    let selectedStateNms: string = "";
    notification.stateNames.forEach((stateNm: any, index: number) => {
      /** names in comma seperated string format */
      index !== notification.stateNames.length - 1
        ? (selectedStateNms += `${stateNm},`)
        : (selectedStateNms += `${stateNm}`);
    });
    if (formikData.mobile) {
      data.stateIds = [];
      data.stateNames = "NoState";
    } else {
      data.stateIds = selectedStates;
      data.stateNames = selectedStateNms;
    }
    /** Districts payload */
    let selectedDistrictsIDs: Array<number> = formikData.districts;
    let selectedDistrictNms: string = "";
    let filteredDist: any = stateDistricts.filter(
      (dist: any) => formikData.districts.indexOf(dist.districtId) !== -1
    );
    filteredDist.forEach((element: any, index: number) => {
      index !== formikData.districts.length - 1
        ? (selectedDistrictNms += `${element.districtName},`)
        : (selectedDistrictNms += `${element.districtName}`);
    });
    if (formikData.mobile) {
      data.districtIds = [];
      data.districtNames = "NoDistrict";
    } else {
      data.districtIds = selectedDistrictsIDs;
      data.districtNames = selectedDistrictNms;
    }

    data.frequency = formikData.frequency;

    /**
     * When notification frequency is selected as once,
     * append selected date, time and
     * convert to UTC
     * developer: sivakiran, 14JUL2022
     */
    if (formikData.frequency === frequencyData[0].value) {
      const startDate = moment(formikData.startDate).format(formatDate); // return start date in DD/MM/YYYY format
      const selectedTime = moment(formikData.time).format(formatTime); // return time in HH:mm:ss
      const startDateTime = startDate + selectedTime; // append startdate with selected time
      data.fromDate = moment(startDateTime, "DD/MM/YYYY HH:mm:ss")
        .utc()
        .format(formatDate); // convert final date & time to UTC
    } else {
      data.fromDate = moment(formikData.startDate).format(formatDate);
    }

    /** Start and End date payload in UTC format */
    if (formikData.frequency !== frequencyData[0].value) {
      data.toDate = moment(formikData.endDate).format(formatDate);
    }
    // change: Converting time to UTC
    // developer: sivakiran, 14JUL2022
    data.notificationTime = moment(formikData.time, ISO_8601)
      .utc()
      .format(formatTime);
    data.notificationTime = data.notificationTime + ":00";
    if (formikData.frequency === frequencyData[2].value) {
      data.dayOfWeek = formikData.dayOfWeek;
    }
    // /** calculating last day of the month */
    if (formikData.frequency === frequencyData[3].value) {
      /** For last day of the month send  dayOfMonth as zero, as per backend agreement*/
      data.dayOfMonth =
        formikData.dayOfMonth ===
        dayOfMonthData[dayOfMonthData.length - 1].value
          ? 0
          : parseInt(formikData.dayOfMonth);
    }

    const filterByLang = (data: string[], langVal: string) => {
      return data.find((item) => item.includes(langVal))?.split(/-(.*)/s)[1];
    };

    if (langValue) {
      data.notificationTitle = filterByLang(formikData.titles, langValue);
      data.message = filterByLang(formikData.messages, langValue);
      data.hyperlink = formikData.hyperlinks
        ? filterByLang(formikData.hyperlinks, langValue)
        : undefined;
    } else {
      data.notificationTitle = formikData.title;
      data.message = formikData.message;
      data.hyperlink = formikData.hyperLink;
    }

    if (isEdit) {
      data.id = notificatioDetails.id;
    }

    data.mobileNo = formikData.mobile ?? null;

    /** Changing payload in formData way */
    let formData: any = new FormData();
    /**adding img file to form data */
    if (formikData.image && formikData.image instanceof File) {
      const filename = formikData.image.name.split(".");

      const newFile = new File(
        [formikData.image],
        `NOTIFICATION_${new Date().getTime()}.${filename[filename.length - 1]}`,
        { type: formikData.image.type }
      );
      formData.append("file", newFile);
    } else if (formikData.image) {
      data.imageUrl = formikData.image;
    } else {
      data.imageUrl = null;
    }
    formData.append("data", JSON.stringify(data));
    // console.log('data:', data)
    // console.log('data string:', JSON.stringify(data))
    return formData;
  };
  /** Filed level validation for Image file upload */
  const validateImage = (value: any) => {
    let errorResult = genericValidateImage(value);
    if (errorResult === "Image required") {
      // Image field is optional
      return "";
    } else {
      return errorResult;
    }
  };
  /** Clear selected image */
  const clearImageObject = () => {
    /**remove img code to upload the same img again */
    let parent: any = document.getElementById("upload-img");
    let children = parent.children[0];
    children.value = "";
  };
  /**
   * this useEffect() will handle all initial api calls
   */
  useEffect(() => {
    fetchCrops();
    fetchCropStates();
    fetchNotificationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**fetch crops after language selection only */
  // useEffect(() => {
  //   if (selectedLangObj.length === 1) {
  //     fetchCrops();
  //   }
  // }, [selectedLangObj]);
  /**
   * Calling crop-state-map api after crop selection
   */
  useEffect(() => {
    if (notification.filteredCrops && notification.filteredCrops.length) {
      let selectedCropsIds: Array<CropIdType> = [];
      notification.filteredCrops.forEach((item: any) => {
        selectedCropsIds.push(item.id);
      });
      // fetchCropStates(selectedCropsIds);
    } else {
      // setCropsStateList([]);
      // setStateDistricts([]);
      // setNotification((prevState) => ({
      //   ...prevState,
      // stateNames: [],
      // stateId: [],
      // selectedDistrictObj: [],
      // }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.filteredCrops]);

  /**
   * Calling state-district-map api (geo-hierarchy) after crop selection
   */
  useEffect(() => {
    if (notification.stateId && notification.stateId.length) {
      // let selectedStateId: Array<number | undefined> = [notification.stateId];
      fetchStateDistricts(notification.stateId);
    } else {
      setStateDistricts([]);
      setNotification((prevState) => ({
        ...prevState,
        selectedDistrictObj: [],
        stateNames: [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.stateId]);

  /**when any change in message and sms checkbox happens */
  useEffect(() => {
    if (
      notification.sendAsSMS &&
      notification.message?.length &&
      userCount !== undefined
    ) {
      calculateSMSCost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.message, notification.sendAsSMS]);
  /**On every district selection calling the user count token api */
  useEffect(() => {
    if (notification.sendAsSMS && notification?.selectedDistrictObj.length) {
      calculateUserCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.selectedDistrictObj, notification.sendAsSMS]);
  /**For every new userCount, calculate sms cost */
  useEffect(() => {
    if (notification.sendAsSMS && notification.message?.length) {
      calculateSMSCost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCount]);

  useEffect(() => {
    if (isEdit) {
      fetchTimezoneOffset(successCallBack, errorCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /** API to get all the Push notification details */
  useEffect(() => {
    if (isEdit && getByIDCount <= 2) {
      setGetByIDCount(getByIDCount + 1);
      getNotificationById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crops]);
  /**
   * using this use effect to initialise the district drop-down values in edit,
   * and stopping its continution execution by using getByIDCount state variable
   */
  useEffect(() => {
    if (
      notification.distIds &&
      notification.distIds.length &&
      stateDistricts &&
      stateDistricts.length &&
      getByIDCount <= 3
    ) {
      if (notification.distIds.length === stateDistricts.length - 1)
        stateDistricts[0].checked = true;
      let filteredDistricts: Array<any> = [];
      stateDistricts.map((district: any) => {
        /** if item already selected, show as selected */
        if (notification.distIds?.indexOf(district.districtId) !== -1) {
          district.checked = true;
          filteredDistricts.push(district);
        } else {
          district.checked = false;
        }
      });
      setNotification((prevState) => ({
        ...prevState,
        selectedDistrictObj: filteredDistricts,
      }));
      /**increment this state value so that this useEffect should not repeat */
      setGetByIDCount(getByIDCount + 1);
    }
  }, [notification.distIds, stateDistricts]);
  return (
    <Formik
      innerRef={formRef}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={(isEdit && editData) || initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values: any, _onSubmitProps: any) => {
        /** Calling save notification method */

        saveUpdateNotification(values);
        /**
         * resetting and closing form after isSubmmiting(pre-defined)
         *  is false after api call success
         */
        // onSubmitProps.resetForm();
        // handleClose(true);
      }}
    >
      {({ values, errors }) => (
        <Modal open={showCreatePushNotification}>
          <Form className={classes.formOutlineHelper}>
            <Paper className={classes.formPaper}>
              <ModalHeader
                header={header}
                onClose={() => {
                  if (confirm("You may lose your data.")) {
                    handleClose();
                  }
                }}
              />

              <Box className={classes.formContainer}>
                <div className={classes.formFieldsHelper}>
                  <Stack flex={1}>
                    <FormControl className={classes.formControlMrtHelper}>
                      <Typography fontSize={14} variant="subtitle2">
                        Mobile Number with Country Code
                      </Typography>
                      <Field name="mobile">
                        {(titleProps: any) => {
                          const { form } = titleProps;
                          const { setFieldValue } = form;
                          return (
                            <TextField
                              fullWidth
                              name="mobile"
                              onChange={(event) => {
                                let input = event.target.value.replace(
                                  /[^0-9,]/g,
                                  ""
                                );
                                setFieldValue("mobile", input);

                                // Reset Fields
                                setSelectedLangObj([]);
                                setFieldValue("language", []);
                                setFieldValue("filteredCrops", ["NoCrop"]);
                                setFieldValue("stateNames", ["NoState"]);
                                setFieldValue("districts", ["NoDistrict"]);

                                // setNotification((prevState) => ({
                                //   ...prevState,
                                //   stateNames: [],
                                // }));

                                // handleCropsSelection(false);
                                // handleStateClearSelection();
                                // handleClearDistrictSelection(false);
                              }}
                              value={form.values.mobile}
                              inputProps={{ maxLength: 300 }}
                              placeholder="accept multiple values, separated by coma"
                              disabled={isEdit}
                              style={
                                isEdit ? { backgroundColor: "#F3F4F6" } : {}
                              }
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage name="mobile">
                        {(
                          errorMsg:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined
                        ) => (
                          <FormHelperText error={true}>
                            {errorMsg}
                          </FormHelperText>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  </Stack>
                </div>

                <Box className={classes.formFieldsHelperOne}>
                  <Field
                    name="language"
                    validateOnChange={true}
                    validate={(data: any) => {
                      if (data.length > 0 || values.mobile?.length > 0) {
                        return null;
                      }

                      return "Language is required";
                    }}
                  >
                    {(_props: any) => {
                      const { form} =
                        _props;
                      const { setFieldValue } = form;
                      return (
                        <LanguageDropdown
                          isDisabled={
                            form.values.mobile?.length !== 0 || isEdit
                          }
                          selectedLanguage={
                            isEdit && form.values.mobile?.length !== 0
                              ? []
                              : form.values?.language
                          }
                          // selectedLanguage={selectedLangObj?.languageName}
                          displayPrimaryLanguage={"ALL"}
                          onChangeLanguage={(langData: string[]) => {
                            setFieldValue("language", langData);
                            /** language selection will reset crops, states and districts */
                            setFieldValue("filteredCrops", null);
                            setFieldValue("stateNames", null);
                            setFieldValue("districts", null);

                            setSelectedLangObj(
                              langData.map((data) => {
                                const splitted = data.split("##");

                                return {
                                  languageId: Number(splitted[0]),
                                  languageName: splitted[1],
                                  languageCode: splitted[2],
                                };
                              })
                            );
                          }}
                          multiple={true}
                          // key={selectedLangObj?.languageName}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="language">
                    {(
                      errorMsg:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                    ) => (
                      <FormHelperText
                        error={true}
                        className={classes.formErrorMsgLanguageHelper}
                      >
                        {errorMsg}
                      </FormHelperText>
                    )}
                  </ErrorMessage>
                </Box>
                <div className={classes.formFieldsHelper}>
                  <Field name="sendAsSMS">
                    {(smsProps: any) => {
                      const { form } = smsProps;
                      const { setFieldValue } = form;
                      return (
                        <div className={classes.formSendSMSHelper}>
                          <Typography variant="subtitle2">
                            Send push notification as SMS?
                          </Typography>
                          <Checkbox
                            checked={notification.sendAsSMS}
                            size="small"
                            name="sendAsSMS"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChangeSendAsSMS(event);
                              setFieldValue("sendAsSMS", event.target.checked);
                            }}
                            key={`${notification.sendAsSMS}`}
                          />
                        </div>
                      );
                    }}
                  </Field>
                  <Field name="notificationTypeID">
                    {(notificationTypeProps: any) => {
                      const { form } = notificationTypeProps;
                      const { setFieldValue } = form;
                      return (
                        <RenderField
                          name="id"
                          label="Notification Type *"
                          labelKey="notificationTypeName"
                          key={selectedNotification}
                          value={selectedNotification ?? ''}
                          // selectedOption={
                          //   (notification.notificationTypeName &&
                          //     listOfNotificationTypes.find(
                          //       (noti) =>
                          //         noti.notificationTypeName ===
                          //         notification.notificationTypeName
                          //     )?.notificationTypeName) ||
                          // selectedNotification
                          // }
                          options={listOfNotificationTypes ?? []}
                          onChange={(
                            name: string,
                            selectedType: notificationTypesProps
                          ) => {
                            handleTypeChange(
                              "notificationTypeID",
                              selectedType
                            );
                            setFieldValue(
                              "notificationTypeID",
                              selectedType.id
                            );
                          }}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="notificationTypeID">
                    {(
                      errorMsg:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                    ) => (
                      <FormHelperText error={true}>{errorMsg}</FormHelperText>
                    )}
                  </ErrorMessage>
                  <Field name="image" validate={validateImage}>
                    {(imageProps: any) => {
                      const { form } = imageProps;
                      const { setFieldValue } = form;
                      return (
                        // UploadButtons('Notification Image')
                        <Stack>
                          <Stack
                            direction="row"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            spacing={1}
                            marginTop={3}
                          >
                            <Typography
                              variant="subtitle2"
                              fontSize={14}
                              component="span"
                              width="80px"
                            >
                              Notification Image
                            </Typography>
                            <Stack>
                              <label
                                htmlFor="contained-button-file"
                                id="upload-img"
                              >
                                <Input
                                  accept=".jpg, .png, .jpeg"
                                  id="contained-button-file"
                                  // multiple
                                  type="file"
                                  name="image"
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                  ) => {
                                    if (event.target.files?.[0]) {
                                      const options: ToastOptions = {
                                        position: toast.POSITION.TOP_CENTER,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: false,
                                        draggable: false,
                                        progress: undefined,
                                        autoClose: 2000,
                                      };

                                      const file = event.target.files[0];

                                      const checkFileType = (file: File) => {
                                        const fileSizeInMB = parseFloat(
                                          (
                                            file.size / Math.pow(1024, 2)
                                          ).toFixed(2)
                                        );

                                        if (fileSizeInMB > 5) {
                                          toast.info(
                                            `Maximum file is 5MB, current File size is ${fileSizeInMB}MB`,
                                            options
                                          );

                                          return false;
                                        }

                                        const imageMimeTypes = [
                                          "image/jpeg",
                                          "image/jpg",
                                          "image/png",
                                        ];

                                        if (
                                          !imageMimeTypes.includes(file.type)
                                        ) {
                                          toast.info(
                                            "Invalid file type, please upload a valid image file",
                                            options
                                          );

                                          return false;
                                        }

                                        return true;
                                      };

                                      if (checkFileType(file)) {
                                        setFieldValue(
                                          "image",
                                          event.target?.files?.[0]
                                        );
                                      }
                                    }
                                  }}
                                />
                                {/* Displaying image either by Raw data or by using URL */}
                                {values.image && (
                                  <img
                                      alt="Notification"
                                    src={
                                      typeof values.image !== "string"
                                        ? `${URL.createObjectURL(values.image)}`
                                        : `${values.image}`
                                    }
                                    width="128px"
                                    height="128px"
                                  />
                                )}
                                {!values.image && (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    component="span"
                                    disableRipple
                                    disableElevation
                                    disableTouchRipple
                                    className={classes.formUploadImageHelper}
                                  >
                                    <AddIcon
                                      className={
                                        classes.uploadImgBtnIconColorHelper
                                      }
                                    />{" "}
                                    Upload an image
                                  </Button>
                                )}
                              </label>
                            </Stack>

                            <Stack spacing={2}>
                              <FormHelperText
                                className={classes.formHelperTextFontSizeHelper}
                              >
                                JPG, JPEG or PNG. Max size of 5 MB
                              </FormHelperText>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <PublishOutlinedIcon fontSize="inherit" />
                                <Typography variant="body2">
                                  <label
                                    className={classes.rpPicture}
                                    htmlFor="contained-button-file2"
                                  >
                                    {values.image && (
                                      <Input
                                        accept=".jpg, .png, .jpeg"
                                        id="contained-button-file2"
                                        // multiple
                                        name="image"
                                        type="file"
                                        onChange={(
                                          event: ChangeEvent<HTMLInputElement>
                                        ) => {
                                          setFieldValue(
                                            "image",
                                            event.target?.files &&
                                              event.target.files[0]
                                          );
                                        }}
                                      />
                                    )}
                                    Replace picture
                                  </label>
                                </Typography>
                              </Stack>
                              <Stack
                                className={classes.rmPicture}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                onClick={() => {
                                  clearImageObject();
                                  setFieldValue("image", "");
                                }}
                              >
                                <DeleteOutlinedIcon fontSize="inherit" />
                                <Typography variant="body2">
                                  Remove picture
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    }}
                  </Field>
                  <FormHelperText error={true}>
                    <ErrorMessage name="image" />
                  </FormHelperText>
                  <Field name="filteredCrops">
                    {(filteredCropProps: any) => {
                      const { form } = filteredCropProps;
                      const { setFieldValue } = form;
                      return (
                        <FormControl
                          className={classes.formControlMrtHelper}
                          fullWidth
                        >
                          <Typography variant="subtitle2">Crop *</Typography>
                          <MultiSelect
                            name="crop"
                            showClearAll={true}
                            labelKey="label"
                            options={crops ?? []}
                            selectedOptions={selectedCrops ?? []}
                            onCheckboxChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleCropsCheckboxChange(event);
                              let filteredCrops: Array<any> | null = [];
                              filteredCrops = crops.filter((crop) => {
                                if (crop.label !== "Select all") {
                                  return crop.checked;
                                }
                              });
                              setFieldValue(
                                "filteredCrops",
                                filteredCrops?.length ? filteredCrops : null
                              );
                              // if (!filteredCrops?.length) {
                              // setFieldValue("stateNames", null);
                              // setFieldValue("districts", null);
                              // }
                              setNotification((prevState) => ({
                                ...prevState,
                                filteredCrops: filteredCrops,
                              }));
                            }}
                            onClearSelection={() => {
                              // handleCropsSelection(false);
                              setNotification((prevState) => ({
                                ...prevState,
                                filteredCrops: [],
                              }));
                              setFieldValue("filteredCrops", null);
                              // setFieldValue("stateNames", null);
                              // setFieldValue("districts", null);
                            }}
                            isDisabled={form.values.mobile?.length !== 0}
                          />
                        </FormControl>
                      );
                    }}
                  </Field>
                  <ErrorMessage name="filteredCrops">
                    {(
                      errorMsg:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                    ) => (
                      <FormHelperText error={true}>{errorMsg}</FormHelperText>
                    )}
                  </ErrorMessage>
                  <Field name="stateNames">
                    {(stateNameProperties: any) => {
                      const { form } = stateNameProperties;
                      const { setFieldValue } = form;
                      return (
                        <FormControl
                          className={classes.formControlMrtHelper}
                          fullWidth
                        >
                          <Typography variant="subtitle2">State *</Typography>
                          <MultiSelect
                            key={"state"}
                            name="state"
                            showClearAll={true}
                            labelKey="stateName"
                            options={cropsStateList ?? []}
                            selectedOptions={notification.stateNames ?? []}
                            onCheckboxChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleStatesCheckboxChange(event);
                              let selectedStates: Array<string> = [];
                              let selectedStateIds: Array<number> = [];
                              cropsStateList
                                .filter((eachState: any) => eachState.checked)
                                .map((eachState: any) => {
                                  if (eachState.stateName !== "Select all") {
                                    selectedStates.push(eachState.stateName);
                                    selectedStateIds.push(eachState.stateId);
                                  }
                                });
                              setNotification((prevState) => ({
                                ...prevState,
                                stateId: selectedStateIds,
                                stateNames: selectedStates,
                              }));
                              setFieldValue(
                                "stateNames",
                                selectedStates?.length ? selectedStates : null
                              );
                              if (!selectedStates?.length) {
                                setFieldValue("districts", null);
                              }
                            }}
                            onClearSelection={() => {
                              handleStateClearSelection();
                              setNotification((prevState) => ({
                                ...prevState,
                                stateNames: [],
                                stateId: [],
                              }));
                              setFieldValue("stateNames", null);
                              setFieldValue("districts", null);
                            }}
                            isDisabled={form.values.mobile?.length !== 0}
                          />
                        </FormControl>
                      );
                    }}
                  </Field>
                  <ErrorMessage name="stateNames">
                    {(
                      errorMsg:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                    ) => (
                      <FormHelperText error={true}>{errorMsg}</FormHelperText>
                    )}
                  </ErrorMessage>
                  <Field name="districts">
                    {(districtProps: any) => {
                      const { form } = districtProps;
                      const { setFieldValue } = form;
                      return (
                        <FormControl
                          className={classes.formControlMrtHelper}
                          fullWidth
                        >
                          <Typography variant="subtitle2">
                            District *
                          </Typography>
                          <MultiSelect
                            key={"districts"}
                            name="selectedDistrictObj"
                            showClearAll={true}
                            labelKey="districtName"
                            options={stateDistricts}
                            selectedOptions={
                              (notification.selectedDistrictObj?.length &&
                                notification.selectedDistrictObj.map(
                                  (dist: any) => dist.districtName
                                )) ||
                              []
                            }
                            onCheckboxChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleDistrictChange(event);
                              setFieldValue("districts", selectedDistIds);
                            }}
                            onClearSelection={() => {
                              handleClearDistrictSelection(false);
                              setFieldValue("districts", null);
                              setUserCount(0);
                              selectedDistIds = null;
                            }}
                            isDisabled={form.values.mobile?.length !== 0}
                          />
                        </FormControl>
                      );
                    }}
                  </Field>
                  <ErrorMessage name="districts">
                    {(
                      errorMsg:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                    ) => (
                      <FormHelperText error={true}>{errorMsg}</FormHelperText>
                    )}
                  </ErrorMessage>
                  <Field name="frequency">
                    {(frequencyProps: any) => {
                      const { form } = frequencyProps;
                      const { setFieldValue } = form;
                      return (
                        <RenderField
                          name="frequency"
                          label="Frequency *"
                          value={notification.frequency ?? ''}
                          labelKey="displayName"
                          options={frequencyData ?? []}
                          onChange={(name: string, value: any) => {
                            setFieldValue("frequency", value.value);
                            setNotification((prevState) => ({
                              ...prevState,
                              ["frequency"]: value.value,
                            }));
                          }}
                          key={notification.frequency}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage name="frequency">
                    {(
                      errorMsg:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined
                    ) => (
                      <FormHelperText error={true}>{errorMsg}</FormHelperText>
                    )}
                  </ErrorMessage>
                  {notification.frequency && (
                    <FormControl
                      className={classes.formControlMrtHelper}
                      fullWidth
                    >
                      <Stack direction="row" spacing={1}>
                        <Stack>
                          <Typography
                            fontSize={14}
                            variant="subtitle2"
                            component="p"
                          >
                            {notification.frequency === frequencyData[0].value
                              ? "Notification Date *"
                              : `Start Date *`}
                          </Typography>
                          <Field name="startDate">
                            {(startDateProps: any) => {
                              const { form } = startDateProps;
                              const { setFieldValue } = form;
                              return (
                                <ReactDatePicker
                                  selected={notification.startDate}
                                  minDate={new Date()}
                                  maxDate={null}
                                  placeholderText={
                                    notification.frequency ===
                                    frequencyData[0].value
                                      ? "Notification Date *"
                                      : `Start Date *`
                                  }
                                  datePickerCallBack={(newDate: Date) => {
                                    setFieldValue("startDate", newDate);
                                    /** Resetting the end date */
                                    if (
                                      newDate.getTime() &&
                                      notification.endDate &&
                                      newDate.getTime() >
                                        notification.endDate.getTime()
                                    ) {
                                      setNotification((prevState) => ({
                                        ...prevState,
                                        endDate: null,
                                      }));
                                      setFieldValue("endDate", null);
                                    }
                                    setNotification((prevState) => ({
                                      ...prevState,
                                      startDate: newDate,
                                    }));
                                  }}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="startDate">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </Stack>
                        {notification.frequency !== frequencyData[0].value && (
                          <Stack>
                            <Typography
                              fontSize={14}
                              variant="subtitle2"
                              component="p"
                            >
                              End Date *
                            </Typography>
                            <Field name="endDate">
                              {(endDateProps: any) => {
                                const { form } = endDateProps;
                                const { setFieldValue } = form;
                                return (
                                  <ReactDatePicker
                                    selected={notification.endDate}
                                    minDate={
                                      notification.startDate || new Date()
                                    }
                                    maxDate={null}
                                    placeholderText={
                                      notification.frequency ===
                                      frequencyData[0].value
                                        ? "Notification Date *"
                                        : `Start Date *`
                                    }
                                    datePickerCallBack={(newDate: Date) => {
                                      setFieldValue("endDate", newDate);
                                      setNotification((prevState) => ({
                                        ...prevState,
                                        endDate: newDate,
                                      }));
                                    }}
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage name="endDate">
                              {(
                                errorMsg:
                                  | boolean
                                  | React.ReactChild
                                  | React.ReactFragment
                                  | React.ReactPortal
                                  | null
                                  | undefined
                              ) => (
                                <FormHelperText error={true}>
                                  {errorMsg}
                                </FormHelperText>
                              )}
                            </ErrorMessage>
                          </Stack>
                        )}
                      </Stack>
                    </FormControl>
                  )}
                  {notification.frequency === frequencyData[2].value && (
                    <FormControl
                      className={classes.formControlMrtHelper}
                      fullWidth
                    >
                      <Stack direction="row" spacing={3}>
                        <Stack flex="1">
                          <Field name="dayOfWeek">
                            {(weekDayProps: any) => {
                              const { form } = weekDayProps;
                              const { setFieldValue } = form;
                              return (
                                <RenderField
                                  name="dayOfWeek"
                                  label="Select Day *"
                                  labelKey="displayName"
                                  value={notification.dayOfWeek ?? ''}
                                  options={dayData ?? []}
                                  onChange={(name: string, value: any) => {
                                    updateNotificationDetails(
                                      "dayOfWeek",
                                      value
                                    );
                                    setFieldValue("dayOfWeek", value.value);
                                  }}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="dayOfWeek">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </Stack>
                      </Stack>
                    </FormControl>
                  )}
                  {notification.frequency === frequencyData[3].value && (
                    <FormControl
                      className={classes.formControlMrtHelper}
                      fullWidth
                    >
                      <Stack direction="row" spacing={3}>
                        <Stack flex="1">
                          <Field name="dayOfMonth">
                            {(dayOfMonthProps: any) => {
                              const { form } = dayOfMonthProps;
                              const { setFieldValue } = form;
                              return (
                                <RenderField
                                  name="dayOfMonth"
                                  label="Select Day of Month*"
                                  labelKey="displayName"
                                  value={notification.dayOfMonth ?? ''}
                                  options={dayOfMonthData ?? []}
                                  onChange={(name: string, value: any) => {
                                    updateNotificationDetails(
                                      "dayOfMonth",
                                      value
                                    );
                                    setFieldValue("dayOfMonth", value.value);
                                  }}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="dayOfMonth">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText error={true}>
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </Stack>
                      </Stack>
                    </FormControl>
                  )}
                  <FormControl
                    className={classes.formControlMrtHelper}
                    fullWidth
                  >
                    <Stack direction="row" spacing={3} maxWidth="210px">
                      <Stack>
                        <Typography
                          fontSize={14}
                          variant="subtitle2"
                          component="p"
                        >
                          Notification Time *
                        </Typography>
                        <Field name="time">
                          {(timeProps: any) => {
                            const { form } = timeProps;
                            const { setFieldValue } = form;
                            return (
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <TimePicker
                                  disableOpenPicker={false}
                                  minTime={
                                    new Date() > notification?.startDate
                                      ? new Date()
                                      : notification.startDate
                                  } //disabling before notification time
                                  renderInput={(customFieldProps) => (
                                    <TextField
                                      {...customFieldProps}
                                      className={classes.formTimePickerHelper}
                                      disabled={true}
                                      onKeyDown={(
                                        e: React.KeyboardEvent<HTMLInputElement>
                                      ) => {
                                        e.stopPropagation()
                                        e.preventDefault();
                                      }}
                                    />
                                  )}
                                  value={notification.time}
                                  onChange={(newValue: any) => {
                                    setNotification((prevState) => ({
                                      ...prevState,
                                      time: newValue,
                                    }));
                                    setFieldValue("time", newValue);
                                  }}
                                  closeOnSelect={false}
                                />
                              </LocalizationProvider>
                            );
                          }}
                        </Field>
                        <ErrorMessage name="time">
                          {(
                            errorMsg:
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined
                          ) => (
                            <FormHelperText error={true}>
                              {errorMsg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </Stack>
                    </Stack>
                  </FormControl>
                  {selectedLangObj.map(
                    (item: LangObjectType, index: number) => {
                      return (
                        <Stack key={item.languageId} flex={1}>
                          <FormControl className={classes.formControlMrtHelper}>
                            <Typography fontSize={14} variant="subtitle2">
                              Notification Title {item.languageName} *
                            </Typography>
                            <Field
                              name={`title-${index}`}
                              validate={(value: any) => {
                                return !value
                                  ? "Notification Title required"
                                  : "";
                              }}
                            >
                              {(titleProps: any) => {
                                const { form } = titleProps;
                                const { setFieldValue } = form;

                                return (
                                  <TextField
                                    fullWidth
                                    onBlur={(event) => {
                                      const titles = !form.values.titles
                                        ? []
                                        : [...form.values.titles];
                                      const title = event.target.value
                                        ? `${item.languageId}##${item.languageName}##${item.languageCode}-${event.target.value}`
                                        : undefined;

                                      titles[index] = title;

                                      setFieldValue("titles", titles);
                                      setFieldValue(`title-${index}`, title);
                                    }}
                                    inputProps={{ maxLength: 50 }}
                                    defaultValue={form.values.title}
                                    key={form.values.title}
                                  />
                                );
                              }}
                            </Field>
                            {errors[`title-${index}`] ? (
                              <FormHelperText error={true}>
                                {errors[`title-${index}`]}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                          <FormControl
                            className={classes.formControlMrtHelper}
                            fullWidth
                          >
                            <Typography fontSize={14} variant="subtitle2">
                              Message {item.languageName} *
                            </Typography>
                            <Typography fontSize={12} color="#696F88">
                              Max. 1024 charaters
                            </Typography>
                            <Field
                              name={`message-${index}`}
                              validate={(value: string) => {
                                if (!value) return "Message required";
                                if (value.length > 1024)
                                  return "Max value 1024";

                                return "";
                              }}
                            >
                              {(messageProps: any) => {
                                const { form } = messageProps;
                                const { setFieldValue } = form;
                                return (
                                  <TextareaAutosize
                                    name="message"
                                    className={classes.textAreaStyles}
                                    onBlur={(
                                      event: React.ChangeEvent<HTMLTextAreaElement>
                                    ) => {
                                      const messages = !form.values.messages
                                        ? []
                                        : [...form.values.messages];
                                      const message = event.target.value
                                        ? `${item.languageId}##${item.languageName}##${item.languageCode}-${event.target.value}`
                                        : undefined;

                                      messages[index] = message;

                                      // handleMessageChange(event);
                                      setFieldValue("messages", messages);
                                      setFieldValue(
                                        `message-${index}`,
                                        message
                                      );
                                    }}
                                    defaultValue={form.values.message}
                                    key={form.values.message}
                                  />
                                );
                              }}
                            </Field>
                            {errors[`message-${index}`] ? (
                              <FormHelperText error={true}>
                                {errors[`message-${index}`]}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                          <FormControl
                            className={classes.formControlMrtHelper}
                            fullWidth
                          >
                            <Typography variant="subtitle2">
                              Hyperlink {item.languageName}
                            </Typography>
                            <Field name="hyperLink">
                              {(hyperLinkProps: any) => {
                                const { form } = hyperLinkProps;
                                const { setFieldValue } = form;
                                let url: string = "";

                                if (form.values?.hyperlinks?.[index]) {
                                  url =
                                    form.values?.hyperlinks?.[index].split(
                                      /-(.*)/s
                                    )[1];
                                }

                                return (
                                  <TextareaAutosize
                                    name="hyperLink"
                                    className={classes.textAreaStyles}
                                    maxLength={200}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLTextAreaElement>
                                    ) => {
                                      const hyperlinks = !form.values.hyperlinks
                                        ? []
                                        : [...form.values.hyperlinks];
                                      const hyperlink = event.target.value
                                        ? `${item.languageId}##${item.languageName}##${item.languageCode}-${event.target.value}`
                                        : undefined;

                                      if (hyperlink) {
                                        hyperlinks[index] =
                                          validationTrimUrl(hyperlink);
                                      } else {
                                        hyperlinks[index] = hyperlink;
                                      }

                                      setFieldValue("hyperlinks", hyperlinks);
                                      setFieldValue(
                                        `hyperlink-${index}`,
                                        hyperlink
                                      );
                                    }}
                                    value={url}
                                    defaultValue={form.values.hyperLink}
                                    key={form.values.hyperLink}
                                  />
                                );
                              }}
                            </Field>
                          </FormControl>
                        </Stack>
                      );
                    }
                  )}

                  {values.mobile ? (
                    <Stack>
                      <FormControl className={classes.formControlMrtHelper}>
                        <Typography fontSize={14} variant="subtitle2">
                          Notification Title *
                        </Typography>
                        <Field name="title">
                          {(titleProps: any) => {
                            const { form } = titleProps;
                            const { setFieldValue } = form;
                            return (
                              <TextField
                                fullWidth
                                onBlur={(event) => {
                                  setFieldValue("title", event.target.value);
                                }}
                                inputProps={{ maxLength: 50 }}
                                defaultValue={values.title}
                                key={values.title}
                              />
                            );
                          }}
                        </Field>
                        <ErrorMessage name="title">
                          {(
                            errorMsg:
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined
                          ) => (
                            <FormHelperText error={true}>
                              {errorMsg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </FormControl>
                      <FormControl
                        className={classes.formControlMrtHelper}
                        fullWidth
                      >
                        <Typography fontSize={14} variant="subtitle2">
                          Message *
                        </Typography>
                        <Typography fontSize={12} color="#696F88">
                          Max. 1024 charaters
                        </Typography>
                        <Field name="message">
                          {(messageProps: any) => {
                            const { form } = messageProps;
                            const { setFieldValue } = form;
                            return (
                              <TextareaAutosize
                                name="message"
                                className={classes.textAreaStyles}
                                onBlur={(
                                  event: React.ChangeEvent<HTMLTextAreaElement>
                                ) => {
                                  setFieldValue("message", event.target.value);
                                }}
                                defaultValue={values.message}
                                key={values.message}
                              />
                            );
                          }}
                        </Field>
                        <ErrorMessage name="message">
                          {(
                            errorMsg:
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined
                          ) => (
                            <FormHelperText error={true}>
                              {errorMsg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </FormControl>
                      <FormControl
                        className={classes.formControlMrtHelper}
                        fullWidth
                      >
                        <Typography variant="subtitle2">Hyperlink</Typography>
                        <Field name="hyperLink">
                          {(hyperLinkProps: any) => {
                            const { form } = hyperLinkProps;
                            const { setFieldValue } = form;
                            return (
                              <TextareaAutosize
                                name="hyperLink"
                                className={classes.textAreaStyles}
                                maxLength={200}
                                onBlur={(
                                  event: React.ChangeEvent<HTMLTextAreaElement>
                                ) => {
                                  handleMessageChange(event);
                                  setFieldValue(
                                    "hyperLink",
                                    event.target.value
                                  );
                                }}
                                defaultValue={values.hyperLink}
                                key={values.hyperLink}
                              />
                            );
                          }}
                        </Field>
                        <ErrorMessage name="hyperLink">
                          {(
                            errorMsg:
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined
                          ) => (
                            <FormHelperText error={true}>
                              {errorMsg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    </Stack>
                  ) : null}

                  {notification.sendAsSMS && (
                    <FormControl
                      className={classes.formTotalCostHelper}
                      fullWidth
                    >
                      <Typography variant="subtitle2">Total Cost</Typography>
                      <Field name="smsCost">
                        {(smsCostProps: any) => {
                          const { form } = smsCostProps;
                          const { setFieldValue } = form;
                          return (
                            <TextField
                              onBlur={() => {
                                setFieldValue("smsCost", notification.smsCost);
                              }}
                              value={notification.smsCost}
                              disabled
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage name="smsCost">
                        {(
                          errorMsg:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined
                        ) => (
                          <FormHelperText error={true}>
                            {errorMsg}
                          </FormHelperText>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </div>
              </Box>
              <Divider />
              <div className={classes.formButtonHelper}>
                <Stack direction="row" spacing={2}>
                  <ButtonWithIcon
                    showCreateProgress={props.showCreateProgress}
                    type={CANCEL_TYPE}
                    label="Cancel"
                    onClick={() => {
                      if (confirm("You may lose your data.")) {
                        handleClose();
                      }
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    disableElevation
                  >
                    {(isEdit && "Save") || "Create"}
                  </Button>
                </Stack>
              </div>
            </Paper>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default AddEditNotification;
