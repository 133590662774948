import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Box, IconButton } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useStyles } from './datePickerStyles';

export type ReactDatePickerProps = {
    datePickerCallBack: Function;
    maxDate: Date | null;
    minDate: Date | null;
    placeholderText: string;
    selected: Date | null;
};
const ReactDatePicker: React.FC<ReactDatePickerProps> = (props) => {
    const classes = useStyles();

    const [, setStartDate] = useState<Date | null>(null);
    const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
        <Box className={classes.DatePickerContainer} display='flex' alignItems='center' ref={ref}>
            {/* --------------------Calender Input Field----------------------------------- */}
            <input className={classes.inputTagStyles} placeholder='Select Date' value={value} readOnly />

            {/* --------------------Calender Icon Button----------------------------------- */}
            <IconButton onClick={onClick} >
                <CalendarTodayOutlinedIcon fontSize='small' className={classes.iconStyles} />
            </IconButton>
        </Box>
    ));

    return (
        <DatePicker
            selected={props.selected || null}
            minDate={props.minDate}
            maxDate={props.maxDate}
            onChange={(newDate: Date) => {
                setStartDate(newDate);
                props.datePickerCallBack(newDate);
            }}
            dateFormat='dd-MM-yyyy'
            placeholderText={props.placeholderText}
            onKeyDown={(e) => {
                e.preventDefault();
            }}
            customInput={<ExampleCustomInput />}
            popperClassName={classes.reactdatepickerpopper}
        // customInput={
        //     <CalendarTodayOutlinedIcon />
        // }
        />
    );
};

export default ReactDatePicker;
