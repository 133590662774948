import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { FC, useState } from "react";
import CustomTabs from "../../../Common/CustomTabs/index";
import ResponseView from "./ResponseView";
import { useStyles } from "./style";

type ViewResponseHeaderProps = {
  header: any;
  onClose: Function;
  showEdit?: boolean;
  showDelete?: boolean;
  queryItemObject: any;
  queryItemData: any;
};

const ViewResponseHeader: FC<ViewResponseHeaderProps> = (
  props: ViewResponseHeaderProps
) => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const queryItemObject = [
    props.queryItemObject?.queryId,
    `+${props.queryItemObject?.phoneNumber}`,
    props.queryItemObject?.growerState,
    props.queryItemObject?.growerDistrict,
    props.queryItemObject?.name,
    props.queryItemObject?.category,
  ];

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2, py: 3, backgroundColor: "#EAF6FF" }}
      >
        <Stack
          spacing={1.5}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          {props.header.map((item: any, index: number) => {
            return (
              <Box key={index}>
                <Typography fontSize={12} fontWeight="semiBold">
                  {item}
                </Typography>
                <Typography
                  fontSize={12}
                  fontWeight="bold"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {queryItemObject[index]}
                </Typography>
              </Box>
            );
          })}
        </Stack>
        <Box>
          <IconButton
            onClick={() => {
              props.onClose();
            }}
          >
            <CloseIcon fontSize="small" color="action" />
          </IconButton>
        </Box>
      </Stack>
      <TableContainer>
        <Stack className={classes.tableStackStyle}>
          <CustomTabs
            tabsList={props.queryItemData?.queryConversations}
            value={activeTab}
            isQuery={true}
            onChange={handleChange}
          />
        </Stack>
        <Divider />
      </TableContainer>
      {props.queryItemData !== null && (
        <ResponseView
          ViewAPIResponse={props.queryItemData}
          activeTab={activeTab}
        />
      )}
    </>
  );
};

export default ViewResponseHeader;
