import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import ReactPlayer from "react-player";
import { TableHeaderTypes } from "../../Components/Crop/ViewCrop/TableHeaders";
import { useStyles } from "./style";
import Paper from "@mui/material/Paper";
type TableProps = {
  data: any[];
  headers: TableHeaderTypes[];
};
export const TableComponent: React.FC<TableProps> = (props: TableProps) => {
  const classes = useStyles();

  const renderImage = (url: string) => {
    return <img src={url} alt="Image" width="70" />;
  };

  const renderVideo = (url: string) => {
    return (
      <ReactPlayer
        className="react-player"
        url={url}
        width="75px"
        height="50px"
      />
    );
  };

  const renderAnchorTag = (url: string, text: string) => {
    return (
      <a href={url} target="_new">
        {text}
      </a>
    );
  };

  const renderContent = (
    content: React.ReactNode | string | undefined,
    callback?: (content: any, rowData?: any) => any,
    data?: any
  ) => {
    if (content && typeof content === "string") {
      if (content.includes("youtu")) {
        return renderVideo(content);
      } else if (
        content.includes(".png") ||
        content.includes(".jpg") ||
        content.includes(".jpeg")
      ) {
        return renderImage(content);
      } else if (content.startsWith("http")) {
        return renderAnchorTag(content, "click here");
      }
    } else if (Array.isArray(content)) {
      if (callback) return callback(content, data);

      return content;
    }
    return content || content === 0 ? `${content}` : "--";
  };

  const renderData = () => {
    return (
      <>
        {props.data.map((dataObj, index: number) => (
          <TableRow
            key={`TableBody Data Row ${index}`}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {props.headers.map((headerObj: TableHeaderTypes) => {
              const callback = headerObj.callback;

              let dataValue: React.ReactNode | string | undefined =
                dataObj[headerObj.FieldName];

              if (headerObj.isBoolean) {
                dataValue = dataObj[headerObj.FieldName]
                  ? headerObj.truthLabel
                  : headerObj.falseLabel;
              } else if (headerObj.FieldName === "filterValue") {
                dataValue = dataObj.filterValues.map((filterValueObj: any) => {
                  return filterValueObj.valueSystemName;
                });
              }

              return (
                <>
                  <TableCell scope="row" width={250}>
                    {renderContent(dataValue, callback, dataObj)}
                  </TableCell>
                </>
              );
            })}
          </TableRow>
        ))}
      </>
    );
  };
  return (
    <TableContainer component={Paper} style={{ borderRadius: 0 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className={classes.basicTbHeaderHelper}>
          <TableRow>
            {props.headers.map((headerLabel) => {
              return (
                <TableCell
                  key={`${headerLabel.FieldName}-${headerLabel.HeaderName}`}
                >
                  {headerLabel.HeaderName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody className={classes.basicTbBodyHelper}>
          {renderData()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
