export const StagesToCreateAHybrid = [
    {
      stepLabel: 'General Details',
      stepValue: 'general-details',
      hasErrors: false,
      isFormValid: false
    },
    {
      stepLabel: 'Features',
      stepValue: 'features',
      hasErrors: false,
      isFormValid: false
    },
    {
      stepLabel: 'Precautions',
      stepValue: 'precautions',
      hasErrors: false,
      isFormValid: false
    },
    {
      stepLabel: 'Agronomy Practices',
      stepValue: 'agronomy-practices',
      hasErrors: false,
      isFormValid: false
    },
    {
      stepLabel: 'Grower Testimonials',
      stepValue: 'grower-testimonials',
      hasErrors: false,
      isFormValid: false
    },
    {
        stepLabel: 'Applicable Filters',
        stepValue: 'applicable-filters',
        hasErrors: false,
        isFormValid: false
      },
  ];
  