import {
  Box,
  Modal,
  Paper,
  Stack,
  FormHelperText,
  Divider,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ActionType } from "../../../../Common/Constants";
import LanguageDropdown from "../../../../Common/LanguageDropdown";
import ModalHeader from "../../../../Common/Modal/ModalHeader";
import { useStyles } from "../style";
import RenderField from "../../../../Common/RenderField";

interface CreateOrEditModalProps {
  actionType: string;
  showCreateOrEditModal: boolean;
  onShowCreateOrEditModal: (toggle: boolean) => void;
}

const CreateOrEditModal: React.FC<CreateOrEditModalProps> = (props) => {
  const classes = useStyles();

  return (
    <Modal
      open={props.showCreateOrEditModal}
      disableEscapeKeyDown={true}
      // onClose={() => props.onShowCreateOrEditModal(false)}
    >
      <Paper className={classes.formPaper}>
        <Paper elevation={3} square>
          <ModalHeader
            header="Map Products"
            showDelete={false}
            showEdit={false}
            onClose={() => {
              if (confirm("You may lose your data.?")) {
                props.onShowCreateOrEditModal(false);
              }
            }}
          />
          <Formik initialValues={{}} onSubmit={() => {}}>
            {({}) => (
              <Form id="plantix-cp-products-form">
                <Box className={classes.formContainer}>
                  <Box className={classes.formFieldsHelperOne}>
                    <LanguageDropdown
                      onChangeLanguage={() => {
                        // TODO
                      }}
                      selectedLanguage=""
                      isDisabled={props.actionType === ActionType.UPDATE}
                      displayPrimaryLanguage={
                        props.actionType !== ActionType.ADD_TRANSLATION
                      }
                    />
                    <FormHelperText error={true} className="langError">
                      <ErrorMessage name="language" />
                    </FormHelperText>
                  </Box>
                  <Box className={classes.formFieldsHelper}>
                    <Field name="crop">
                      {() => {
                        return (
                          <FormControl
                            className={classes.formMarginHelper}
                            fullWidth
                          >
                            <RenderField
                              // key={values.crop}
                              name="crop"
                              labelKey="name"
                              // TODO
                              options={[]}
                              // options={props.cropList ? props.cropList : []}
                              label="Crop"
                              // TODO
                              value=""
                              // value={values.crop}
                              // TODO
                              onChange={() => {}}
                              // onChange={(name: string, value: any) => {
                              //   if (
                              //     (props.targetThreatList.length ||
                              //       props.recommendationList.length) &&
                              //     confirm(
                              //       "Alert!! As you are trying to change the crop, the step5 and step6 details will be Reset."
                              //     )
                              //   ) {
                              //     props.resetThreatAndCalendar();
                              //     setFieldValue("crop", value.name);
                              //   } else if (
                              //     !(
                              //       props.targetThreatList.length ||
                              //       props.recommendationList.length
                              //     )
                              //   ) {
                              //     setFieldValue("crop", value.name);
                              //   }
                              // }}
                              // disabled={!props.isSystemNameEditable}
                            />
                          </FormControl>
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="crop" />
                    </FormHelperText>
                    <Stack>
                      <Typography variant="subtitle2">
                        Map Diseases to Products
                      </Typography>
                      <FormControl
                        className={classes.formMarginHelper}
                        fullWidth
                      >
                        <Typography variant="subtitle2">
                          Disease 1
                        </Typography>
                        <FormGroup style={{flexDirection: 'row'}}>
                          <FormControlLabel control={<Checkbox defaultChecked />} label="Product Name 1" />
                          <FormControlLabel control={<Checkbox />} label="Product Name 2" />
                          <FormControlLabel control={<Checkbox />} label="Product Name 3" />
                          <FormControlLabel control={<Checkbox />} label="Product Name 4" />
                        </FormGroup>
                      </FormControl>
                      <FormControl
                        className={classes.formMarginHelper}
                        fullWidth
                      >
                        <Typography variant="subtitle2">
                          Disease 2
                        </Typography>
                        <FormGroup style={{flexDirection: 'row'}}>
                          <FormControlLabel control={<Checkbox defaultChecked />} label="Product Name 1" />
                          <FormControlLabel control={<Checkbox />} label="Product Name 2" />
                          <FormControlLabel control={<Checkbox />} label="Product Name 3" />
                          <FormControlLabel control={<Checkbox />} label="Product Name 4" />
                        </FormGroup>
                      </FormControl>
                    </Stack>
                  </Box>
                </Box>
                <Divider />
                <Box className={classes.modalFooterActionBtnsHelper}>
                  <Button
                    color="success"
                    variant="contained"
                    className={classes.buttonHelperNext}
                    type="submit"
                  >
                    Next
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Paper>
    </Modal>
  );
};

export default CreateOrEditModal;
