import { Box, TableContainer, Stack } from "@mui/material";
import { DOWNLOAD_SAMPLE_TEMPLATE_TYPE } from "../../Common/Constants";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import { DownLoadSampleTemplate } from "../../Common/DownloadSampleTemplate";
import FiltersAndSort from "../../Common/FilterAndSort";
import PageTitle from "../../Common/PageTitle";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import ModalCreatePopUp from "./ModalCreatePopUp";
import { filterObjType } from "./PlantixMapProduct";
import { useStyles } from "./style";

interface PlantixMapProductProps {
  searchValue: string;
  activeTab: number;
  limit: number;
  sortId: "" | "0" | "1";
  filterObj: filterObjType;
  currentPage: number;
  totalPages: number;
  open: boolean;
  showCreateOrEditModal: boolean;
  actionType: string;
  showBulkUpload: boolean;
  onSearchValueChange: (newValue: string) => void;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  onClickAction: (clickAction: string, detail?: any) => void;
  onKnowMorePopUp: (flag: boolean, rowData: any) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  onShowCreateOrEditModal: (toggle: boolean) => void;
  onShowBulkUpload: (toggle: boolean) => void;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setSortId: React.Dispatch<React.SetStateAction<"" | "0" | "1">>;
  setFilterObj: React.Dispatch<React.SetStateAction<filterObjType>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onSelectAll: (status: boolean) => void;
  onRowSelect: (status: boolean, rowInfo: any) => void;
  dataToDisplay: any[];
  buttonExportVisible?: boolean;
}

type ColumnTypes = {
  fieldLabel: string;
  fieldName: string;
};

const DataListColumns: ColumnTypes[] = [
  {
    fieldLabel: "Crop",
    fieldName: "cropName",
  },
  {
    fieldLabel: "Disease",
    fieldName: "diseaseName",
  },
  {
    fieldLabel: "CP System Name",
    fieldName: "systemName",
  },
  {
    fieldLabel: "Eppo Code",
    fieldName: "eppoCode",
  },
  {
    fieldLabel: "Peat ID",
    fieldName: "peatId",
  },
  {
    fieldLabel: "Last Updated On",
    fieldName: "lastUpdatedOn",
  },
];

const MyUploadColumns: ColumnTypes[] = [
  {
    fieldLabel: "File Name",
    fieldName: "fileName",
  },
  {
    fieldLabel: "Uploaded On",
    fieldName: "cropsUploadDate",
  },
  {
    fieldLabel: "Status",
    fieldName: "comnMyUploadStatus",
  },
];

const PlantixMapProductView: React.FC<PlantixMapProductProps> = (props) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <div>
        <Stack
          direction="row"
          alignItems="center"
          className={classes.pageTitleNSearchStylesHelper}
        >
          <PageTitle title="Plantix - Map Products" />
          <SearchBar
            placeholderText={`Search by ${
              props.activeTab === 0
                ? "Crop, Disease, CP System Name"
                : "File Name"
            }`}
            searchValue={props.searchValue ?? ""}
            onSearchValueChange={props.onSearchValueChange}
            onEnterClick={() => {}}
          />
        </Stack>
        <Box>
          <Box className={classes.customTabBtmBorder}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CustomTabs
                value={props.activeTab}
                tabsList={["Data List", "My uploads"]}
                onChange={props.onChange}
              ></CustomTabs>
              {props.activeTab === 1 && (
                <DownLoadSampleTemplate
                  literal={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.PLANTIX_MAP}
                  displayAsButton={false}
                />
              )}
            </Stack>
          </Box>
          <Stack direction="row" className={classes.rowsPerPageStylesHelper}>
            <RowsPerPage
              pagSize={5}
              onChangeRowsPerPage={(data: number) => props.setLimit(data)}
            />
            <FiltersAndSort
              sortBy={props.sortId}
              onSort={(sortType: any) =>
                props.setSortId(sortType?.toString() ?? "1")
              }
              onChangeFilters={(data: any) => props.setFilterObj(data)}
              filters={{ ...props.filterObj }}
              hasFilter={props.activeTab === 0}
              isResetFilter={`${props.activeTab}`}
              showLanguageSelection={false}
              showSecondarySort={false}
              showCropSelection={true}
              showLastUpdatedOn={true}
            />
          </Stack>
          <DataTable
            columns={props.activeTab === 0 ? DataListColumns : MyUploadColumns}
            data={props.dataToDisplay}
            enableSelection={props.buttonExportVisible && props.activeTab !== 1}
            pageNumber={1}
            onPageChange={() => {}}
            onSelectAll={props.onSelectAll}
            onDeselectAll={() => {}}
            onRowSelect={props.onRowSelect}
            isEditEnabled={false}
            isViewButton={props.activeTab === 0}
            isDeleteEnabled={props.activeTab === 0}
            onActionClick={(type, rowInfo) =>
              props.onClickAction(type, rowInfo)
            }
            hasTranslation={true}
            languageKey="languageName"
            handleKnowMorePopup={(flag: boolean, rowData: any) =>
              props.onKnowMorePopUp(flag, rowData)
            }
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <DataTableActions
              actionsType={ACTION_TYPE.TYPE_CP_PRODUCT}
              pageNumber={props.currentPage + 1}
              onPageChange={(selectedPageNo: number) =>
                props.setCurrentPage(selectedPageNo - 1)
              }
              buttonVisible={props.activeTab === 0}
              buttonExportVisible={props.buttonExportVisible}
              onActionClick={(data: string) => props.onClickAction(data)}
              canShowAvatar={props.activeTab === 0}
              activeTab={props.activeTab}
              totalpages={props.totalPages}
            />
          </Stack>
        </Box>
      </div>
      <ModalCreatePopUp
        open={props.open}
        actionType={props.actionType}
        showCreateOrEditModal={props.showCreateOrEditModal}
        showBulkUpload={props.showBulkUpload}
        onCloseModal={props.onCloseModal}
        onShowCreateOrEditModal={props.onShowCreateOrEditModal}
        onShowBulkUpload={props.onShowBulkUpload}
        onChangeTabs={(tabs: number) => props.onChange(null as any, tabs)}
      />
    </TableContainer>
  );
};

export default PlantixMapProductView;
