import {
  Box,
  Modal,
  Paper,
  Stack,
  Button,
  Fab,
  Typography,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalHeader from "../../../../Common/Modal/ModalHeader";
import { useStyles } from "../style";
import { DragAndDrop } from "../../../../Common/DragAndDrop";
import ButtonWithIcon from "../../../../Common/Buttons";
import { useState } from "react";
import api_endpoints, { base_url } from "../../../../Api/end_points";
import { IRequestBody } from "../../../../Constants/interfaces";
import { api_service } from "../../../../Api/api_service";
import { usePlantixCPProduct } from "../../PlantixCPProducts";
import { TOASTMASTER_POPUP_TYPE } from "../../../../Common/Constants";
import { TriggerToastMessage } from "../../../../Utils/toastTrigger";
import getRandomizeValue from "../../../../Utils/randomizer";
import { DownLoadSampleTemplate } from "../../../../Common/DownloadSampleTemplate";

interface BulkUploadProps {
  showBulkUpload: boolean;
  onShowBulkUpload: (toggle: boolean) => void;
  templateLiteral: string;
  validationLiteral: string;
}

const BulkUploadModal: React.FC<BulkUploadProps> = (props) => {
  const classes = useStyles();

  const [fileData, setFileData] = useState<any>(undefined);
  const { changeTabs } = usePlantixCPProduct();

  const handleSubmit = () => {
    if (fileData) {
      let apiData = {} as IRequestBody;
      apiData.domain = base_url;
      apiData.endPoint = api_endpoints.bulk_upload;

      let formData = new FormData();
      formData.append("dataType", "Plantix CP Products");
      formData.append("file", fileData);

      apiData.payLoad = formData;

      api_service
        .post(apiData)
        .then((response: any) => {
          const res = response.data;

          if (res.statusCode === 200) {
            TriggerToastMessage(res.message, TOASTMASTER_POPUP_TYPE.SUCCESS);
          } else {
            TriggerToastMessage(res.errorMessage, TOASTMASTER_POPUP_TYPE.ERROR);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          props.onShowBulkUpload(false);
          setFileData(undefined);
          changeTabs(1);
          // fetchData();
        });
    }
  };

  return (
    <Modal
      open={props.showBulkUpload}
      className={classes.modalStylesHelper}
    >
      <Paper className={classes.formPaperHelper}>
        <ModalHeader
          header="Bulk Upload"
          onClose={() => {
            props.onShowBulkUpload(false);
            setFileData(undefined);
          }}
          showDelete={false}
          showEdit={false}
        ></ModalHeader>
        <Box className={classes.bulkUploadModal}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              className={classes.bulkDataBorderBottom}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Fab
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  color="primary"
                  className={classes.bulkUploadFabBtnHelper}
                  size="small"
                >
                  1
                </Fab>
                <Typography color="primary" variant="subtitle2">
                  Download the sample template
                </Typography>
              </Stack>
              <DownLoadSampleTemplate
                literal={props.templateLiteral}
                displayAsButton={true}
              />
            </Stack>
            <Stack width="100%">
              <Stack direction="row" spacing={1} alignItems="center">
                <Fab
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  color="primary"
                  sx={{ boxShadow: "none" }}
                  size="small"
                >
                  2
                </Fab>
                <Typography color="primary" variant="subtitle2">
                  Upload the completed template
                </Typography>
              </Stack>
              <DragAndDrop
                maxSize={5}
                types={["xls", "xlsx"]}
                multiple={false}
                label="Click or drag file to this area to upload"
                name={"Support for a single or bulk upload"}
                key={fileData?.name || getRandomizeValue()}
                handleChange={(selectedFile: any) => {
                  let fileMB = selectedFile.size / 1000 / 1000;

                  if (fileMB <= 5) {
                    setFileData(selectedFile);
                    return;
                  }
                }}
                onSizeError={() => {
                  // TODO
                }}
              />
              {fileData && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  minWidth="250px"
                  sx={{ marginInline: "auto", marginBlockStart: "1rem" }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Stack direction="column">
                      <Stack direction="row" alignItems="center">
                        <Stack direction="row" alignItems="center">
                          <AttachFileIcon
                            className={classes.bulkUploadAttachCloseBtnsHelper}
                          />
                          <Typography
                            color="primary"
                            variant="body1"
                            component="span"
                          >
                            {fileData && fileData.name}
                          </Typography>
                        </Stack>
                        <Button
                          onClick={() => {
                            setFileData(undefined);
                          }}
                          data-testid="delete-file"
                          variant="text"
                          color="info"
                        >
                          <CloseOutlinedIcon
                            className={classes.bulkUploadAttachCloseBtnsHelper}
                          />
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack width="330px" sx={{ marginInline: "auto", marginY: "1rem" }}>
              <ButtonWithIcon
                label={"Submit"}
                type="MODAL CREATE"
                onClick={() => {
                  handleSubmit();
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default BulkUploadModal;
