import { Box, Modal, Paper, Stack } from "@mui/material";
import ButtonWithIcon from "../../../Common/Buttons";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import BulkUploadModal from "./BulkUploadModal";
import CreateOrEditModal from "./CreateOrEditModal";
import { useStyles } from "./style";
import {DOWNLOAD_SAMPLE_TEMPLATE_TYPE, PublishModuleDD} from "../../../Common/Constants";

interface ModalCreatePopUpProps {
  open: boolean;
  showCreateOrEditModal: boolean;
  actionType: string;
  showBulkUpload: boolean;
  onCloseModal: () => void;
  onShowCreateOrEditModal: (toggle: boolean) => void;
  onShowBulkUpload: (toggle: boolean) => void;
}

const ModalCreatePopUp: React.FC<ModalCreatePopUpProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        open={props.open}
        // onClose={() => {
        //   props.onCloseModal();
        // }}
      >
        <Paper className={classes.createHybridPaper}>
          <ModalHeader
            header="Add New Data"
            onClose={() => props.onCloseModal()}
            showDelete={false}
            showEdit={false}
          />
          <Box className="containerBox">
            <Box className="flexBoxContainerInner">
              <Stack width={330} spacing={4}>
                <ButtonWithIcon
                  label={"Create new data"}
                  type={"MODAL CREATE"}
                  onClick={() => props.onShowCreateOrEditModal(true)}
                />
                <ButtonWithIcon
                  label={"Bulk upload data"}
                  type={"MODAL CANCEL"}
                  onClick={() => props.onShowBulkUpload(true)}
                />
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Modal>
      <CreateOrEditModal
        actionType={props.actionType}
        showCreateOrEditModal={props.showCreateOrEditModal}
        onShowCreateOrEditModal={props.onShowCreateOrEditModal}
      />
      <BulkUploadModal
        templateLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.PLANTIX_CP}
        validationLiteral={PublishModuleDD.PLANTIX_CP_PRODUCTS}
        showBulkUpload={props.showBulkUpload}
        onShowBulkUpload={props.onShowBulkUpload}
      />
    </>
  );
};

export default ModalCreatePopUp;
