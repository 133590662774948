import { Box, Stack, TableContainer } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api_endpoints, { base_url } from "../../Api/end_points";
import {
  ActionType,
  DOWNLOAD_SAMPLE_TEMPLATE_TYPE,
  ErrorTableHeaders,
  PublishModuleDD,
} from "../../Common/Constants";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import { DownLoadSampleTemplate } from "../../Common/DownloadSampleTemplate";
import FilterAndSort from "../../Common/FilterAndSort";
import PageTitle from "../../Common/PageTitle";
import AddNewContentModal from "../../Common/Popup";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import { IRequestBody } from "../../Constants/interfaces";
import { TriggerToastMessage } from "../../Utils/toastTrigger";
import BulkUploadModal from "../RetailersAndNurseries/Modal/BulkUploadModal";
import KnowMorePopup from "./../../Common/KnowMoreErrorModal/knowMoreDetails";
import { GetErrorList } from "../Crop/CropsAPICalls";
import { CPCreateEdit } from "./AddCpProduct";
import { CpProductsDataList, CpProductsMyUploads } from "./CpProductColumnDef";
import {
  DeletePublishedCP,
  GetDataList,
  GetMyUploadList,
} from "./CPprouctsAPICalls";
import { useStyles } from "./style";
import ViewCpProducts from "./ViewCpProducts/ViewCpProducts";
import { formatDate } from "../../Utility/DateFormat";
import { removeSpacesImagePath } from "../../Utils/removeSpaceImagePath";
import { ConvertJSONToXLS } from "../../Utils/fileHelper";
import _ from "../../Utils/lodash";
import { api_service } from "../../Api/api_service";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { checkExportToExcelAllowed } from "../../Api/generic_apicalls";
import downloadZipFile, {
  DownloadZipParamsType,
} from "../../Utils/downloadZip";
import { useDownloadProgressContext } from "../../Common/ProgressProvider";
import cropImageMedia from "../../Utils/cropMedia";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: ITabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const INITIAL_FILTER_STATE = {
  languageIds: [],
  status: [],
  crop: [],
  category: [],
};

export const CpProductsList = (props: any) => {
  const { addDownloadProgress } = useDownloadProgressContext();

  const storeData = useSelector((state: RootState) => ({
    countryCode: state.auth.countryCode,
    languages: state.generic?.ListOfLanguages?.languageList,
  }));
  const [activeTab, setActiveTab] = useState<number>(0);
  const [value] = useState(0);
  const [dataToDisplay, setDataToDisplay] = useState<any>([]);
  const [dataToDisplayIsChecked, setDataToDisplayIsChecked] = useState<
    Array<any>
  >([]);

  const [showViewModal, setShowViewModal] = useState(false);

  const [limit, setLimit] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchedValue, setSearchedValue] = useState<string | undefined>("");
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filterObj, setFilterObj] = useState(INITIAL_FILTER_STATE);
  const [sortId, setSortId] = useState("1");

  const [selectedCPInfo, setSelectedCPInfo] = useState<any>("");
  const [showBulkUploadModal, toggleBulkUploadView] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [actionType, setActionType] = useState<string>("");
  const [showCropModal, toggleNewCropModal] = useState<boolean>(false);
  const [selectedCropInfo, setSelectedCropInfo] = useState<any>("");
  const [myUploadData, setMyUploadData] = useState<any | undefined>(undefined);
  const [openKnowMore, setOpenKnowMore] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<Array<any> | undefined>(undefined);

  const [buttonExportVisible, setButtonExportVisible] =
    useState<boolean>(false);
  /** Tab change handler */
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setFilterObj(INITIAL_FILTER_STATE);
    setSortId("1");
    setSearchedValue("");
    setCurrentPage(0);
    setActiveTab(newValue);
    setDataToDisplayIsChecked([]);
  };

  // Select Checkbox
  // const handleSelectAll = (status: boolean) => {
  //   AssignCheckedFlag(dataToDisplay, status);
  // };

  const selectEachByRow = (check: boolean, rowData: any) => {
    checkOrUncheckRow(rowData, check);
  };

  const getCropNameFromImageUrl = (imageUrl: string) => {
    const splittedText = imageUrl.split("/");
    return splittedText.includes("admin-portal")
      ? splittedText[5]
      : splittedText[4];
  };

  //check or uncheck row
  const checkOrUncheckRow = (data: any, status: boolean) => {
    const checkedItems = dataToDisplayIsChecked.flatMap((item) =>
      item.data.filter((itemData: any) => itemData.checked)
    );

    const isValid =
      checkedItems.length === 0 ||
      checkedItems.some((itemData: any) => {
        const cropName = getCropNameFromImageUrl(data.image);
        const itemCropName = getCropNameFromImageUrl(itemData.image);
        return cropName === itemCropName;
      });

    if (isValid) {
      const filterData: any = dataToDisplay.map((isdata: any) => {
        if (
          isdata.id === data.id &&
          isdata.languageName === data.languageName
        ) {
          isdata.checked = status;
        }

        return isdata;
      });

      setDataToDisplay(filterData);
      filterDataBasedOnPageChecked(currentPage, filterData);
    } else {
      TriggerToastMessage(
        "You are selecting a CP Product from a different crop. Please select from the same crop!",
        "warn"
      );
    }
  };

  // const AssignCheckedFlag = (data: any, status: boolean) => {
  //   const filterData: any = data.map((isdata: any) => {
  //     isdata.checked = status;
  //     return isdata;
  //   });
  //
  //   filterDataBasedOnPageChecked(currentPage, filterData);
  // };

  // Filter data based on page checked
  const filterDataBasedOnPageChecked = (page: number, data: any) => {
    if (dataToDisplayIsChecked.length < 1) {
      setDataToDisplayIsChecked([
        {
          page: page,
          data: data,
        },
      ]);
      return;
    }

    const checkPageIsExist = dataToDisplayIsChecked.some(
      (oldData) => oldData.page === page
    );
    if (checkPageIsExist) {
      const filterData: any = dataToDisplayIsChecked.map((isdata: any) => {
        if (isdata.page === page) {
          return {
            page,
            data,
          };
        }
        return isdata;
      });
      setDataToDisplayIsChecked(filterData);
      return;
    }

    setDataToDisplayIsChecked([
      ...dataToDisplayIsChecked,
      {
        page: page,
        data: data,
      },
    ]);
  };

  const replaceDataAlreadyExistWithChecked = () => {
    if (dataToDisplayIsChecked.length < 1) return;

    const dataCheckedPage = dataToDisplayIsChecked.filter((data) => {
      return data.page === currentPage;
    });
    if (currentPage === dataCheckedPage?.[0]?.page) {
      setDataToDisplay(dataCheckedPage?.[0]?.data);
    }
  };

  const convertMatrictToArray = () => {
    const data = dataToDisplayIsChecked.map((data) => {
      return data.data;
    });

    const dataFromOtherPage = dataToDisplayIsChecked.some((data) => {
      if (data.page !== currentPage) {
        return data.data.some((data: any) => data.checked === true);
      }
      return false;
    });

    return {
      data: data.flat(),
      dataFromOtherPage,
    };
  };

  const getDetailData = (data: any) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.get_cp_by_sys_nm;
    apiData.endPoint = endPoint.replace("__SYSTEM_NAME__", `${data.system}`);
    apiData.showLoader = true;
    /** payload */
    apiData.customHeaders = {
      "language-code":
        storeData.languages.find((lang) => lang.languageId === data.languageId)
          ?.languageCode ?? "en",
    };

    return new Promise((resolve, reject) => {
      api_service
        .get(apiData)
        ?.then((response: any) => {
          if (response.status === 500) {
            TriggerToastMessage("Unable to process your request", "error");
          }

          resolve(response.data.data);
        })
        .catch((error) => reject(error));
    });
  };

  // Export to excel
  const handleExportToExcel = async () => {
    const { data } = convertMatrictToArray();

    const selectedCpProductInfo = _.filter(data, { checked: true });

    const publishPendingStatus = selectedCpProductInfo.some(
      (value: any) => value.statusId === 5
    );

    if (publishPendingStatus) {
      const confirmationExport = confirm(
        "Exporting draft record for 'Publish Pending' item"
      );
      if (!confirmationExport) return;
    }

    const allData = await Promise.all(
      selectedCpProductInfo.map((data: any) => getDetailData(data))
    );

    const doAndDontData: any[] = [];
    const keyFeaturesData: any[] = [];
    const growerTestimonialsData: any[] = [];
    const threatStageMappingData: any[] = [];
    const calendarTextTranslations: any[] = [];
    const calendarCropRecommendationData: any[] = [];
    const technicalInformationData: any[] = [];

    allData.forEach((data: any) => {
      technicalInformationData.push({
        cpSystemName: data.payload.productSystemName,
        language: data.languageName,
        technicalName: data.payload.applicableCrop,
      });
      data.payload.dos.forEach((dos: any) =>
        doAndDontData.push({
          ...dos,
          language: data.languageName,
          cpSystemName: data.payload.productSystemName,
          type: "DO",
        })
      );
      data.payload.donts.forEach((donts: any) =>
        doAndDontData.push({
          ...donts,
          language: data.languageName,
          cpSystemName: data.payload.productSystemName,
          type: "DONT",
        })
      );

      data.payload.keyFeatures.forEach((keyFeatures: any) =>
        keyFeaturesData.push({
          ...keyFeatures,
          language: data.languageName,
          cpSystemName: data.payload.productSystemName,
        })
      );
      data.payload.growerTestimonials.forEach((growerTestimonials: any) =>
        growerTestimonialsData.push({
          ...growerTestimonials,
          language: data.languageName,
          cpSystemName: data.payload.productSystemName,
        })
      );

      const existingThreatStage = threatStageMappingData.find(
        (stage) => stage.cpSystemName === data.payload.productSystemName
      );
      if (!existingThreatStage) {
        data.payload.threatStageMapping.forEach((threatStageMapping: any) => {
          if (threatStageMapping.stageThreatDisplayOrders) {
            threatStageMapping.stageThreatDisplayOrders.forEach(
              (stage: any) => {
                threatStageMappingData.push({
                  cpSystemName: data.payload.productSystemName,
                  systemName: stage.threatSystemName,
                  stage: stage.stageSystemName,
                  displayOrder: stage.cpDisplayOrder,
                  cpThreatDisplayOrder: stage.cpThreatDisplayOrder,
                });
              }
            );
          } else {
            threatStageMapping.stages?.forEach((stage: any) => {
              threatStageMappingData.push({
                cpSystemName: data.payload.productSystemName,
                systemName: threatStageMapping.systemName,
                stage: stage,
                displayOrder: 0,
                cpThreatDisplayOrder: 0,
              });
            });
          }
        });
      }

      data.payload.recommendations.forEach(
        (recommendation: any, index: number) => {
          const indexSuffix = index + 1;
          const reminderTranslationName = `REMINDER_MSG_${data.payload.productSystemName}${indexSuffix}`;
          const whyToUseTranslationName = `WHY_TO_USE_${data.payload.productSystemName}${indexSuffix}`;

          const translation = (
            translationSystemName: string,
            type: string,
            text: string
          ) => ({
            translationSystemName,
            type,
            text,
            language: data.languageName,
          });

          calendarTextTranslations.push(
            translation(
              reminderTranslationName,
              "REMINDER",
              recommendation.reminderMessage
            )
          );
          calendarTextTranslations.push(
            translation(
              whyToUseTranslationName,
              "WHYTOUSE",
              recommendation.whyToUse
            )
          );

          const existingData = calendarCropRecommendationData.find(
            (data: any) =>
              data.whyToUse === whyToUseTranslationName ||
              data.reminderMessage === reminderTranslationName
          );

          if (!existingData) {
            calendarCropRecommendationData.push({
              ...recommendation,
              whyToUse: whyToUseTranslationName,
              reminderMessage: reminderTranslationName,
              cpSystemName: data.payload.productSystemName,
            });
          }
        }
      );
    });

    if (selectedCpProductInfo.length === 0) {
      TriggerToastMessage("Please select atleast one line to export", "warn");
      return;
    }

    const MAX_RETRY_COUNT = 3;

    const downloadWithRetry = (
      params: DownloadZipParamsType,
      retryCount: number = 0
    ) => {
      downloadZipFile({
        ...params,
        onErrorDownload: (message) => {
          addDownloadProgress({
            module: "CP Products",
            downloadName:
              selectedCpProductInfo[0].crop +
              " - " +
              selectedCpProductInfo
                .map((info: any) => info.system)
                .filter(
                  (systemName: string, index: number, self: string[]) =>
                    index === self.indexOf(systemName)
                )
                .join(", "),
            status: "Error",
            message: message ?? "Something went wrong",
            canRefetch: true,
            onRefetchCallback: () => {
              if (retryCount < MAX_RETRY_COUNT) {
                downloadWithRetry(params, retryCount + 1);
              } else {
                addDownloadProgress({
                  module: `CP Products`,
                  downloadName:
                    selectedCpProductInfo[0].crop +
                    " - " +
                    selectedCpProductInfo
                      .map((info: any) => info.system)
                      .filter(
                        (systemName: string, index: number, self: string[]) =>
                          index === self.indexOf(systemName)
                      )
                      .join(", "),
                  status: "Error",
                  message: `Maximum numbers of ${MAX_RETRY_COUNT} retries has been reached`,
                  canRefetch: false,
                });
              }
            },
          });
        },
      });
    };

    downloadWithRetry({
      systemNames: ["cp"],
      path: `admin-portal/${storeData.countryCode}/${getCropNameFromImageUrl(
        selectedCpProductInfo[0].image
      )}/`,
      folder: `CP Products`,
      onDownloadProgress: (ProgressEvent: any) => {
        const totalDownloaded = ProgressEvent.loaded;
        const totalSize = ProgressEvent.total;
        const progress = Math.round((totalDownloaded / totalSize) * 100);

        if (ProgressEvent.target?.status === 200) {
          addDownloadProgress({
            module: "CP Products",
            downloadName:
              selectedCpProductInfo[0].crop +
              " - " +
              selectedCpProductInfo
                .map((info: any) => info.system)
                .filter(
                  (systemName: string, index: number, self: string[]) =>
                    index === self.indexOf(systemName)
                )
                .join(", "),
            totalDownloaded,
            totalSize,
            progress,
            status: "Downloading",
          });
        }
      },
      onPreparingDownload: () => {
        addDownloadProgress({
          module: "CP Products",
          downloadName:
            selectedCpProductInfo[0].crop +
            " - " +
            selectedCpProductInfo
              .map((info: any) => info.system)
              .filter(
                (systemName: string, index: number, self: string[]) =>
                  index === self.indexOf(systemName)
              )
              .join(", "),
          status: "Preparing",
          message: "Preparing to Download Zip File",
        });
      },
    });

    const columnsData = [
      {
        label: "CP SystemName",
        value: "payload.productSystemName",
      },
      {
        label: "Language",
        value: "languageName",
      },
      {
        label: "ProductName",
        value: "payload.productTitle",
      },
      {
        label: "Category",
        value: "payload.category",
      },
      {
        label: "Image Path",
        value: (row: any) => cropImageMedia(row.payload.imagePath, storeData),
      },
      {
        label: "dosage",
        value: "payload.dosage",
      },
      {
        label: "HowToUse",
        value: "payload.howToUse",
      },
      {
        label: "WhereToUse",
        value: "payload.whenToUse",
      },
      {
        label: "Key Benefit 1",
        value: "payload.benefit1",
      },
      {
        label: "Key Benefit 2",
        value: "payload.benefit2",
      },
      {
        label: "Key Benefit 3",
        value: "payload.benefit3",
      },
    ];

    const columnDoAndDont = [
      {
        label: "CP SystemName",
        value: "cpSystemName",
      },
      {
        label: "Language",
        value: "language",
      },
      {
        label: "GuideLine System Name",
        value: "systemName",
      },
      {
        label: "Type",
        value: "type",
      },
      {
        label: "Text",
        value: "text",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnFeatures = [
      {
        label: "CP SystemName",
        value: "cpSystemName",
      },
      {
        label: "Language",
        value: "language",
      },
      {
        label: "Feature System Name",
        value: "systemName",
      },
      {
        label: "Feature Heading",
        value: "header",
      },
      {
        label: "Feature Text",
        value: "description",
      },
      {
        label: "Feature Image Path",
        value: (row: any) => cropImageMedia(row.imagePath, storeData),
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
    ];

    const columnGrowersTestimonials = [
      {
        label: "CP SystemName",
        value: "cpSystemName",
      },
      {
        label: "Language",
        value: "language",
      },
      {
        label: "GrowerTestimonialSystemName",
        value: "systemName",
      },
      {
        label: "GrowerTestimonialText",
        value: "text",
      },
      {
        label: "MediaUrl",
        value: "mediaUrl",
      },
      {
        label: "Display Order",
        value: "displayOrder",
      },
      {
        label: "GrowerTestimonialSubtext",
        value: "subtext",
      },
    ];

    const columnThreatStageMapping = [
      {
        label: "CP SystemName",
        value: "cpSystemName",
      },
      {
        label: "Threat System Name",
        value: "systemName",
      },
      {
        label: "Stage System Name",
        value: "stage",
      },
      {
        label: "Display Order",
        value: (row: any) => row.displayOrder ?? 0,
      },
      {
        label: "CP Threat Display Order",
        value: (row: any) => row.cpThreatDisplayOrder ?? 0,
      },
    ];

    const columnCropCalendarRecommendation = [
      {
        label: "Calendar System Name",
        value: "calendarSystemName",
      },
      {
        label: "Stage System Name",
        value: "stageSystemName",
      },
      {
        label: "Product System Name",
        value: "cpSystemName",
      },
      {
        label: "Threat System Name",
        value: "threatSystemName",
      },
      {
        label: "Dosage",
        value: "dosage",
      },
      {
        label: "When to Use",
        value: "whenToUse",
      },
      {
        label: "Why to Use",
        value: "whyToUse",
      },
      {
        label: "Reminder Message System Name",
        value: "reminderMessage",
      },
      {
        label: "Application Order",
        value: "applicationOrder",
      },
    ];

    const columnCalendarTranslation = [
      {
        label: "Translation System Name",
        value: "translationSystemName",
      },
      {
        label: "Type",
        value: "type",
      },
      {
        label: "Translated Text",
        value: "text",
      },
      {
        label: "Language",
        value: "language",
      },
    ];

    const columnTechnicalInformation = [
      {
        label: "CP SystemName",
        value: "cpSystemName",
      },
      {
        label: "Language",
        value: "language",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Manufacturer",
        value: "manufacturer",
      },
      {
        label: "Technical Name",
        value: "technicalName",
      },
      {
        label: "Product Code",
        value: "productCode",
      },
      {
        label: "Chemical Group",
        value: "productCode",
      },
      {
        label: "Description",
        value: "productCode",
      },
      {
        label: "Target Pest",
        value: "productCode",
      },
      {
        label: "Crop",
        value: "[productCode]",
      },
      {
        label: "Dosage/Acre",
        value: "productCode",
      },
    ];

    const dataObj = [
      {
        columns: columnsData,
        content: allData,
        sheet: "General Info",
      },
      {
        columns: columnDoAndDont,
        content: doAndDontData,
        sheet: "Do's & Donts",
      },
      {
        columns: columnFeatures,
        content: keyFeaturesData,
        sheet: "Features",
      },
      {
        columns: columnTechnicalInformation,
        content: technicalInformationData,
        sheet: "Technical Information",
      },
      {
        columns: columnGrowersTestimonials,
        content: growerTestimonialsData,
        sheet: "Grower Testimonials",
      },
      {
        columns: columnThreatStageMapping,
        content: threatStageMappingData,
        sheet: "Product-Threats-Stages",
      },
      {
        columns: columnCalendarTranslation,
        content: calendarTextTranslations,
        sheet: "Calendar Text Translations",
      },
      {
        columns: columnCropCalendarRecommendation,
        content: calendarCropRecommendationData,
        sheet: "Crop Calendar Recommendation",
      },
    ];
    let settings = {
      fileName: "cpProductInfo_" + new Date().getTime(), // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    ConvertJSONToXLS(dataObj, settings);
  };


  const successCallBack = (response: any, type: string) => {
    switch (type) {
      case "data_list": {
        /** formatting data list table data */
        let listData: any = response.data;
        setTotalPages(listData?.totalPages);
        let dataListToDisplay: any = [];
        listData.productsDtoList.forEach((row: any) => {
          /**updating CP products data according to table columns */
          dataListToDisplay.push({
            id: row.productId,
            title: row.productTitle,
            languageName: row.languageName,
            languageId: row.languageId,
            system: row.productSystemName,
            crop: getCropNameFromImageUrl(
              removeSpacesImagePath(row.productSystemName, row.productMediaPath)
            ),
            image: removeSpacesImagePath(
              row.productSystemName,
              row.productMediaPath
            ),
            lastUpdatedOn: row.lastUpdatedOn
              ? formatDate(row.lastUpdatedOn)
              : "--",
            statusId: row.statusId,
          });
        });
        setDataToDisplay(dataListToDisplay);
        break;
      }
      case "upload_list": {
        /** formatting upload list table data */
        let listData: any = response.data;
        console.log('listData', listData)
        setTotalPages(Math.ceil(listData.totalElements / limit));
        let myUploadListToDisplay: any = [];
        listData.uploadDocumentList.forEach((row: any) => {
          /**updating CP products data according to table columns */
          myUploadListToDisplay.push({
            id: row.id,
            fileName: row.fileName,
            lastUpdatedDate: moment(row?.lastUpdatedDate)
              .add(moment().utcOffset(), "m")
              .format("YYYY-MM-DD hh:mm a"),
            comnMyUploadStatus: row.status,
          });
        });
        setDataToDisplay(myUploadListToDisplay);
        break;
      }
      case "error_list": {
        if (response.statusCode === 200) {
          setErrorList(response.data);
        }
        break;
      }
      case "delete_cp": {
        if (response && response.statusCode === 200) {
          TriggerToastMessage(
            response.message
              ? response.message
              : "CP Product deleted successfully",
            "success"
          );
        } else {
          TriggerToastMessage(
            response.message ? response.message : "CP Product deletion failed",
            "error"
          );
        }
        return fetchDataList();
      }
    }
  };
  /**
   * @description A callback function triggered when an api is failed
   */
  const errorCallback = (_: any, type: string) => {
    switch (type) {
      default: {
        TriggerToastMessage("Unable to process your request", "error");
      }
    }
  };
  const fetchDataList = () => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.payLoad = {
      pageNo: currentPage,
      pageSize: limit,
    };
    /**search payload */
    if (searchedValue) {
      apiData.payLoad.term = searchedValue.toUpperCase();
    }
    /** Filtered data to api payload*/
    if (filterObj.languageIds.length > 0) {
      apiData.payLoad.languageIds = filterObj.languageIds
        .map((filterLngObj: any) => {
          return filterLngObj.split(":::")[1];
        })
        .join(",");
    }
    /** sort in payload */
    if (sortId) {
      apiData.payLoad.sort = sortId === "1" ? "desc" : "asc";
    }
    apiData.endPoint = api_endpoints.get_cp_list;
    apiData.showLoader = true;

    GetDataList(apiData, successCallBack, errorCallback);
  };
  const fetchMyUploads = () => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.payLoad = {
      pageNo: currentPage,
      pageSize: limit,
      dataType: PublishModuleDD.CP_PRODUCTS,
      sortId: sortId,
    };
    // apiData.endPoint = api_endpoints.get_cp_upload_list;
    let endPoint: string = api_endpoints.get_cp_upload_list;
    /**search payload */
    if (searchedValue) {
      apiData.payLoad.searchKey = searchedValue.toLocaleLowerCase();
    }
    apiData.endPoint = endPoint;
    apiData.showLoader = true;

    GetMyUploadList(apiData, successCallBack, errorCallback);
  };
  const getErrorListData = (id: number) => {
    let apiData = {} as IRequestBody;
    apiData.domain = base_url;
    let endPoint: string = api_endpoints.bulkupload_error_list;
    apiData.endPoint = endPoint.replace("__ID__", `${id}`);
    apiData.showLoader = true;
    /** payload */
    GetErrorList(apiData, successCallBack, errorCallback);
  };
  const limitHandler = (value: number) => {
    setCurrentPage(0);
    setLimit(value);
  };
  const paginationHandler = (selectedPageNo: number) => {
    setCurrentPage(selectedPageNo - 1);
  };
  const resetTable = () => {
    switch (activeTab) {
      case 0: {
        fetchDataList();
        break;
      }
      case 1: {
        fetchMyUploads();
        break;
      }
    }
  };
  /** handle search */
  const handleSearch = () => {
    setCurrentPage(0);
    resetTable();
  };
  const showCreateCropModal = (flag: boolean) => {
    setActionType(ActionType.CREATE);
    setOpen(false);
    toggleNewCropModal(flag);
    setSelectedCropInfo("");
  };
  const showBulkUploadPopup = () => {
    setOpen(false);
    toggleBulkUploadView(true);
  };
  const handleKnowMorePopup = (flag: boolean, rowData: any) => {
    setOpenKnowMore(flag);
    /** API call to get Error list */
    getErrorListData(rowData.id);
    /** using this state variable to store file name and pass it
     * to the ErrorListPopup
     */
    setMyUploadData(rowData);
  };
  const handleBulkClose = (currentTab: number) => {
    toggleBulkUploadView(false);
    setMyUploadData(undefined);
    if (currentTab) {
      setActiveTab(currentTab);
      fetchMyUploads();
    }
  };
  const handleDeleteCP = (productSystemName: string) => {
    if (
      confirm(
        `Are you sure to delete this record for ${productSystemName}? \n Plantix Mapping will also get deleted`
      )
    ) {
      let apiData = {} as IRequestBody;
      apiData.domain = base_url;
      let endPoint: string = api_endpoints.delete_published_cp;
      apiData.endPoint = endPoint.replace(
        "__PROD__SYS__NM__",
        productSystemName
      );
      apiData.showLoader = true;
      return DeletePublishedCP(apiData, successCallBack, errorCallback);
    }
  };
  const handleActionClick = (action: string, details?: any) => {
    setActionType(action);
    switch (action) {
      case ActionType.CREATE:
        setOpen(true);
        return;
      case ActionType.UPDATE:
        toggleNewCropModal(true);
        return setSelectedCropInfo(details);
      case ActionType.ADD_TRANSLATION:
        toggleNewCropModal(true);
        return setSelectedCropInfo(details);
      case "Delete":
        return handleDeleteCP(details.system);
      case ActionType.VIEW:
        setSelectedCPInfo(details);
        return setShowViewModal(true);
      case "Back":
        return props.onRefresh();
      default:
        handleExportToExcel();
        return;
    }
  };
  const renderAddCP_Popup = () => {
    return (
      <AddNewContentModal
        title={"Add New Data"}
        isOpen={open}
        onModalClose={() => setOpen(false)}
        onAddNew={() => {
          showCreateCropModal(true);
        }}
        onBulkUpload={() => {
          showBulkUploadPopup();
        }}
      />
    );
  };
  const renderCreateEdit = () => {
    return (
      <>
        <CPCreateEdit
          showCreateModal={showCropModal}
          isEdit={!!selectedCropInfo.id}
          id={selectedCropInfo.id}
          lngId={selectedCropInfo.languageId}
          sysNm={selectedCropInfo.system}
          actionType={actionType}
          onClose={() => {
            fetchDataList();
            toggleNewCropModal(false);
          }}
        />
      </>
    );
  };
  const renderBulkUploadForm = () => {
    return (
      <>
        <BulkUploadModal
          allowedTypes={["xls", "xlsx"]}
          maxFileSize={5}
          onClose={handleBulkClose}
          validationLiteral={PublishModuleDD.CP_PRODUCTS}
          templateLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.CP}
          canByPassValidation={false}
          hasCropDD={true}
        />
      </>
    );
  };

  const renderKnowMoreModal = () => {
    return (
      <>
        <KnowMorePopup
          handleKnowMoreClose={(flag: boolean) => {
            setOpenKnowMore(flag);
            setMyUploadData(undefined);
          }}
          title={myUploadData && myUploadData.fileName}
          tableHeaders={ErrorTableHeaders}
          tableData={errorList}
          openBulkUploadPopup={() => {
            toggleBulkUploadView(true);
            setOpenKnowMore(false);
          }}
        />
      </>
    );
  };
  const renderViewModal = () => {
    return (
      <>
        <ViewCpProducts
          onCloseViewCrop={() => setShowViewModal(false)}
          sysNm={selectedCPInfo.system}
          id={selectedCPInfo.id}
          lngId={selectedCPInfo.languageId}
          canShowEditButton={activeTab === 0}
          onEditClick={(clickAction: string, details: any) =>
            handleActionClick(clickAction, details)
          }
        />
      </>
    );
  };
  useEffect(() => {
    setDataToDisplay([]);
    resetTable();
  }, [activeTab, limit, currentPage, filterObj, sortId]);
  /**for search */

  useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
      return;
    }
    handleSearch();
  }, [searchedValue]);

  useEffect(() => {
    setDataToDisplayIsChecked([]);
  }, [limit]);

  useEffect(() => {
    if (dataToDisplay.length < 1) return;
    replaceDataAlreadyExistWithChecked();
  }, [currentPage, dataToDisplay]);

  useEffect(() => {
    checkExportToExcelAllowed("CP Products")?.then((res) =>
      setButtonExportVisible(res)
    );
  }, []);

  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <div>
          <Stack
            direction="row"
            alignItems="center"
            className={classes.pageTitleNSearchStylesHelper}
          >
            <PageTitle title="CP Products" />
            <SearchBar
              placeholderText={`Search by ${
                activeTab === 0 ? "Title, System Name" : "Filename"
              }`}
              searchValue={searchedValue || ""}
              onSearchValueChange={(newSearchValue: string) => {
                setSearchedValue(newSearchValue);
              }}
              onEnterClick={() => {}}
            />
          </Stack>
          <Box>
            <Box className={classes.customTabBtmBorder}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CustomTabs
                  value={activeTab}
                  tabsList={["Data List", "My uploads"]}
                  onChange={handleChange}
                />
                {activeTab === 1 && (
                  <DownLoadSampleTemplate
                    literal={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.CP}
                    displayAsButton={false}
                  />
                )}
              </Stack>
            </Box>
            <TabPanel value={value} index={0}>
              <Stack
                direction="row"
                className={classes.rowsPerPageStylesHelper}
              >
                <RowsPerPage
                  pagSize={5}
                  onChangeRowsPerPage={(value: number) => limitHandler(value)}
                />
                <FilterAndSort
                  sortBy={sortId}
                  onSort={(sortType: any) => setSortId(sortType?.toString())}
                  onChangeFilters={(data: any) => {
                    setFilterObj(data);
                  }}
                  showLanguageSelection={activeTab === 0}
                  filters={{ ...filterObj }}
                  // showCategoryList={activeTab === 0}
                  showLastUpdatedOn={true}
                  isResetFilter={`${activeTab}`}
                  hasFilter={activeTab === 0}
                />
              </Stack>
              {/* Table view */}
              <DataTable
                actionsType={ACTION_TYPE.TYPE_CROP_PROTECTION}
                columns={
                  activeTab === 0 ? CpProductsDataList : CpProductsMyUploads
                }
                data={dataToDisplay}
                enableSelection={activeTab === 0 && buttonExportVisible}
                singleSelection={activeTab === 0 && buttonExportVisible}
                pageNumber={1}
                onPageChange={props.onPageChange}
                onSelectAll={() => {}}
                onDeselectAll={() => {}}
                onRowSelect={selectEachByRow}
                onActionClick={handleActionClick}
                isEditEnabled={activeTab === 0}
                isViewButton={activeTab === 0}
                isDeleteEnabled={activeTab === 0}
                hasTranslation={activeTab === 0}
                languageKey="languageName"
                handleKnowMorePopup={(flag: boolean, rowData: any) =>
                  handleKnowMorePopup(flag, rowData)
                }
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {/* Bottom of Table view: like Add CP product plus icon */}
                <DataTableActions
                  actionsType={ACTION_TYPE.TYPE_CP_PRODUCT}
                  pageNumber={currentPage + 1}
                  onPageChange={(selectedPageNo: number) =>
                    paginationHandler(selectedPageNo)
                  }
                  buttonVisible={activeTab === 0}
                  buttonExportVisible={activeTab === 0 && buttonExportVisible}
                  onActionClick={(data: string) => handleActionClick(data)}
                  activeTab={activeTab}
                  totalpages={totalPages}
                  canShowAvatar={activeTab === 0}
                />
              </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}></TabPanel>
          </Box>
        </div>
      </TableContainer>

      {/* ------------------------- Manual/Bulk upload Modal--------------------------------------- */}
      {renderAddCP_Popup()}
      {/* ---------------------------Add/Edit Data Modal---------------------------------------- */}
      {showCropModal && renderCreateEdit()}
      {/* -------------------------------View Modal---------------------------------------- */}
      {showViewModal && renderViewModal()}
      {/* ------------------------------ Bulk Upload Modal ---------------------------------------- */}
      {showBulkUploadModal && renderBulkUploadForm()}
      {/* ---------------------------------Uploaded Crop Error List Info ---------------------------------------- */}
      {openKnowMore && renderKnowMoreModal()}
    </>
  );
};

export default CpProductsList;
