import { FilterAltOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FilterPropertyType } from "../../FilterAndSort/type";
import ClearAndApplyButtons from "../ClearAndApplyButtons";
import MenuPopup from "../MenuPopup";
import Language from "./Language";

export const CustomBadges: React.FC<{ count?: number }> = ({ count = 0 }) => {
  return (
    <Stack direction={"row"}>
      <FilterAltOutlined />
      <Box
        data-testid="filter-count"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#CF3537",
          paddingInline: "0.8em",
          color: "#fff",
          fontSize: "12px",
          fontWeight: "600",
          borderRadius: "0.8em",
        }}
      >
        {count}
      </Box>
    </Stack>
  );
};

export type FilterProps = {
  initialFilter?: Partial<FilterPropertyType>;
  filters: Partial<FilterPropertyType>;
  onChangeFilters?: (filters: Partial<FilterPropertyType>) => void;
};

const Filter: React.FC<FilterProps> = (props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [tempFilter, setTempFilter] = useState<Partial<FilterPropertyType>>({});
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFilters = (filters: Partial<FilterPropertyType>) => {
    setTempFilter(filters);
  };

  const handleResetFilters = () => {
    props.onChangeFilters?.(props.initialFilter ?? {});
    handleClose();
  };

  const handleApplyFilters = () => {
    props.onChangeFilters?.(tempFilter);
    handleClose();
  };

  const renderFilters = (filters: Partial<FilterPropertyType>) => {
    const renderedFilters = [];

    if (filters.languageIds)
      renderedFilters.push(
        <Language
          key="language"
          filters={tempFilter}
          onChangeFilters={handleChangeFilters}
        />
      );

    return renderedFilters;
  };

  const countAppliedFilters = Object.values(props.filters)
    .filter((filter) => Array.isArray(filter) && filter.length > 0)
    .flat().length;

  useEffect(() => {
    setTempFilter(props.filters);
  }, [props.filters]);

  return (
    <>
      <Button
        color="inherit"
        sx={{
          textTransform: "unset !important" as "unset",
          alignItems: "center",
        }}
        endIcon={<CustomBadges count={countAppliedFilters} />}
        ref={buttonRef}
        onClick={handleClick}
      >
        <Typography variant="subtitle2">Filters</Typography>
      </Button>
      <MenuPopup anchorEl={buttonRef.current} onClose={handleClose} open={open}>
        <Box sx={{ display: "flex", flexDirection: "column", minWidth: 300 }}>
          {renderFilters(props.filters)}
          <Divider />
          <ClearAndApplyButtons
            onClickClear={handleResetFilters}
            onClickApply={handleApplyFilters}
          />
        </Box>
      </MenuPopup>
    </>
  );
};

export default Filter;
