export type QueryColumnType = {
  fieldName: string;
  fieldLabel: string;
};

export const QueryColumn: Array<QueryColumnType> = [
  {
    fieldLabel: "Query ID",
    fieldName: "queryId",
  },
  {
    fieldLabel: "Crop",
    fieldName: "name",
  },
  {
    fieldLabel: "Category",
    fieldName: "category",
  },
  {
    fieldLabel: "Phone No.",
    fieldName: "phoneNumber",
  },
  {
    fieldLabel: "User Message",
    fieldName: "message",
  },
  {
    fieldLabel: "User Media (Image/Audio)",
    fieldName: "media",
  },
  {
    fieldLabel: "Received on",
    fieldName: "createdDate",
  },
  {
    fieldLabel: "Aging Days",
    fieldName: "agingDays",
  },
  {
    fieldLabel: "Status",
    fieldName: "status",
  },
  {
    fieldLabel: "User Rating",
    fieldName: "rating",
  },
  // {
  //   fieldLabel: "Action",
  //   fieldName: "action",
  // },
];
