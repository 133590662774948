import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Link, Rating, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { CropsTableColumnType } from "../../Components/Crop/CropsColumnsData";
import { LanguageTableColumnType } from "../../Components/Languages/LanguageColumnsData";
import { PushNotificationColumnType } from "../../Components/PushNotifications/columnsData";
import { QueryColumnType } from "../../Components/QueryResponse/columnsData";
import MyLoader from "../../Components/SkeletonComponent/Skeleton";
import { ASSETS_PATH } from "../../Constants/contants";
import { RootState } from "../../redux/reducers";
import { ACTION_TYPE } from "../RenderTableActions";
import CustomSwitch from "../Switch";
import Chip from "./../../Common/Chip/";
import { ActionType } from "../Constants";
import {
    ReadOnlyBadge_Cancelled,
    ReadOnlyBadge_Created,
    ReadOnlyBadge_Published,
    useStyles,
} from "./styles";
import getRandomizeValue from "../../Utils/randomizer";
import extractVideoIdFromUrl from "../../Utils/extractVideoYt";
import YouTube from 'react-youtube';

type DataTableProps = {
    actionsType?: ACTION_TYPE;
    columns:
    | Array<PushNotificationColumnType>
    | Array<LanguageTableColumnType>
    | Array<CropsTableColumnType>
    | Array<QueryColumnType>;
    data: Array<any>;
    dropDownValue?: any;
    enableSelection?: boolean;
    singleSelection?: boolean;
    onActionClick?: (
        action: string,
        details?: any,
        optionalData?: string | any
    ) => void;
    onDeselectAll: Function;
    onPageChange: Function;
    onRowSelect: Function;
    onSelectAll: Function;
    pageNumber: number;
    showActionProgress?: boolean;
    isPublishedEnabled?: boolean;
    isEditEnabled?: boolean;
    isDeleteEnabled?: boolean;
    isStatusBadge?: boolean;
    isViewButton?: boolean;
    isRespondButton?: boolean;
    isDownLoadButton?: boolean;
    isToggleEnabled?: boolean;
    onStatusChanged?: Function;
    languageKey?: string;
    hasTranslation?: boolean;
    selectedPublishModule?: string;
    handleKnowMorePopup?: Function;
    isActionTitleEnabled?: boolean;
};

const DataTable: React.FC<DataTableProps> = ({
    actionsType,
    enableSelection,
    singleSelection,
    columns,
    data,
    onSelectAll,
    onRowSelect,
    onActionClick,
    isPublishedEnabled,
    isEditEnabled,
    isDeleteEnabled,
    isStatusBadge,
    isViewButton,
    isDownLoadButton,
    onStatusChanged,
    isRespondButton,
    languageKey,
    hasTranslation,
    selectedPublishModule,
    handleKnowMorePopup,
    isActionTitleEnabled,
}) => {
    // classes
    const classes = useStyles();
    const storeData = useSelector((state: RootState) => {
        return {
            status: state.loader.showLoader,
            primaryLanguageCode: state.auth.languageCode,
            primaryLanguageName: _.filter(
                state.generic.ListOfLanguages.languageList,
                { languageCode: state.auth.languageCode }
            )[0],
        };
    });
    // States
    const { useState } = React;
    const [, setSelectAllChecked] = useState<boolean>(false);
    const GetColumn = (
        index: number,
        fieldName: string,
        value: string | any,
        row: any = ""
    ) => {
        switch (fieldName) {
            case 'message':
                return (
                    <TableCell key={`${fieldName}-${index}`}>
                        <div className={classes.textWordBreakHelper} dangerouslySetInnerHTML={{__html: value}}></div>
                    </TableCell>
                );
            case "status":
                if (actionsType === ACTION_TYPE.TYPE_QUERY) {
                    return <TableCell key={`${fieldName}-${index}`}>{value}</TableCell>;
                } else {
                    return (
                        <TableCell key={`${fieldName}-${index}`}>
                            <Chip label={value?.toLowerCase()} type={value} />
                        </TableCell>
                    );
                }
            case "state":
                return <TableCell key={`${fieldName}-${index}`}>{value}</TableCell>;
            case "language":
                return <TableCell key={`${fieldName}-${index}`}>{value}</TableCell>;
            case "ScheduleDate": {
                let data: Array<string> = value?.split("##");
                return data && data.length ? (
                    <TableCell key={`${fieldName}-${index}`}>
                        {
                            <>
                                Frequency: {data[0]} <br /> From: {data[1]} <br /> To:{" "}
                                {data[2] !== "null" ? data[2] : "--"}
                            </>
                        }
                    </TableCell>
                ) : (
                    "--"
                );
            }
            case "dashboardScheduleDate": {
                let data: Array<string> = value?.split("##");
                return data && data.length ? (
                    <TableCell key={`${fieldName}-${index}`}>
                        {
                            <>
                                From: {data[0]} <br /> To: {data[1] !== "null" ? data[1] : "--"}
                            </>
                        }
                    </TableCell>
                ) : (
                    "--"
                );
            }
            case "statusId": {
                return (
                    <TableCell>
                        {value === "Published" ? (
                            <ReadOnlyBadge_Published>Published</ReadOnlyBadge_Published>
                        ) : (
                            <ReadOnlyBadge_Cancelled>Cancelled</ReadOnlyBadge_Cancelled>
                        )}
                    </TableCell>
                );
            }
            case "pushStatus": {
                return (
                    <TableCell>
                        <ReadOnlyBadge_Created>Created</ReadOnlyBadge_Created>
                    </TableCell>
                );
            }
            case "image":
            case "imagePath":
                return (
                    <TableCell key={`${fieldName}-${index}`}>
                        <img alt={'path'} src={value} width="35px" />
                    </TableCell>
                );
            case "cropsUploadDate": {
                let data: Array<string> = value?.split("##");
                return data && data.length ? (
                    <TableCell key={`${fieldName}-${index}`}>
                        {
                            <>
                                {data[0]} <br /> {data[1]} <br />
                            </>
                        }
                    </TableCell>
                ) : (
                    "--"
                );
            }
            case "comnMyUploadStatus": {
                return (
                    <TableCell>
                        {value === "COMPLETED" ? (
                            <ReadOnlyBadge_Published>
                                Completed <CheckCircleRoundedIcon fontSize="small" />
                            </ReadOnlyBadge_Published>
                        ) : value === "FAILURE" ? (
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="start"
                            >
                                <ReadOnlyBadge_Cancelled>
                                    Failure <CloseRoundedIcon fontSize="small" />
                                </ReadOnlyBadge_Cancelled>
                                <Button
                                    variant="text"
                                    color="primary"
                                    data-testid="know-more-button"
                                    onClick={() =>
                                        handleKnowMorePopup && handleKnowMorePopup(true, row)
                                    }
                                >
                                    <Typography fontSize={12} variant="subtitle2">
                                        know more
                                    </Typography>
                                </Button>
                            </Stack>
                        ) : (
                            "--"
                        )}
                    </TableCell>
                );
            }
            case "languageColumnStatus": {
                return (
                    <TableCell>
                        <CustomSwitch
                            key={getRandomizeValue()}
                            status={!row.inActiveFlag}
                            id={`field-${fieldName}-${index}`}
                            onStatusChange={(isChecked: boolean) =>
                                onStatusChanged!(isChecked, row)
                            }
                        ></CustomSwitch>
                    </TableCell>
                );
            }
            case "cropColumnStatus": {
                return (
                    <TableCell>
                        <CustomSwitch
                            key={getRandomizeValue()}
                            status={row.isActive}
                            id={"crop-status-column"}
                            onStatusChange={(isChecked: boolean) =>
                                onStatusChanged!(isChecked, row)
                            }
                        />
                    </TableCell>
                );
            }
            case "createdDate":
                return (
                    <TableCell key={`${fieldName}-${index}`}>
                        {moment(value)
                            .add(moment().utcOffset(), "m")
                            .format("DD/MM/YYYY hh:mm a")}
                    </TableCell>
                );
            case "media":
                if (row.queryMediaList.length > 0) {
                    return (
                        <TableCell key={`${fieldName}-${index}`}>
                            <Box className={classes.MediaWrapper} key={index}>
                                {_.uniqBy(row.queryMediaList, "type").map((media: any) => {
                                    return (
                                        media.type === "IMAGE" &&
                                        media.url !== null && (
                                            <Box sx={{ pr: 1 }}>
                                                <img alt={'image-path'} src={media.url} className={classes.ImageStyle} />
                                            </Box>
                                        )
                                    );
                                })}
                                {_.uniqBy(row.queryMediaList, "type").map((media: any) => {
                                    return (
                                        media.type === "AUDIO" &&
                                        media.url !== null && (
                                            <img
                                                src={require("../../assets/play-button-outline-green.png")}
                                                alt="Video should Display here"
                                                className={classes.IconImageStyle}
                                            />
                                        )
                                    );
                                })}
                                {_.uniqBy(row.queryMediaList, "type").map((media: any) => {
                                    return (
                                        media.type === "VIDEO" &&
                                        media.url !== null && (
                                            <Box
                                                className={classes.VideoWrapper}
                                                sx={{
                                                    backgroundColor:
                                                        row?.responded === true ? "white" : "whitesmoke",
                                                }}
                                            >
                                                <ReactPlayer
                                                    className="react-player"
                                                    url={media.url}
                                                    width="75px"
                                                    height="50px"
                                                />
                                            </Box>
                                        )
                                    );
                                })}
                            </Box>
                        </TableCell>
                    );
                } else {
                    return <TableCell key={`${fieldName}-${index}`}></TableCell>;
                }
            case "rating":
                if (value !== null) {
                    return (
                        <TableCell key={`${fieldName}-${index}`}>
                            <Rating value={value} readOnly />
                        </TableCell>
                    );
                } else {
                    return <TableCell key={`${fieldName}-${index}`}></TableCell>;
                }
            case "modifiedDate":
                return (
                    <TableCell key={`${fieldName}-${index}`}>
                        {moment(value).add(moment().utcOffset(), "m").format("YYYY/MM/DD")}
                    </TableCell>
                );
            case "triggeredDate":
                return (
                    <TableCell key={`${fieldName}-${index}`}>
                        {moment(value, "DD/MM/YYYY").format("YYYY/MM/DD")}
                    </TableCell>
                );
            case "lastUpdatedDate":
                return (
                    <TableCell key={`${fieldName}-${index}`}>
                        {moment(value)
                            .add(moment().utcOffset(), "m")
                            .format("YYYY/MM/DD hh:mm a")}
                    </TableCell>
                );
            case "feedurl":
                const isVideo = row.feedurl && row.feedurl.indexOf("youtu") > 0;
                const urlYoutube = extractVideoIdFromUrl(row.feedurl);
                return (
                    <TableCell key={`${fieldName}-${index}`}>
                        <Box className={classes.MediaWrapper} key={index}>
                            {isVideo && urlYoutube ? (
                                <Box
                                    className={classes.VideoWrapper}
                                    sx={{
                                        backgroundColor:
                                            row?.responded === true ? "white" : "whitesmoke",
                                    }}
                                >
                                    <YouTube
                                        className={'react-player'}
                                        opts={{
                                            width: '75px', height: '50px'
                                        }}
                                        videoId={urlYoutube}
                                        onReady={(event) => {
                                            event.target.pauseVideo();
                                        }}
                                        onPlay={(event) => event.target.pauseVideo()}
                                    />
                                </Box>
                            ) : (
                                <Box sx={{ pr: 1 }}>
                                    <img alt={row.feedurl} src={row.feedurl} className={classes.ImageStyle} />
                                </Box>
                            )}
                        </Box>
                    </TableCell>
                );
            default:
                return <TableCell key={`${fieldName}-${index}`}>{value}</TableCell>;
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setSelectAllChecked(checked);
        onSelectAll(checked);
    };

    const handleSelectRow = (
        event: React.ChangeEvent<HTMLInputElement>,
        rowData: any
    ) => {
        const { checked } = event.target;
        onRowSelect(checked, rowData);
    };

    const renderData = () => {
        if (storeData.status) {
            return (
                <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                        <MyLoader className={classes.noDataFound} />
                    </TableCell>
                </TableRow>
            );
        } else if (!storeData.status && data.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                        <Typography className={classes.noDataFound}>
                            {"No Data Found"}
                        </Typography>
                    </TableCell>
                </TableRow>
            );
        } else {
            return (
                <>
                    {data.map((row, index: number) => {
                        return (
                            <TableRow key={`data-table-${index}`} className={classes.noBorderHelper}>
                                {enableSelection && (
                                    <TableCell key={"checkbox-selectall" + index}>
                                        <Checkbox
                                            checked={row.checked || false}
                                            size="small"
                                            data-testid={`checkbox-tables`}
                                            className={classes.checkboxHelper}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                handleSelectRow(event, row)
                                            }
                                        />
                                    </TableCell>
                                )}
                                {columns.map(
                                    (column: PushNotificationColumnType, idx: number) =>
                                        GetColumn(idx, column.fieldName, row[column.fieldName], row)
                                )}
                                <TableCell>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        {url !== "languages" ? (
                                            <>
                                                {isStatusBadge && (
                                                    <div className={classes.readOnlyBadge}>Created</div>
                                                )}
                                                {isViewButton && (
                                                    <Button
                                                        className={classes.viewButtonHelper}
                                                        variant="text"
                                                        data-testid="view-button"
                                                        onClick={() => {
                                                            onActionClick!(
                                                                "View_Push",
                                                                row,
                                                                selectedPublishModule
                                                            );
                                                        }}
                                                    >
                                                        <Typography variant="subtitle2">View</Typography>
                                                    </Button>
                                                )}
                                            </>
                                        ) : null}
                                        {isPublishedEnabled && (
                                            <Button
                                                className={classes.editButtonHelper}
                                                variant="text"
                                                onClick={() =>
                                                    onActionClick && onActionClick("Publish", row)
                                                }
                                            >
                                                <Typography variant="subtitle2">Publish</Typography>
                                            </Button>
                                        )}
                                        {isEditEnabled && (
                                            <Button
                                                className={classes.editButtonHelper}
                                                variant="text"
                                                endIcon={
                                                    <img
                                                        src={`${ASSETS_PATH}Pencil_Icon.svg`}
                                                        alt="Edit"
                                                    />
                                                }
                                                onClick={() =>
                                                    onActionClick && onActionClick("Edit_Push", row)
                                                }
                                            >
                                                <Typography variant="subtitle2">Edit</Typography>
                                            </Button>
                                        )}
                                        {isDeleteEnabled && (
                                            <Button
                                                className={classes.deleteButtonHelper}
                                                variant="text"
                                                color="error"
                                                onClick={() =>
                                                    onActionClick && onActionClick("Delete", row)
                                                }
                                            >
                                                <Typography variant="subtitle2">Delete</Typography>
                                            </Button>
                                        )}
                                        {hasTranslation &&
                                            languageKey &&
                                            row[languageKey] &&
                                            row[languageKey].toLowerCase() ===
                                            storeData.primaryLanguageName[
                                                "languageName"
                                            ].toLowerCase() && (
                                                <Button
                                                    className={classes.addTransButtonHelper}
                                                    variant="text"
                                                    color="success"
                                                    onClick={() =>
                                                        onActionClick &&
                                                        onActionClick(ActionType.ADD_TRANSLATION, row)
                                                    }
                                                >
                                                    <Typography variant="subtitle2">
                                                        Add Translations
                                                    </Typography>
                                                </Button>
                                            )}
                                        {actionsType !== ACTION_TYPE.TYPE_PUBLISH &&
                                            row.statusId === 5 ? (
                                            <ReadOnlyBadge_Created>
                                                Publish
                                                <br />
                                                Pending
                                            </ReadOnlyBadge_Created>
                                        ) : null}
                                        {isDownLoadButton && (
                                            <Button
                                                className={classes.downloadButtonHelper}
                                                variant="text"
                                                onClick={() => console.log("row:", row)}
                                            >
                                                <Typography variant="subtitle2">
                                                    <Link
                                                        color="primary"
                                                        onClick={() =>
                                                            onActionClick && onActionClick("Download", row)
                                                        }
                                                        underline="none"
                                                        target="_blank"
                                                    >
                                                        Download
                                                    </Link>
                                                </Typography>
                                            </Button>
                                        )}
                                        {isRespondButton && (
                                            <Button
                                                className={
                                                    row.responded === true
                                                        ? classes.disableBtnColor
                                                        : classes.viewButtonHelper
                                                }
                                                variant="text"
                                                onClick={() => {
                                                    row.responded === false &&
                                                        onActionClick!("Respond_Push", row);
                                                }}
                                            >
                                                <Typography variant="subtitle2">
                                                    {row.responded === false ? "Respond" : "Responded"}
                                                </Typography>
                                            </Button>
                                        )}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </>
            );
        }
    };

    let url = window.location.href.split("/")[3];

    return (
        <>
            <TableContainer
                className={classes.tableContainerHelper}
                data-testid="table-container"
            >
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    className={classes.tableHelper}
                >
                    <TableHead>
                        <TableRow>
                            {enableSelection && (
                                <TableCell
                                    key="checkbox-selectall"
                                    className={classes.tableHeadCellHelper}
                                >
                                    {!singleSelection ? (
                                        <Checkbox
                                            checked={
                                                !data.some((rows: any) => !rows.checked)
                                            }
                                            size="small"
                                            data-testid="checklist-all"
                                            className={classes.checkboxHelper}
                                            onChange={handleChange}
                                        />
                                    ) : null}
                                </TableCell>
                            )}
                            {columns.map(
                                (column: PushNotificationColumnType, index: number) => {
                                    return (
                                        <TableCell
                                            key={`${column.fieldName}-${index}`}
                                            className={
                                                index + 1 === columns.length
                                                    ? [
                                                        classes.tableHeadCellHelper,
                                                        classes.emptyCellHelper,
                                                    ].join(" ")
                                                    : classes.tableHeadCellHelper
                                            }
                                        // colSpan={index + 1 === columns.length ? 2 : 1}
                                        >
                                            <Typography variant="subtitle2">
                                                {column.fieldLabel}
                                            </Typography>
                                        </TableCell>
                                    );
                                }
                            )}
                            <TableCell
                                key="column-action"
                                className={classes.tableHeadCellHelper}
                            >
                                {isActionTitleEnabled && (
                                    <Typography variant="subtitle2">Action</Typography>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderData()}</TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default React.memo(DataTable);

