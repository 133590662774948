import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import api_endpoints, { base_url } from "../../Api/end_points";
import {
  fetchConfigModules,
  fetchCountryModules,
  fetchCropsList,
  fetchLanguagesList,
  fetchMobileLengthNurseryData,
  fetchNotificationTypes,
  getApplicationConfig,
  getDefaultLanguage,
} from "../../Api/generic_apicalls";
import { ASSETS_PATH } from "../../Constants/contants";
import { IRequestBody } from "../../Constants/interfaces";
import { RootState } from "../../redux/reducers";
import appActions from "./../../redux/action/index";
import { useStyles } from "../Login/loginStyles";

type CountryList = {
  baseUrl: string;
  countryCode: string;
  countryName: string;
};

const CountrySelection: React.FC = (props) => {
  const storeProps = useSelector((state: RootState) => {
    return {
      token: state.auth.authToken,
      countryModules: state.generic.ListOfModules,
    };
  });
  // classes
  const classes = useStyles();
  const dispatch = useDispatch();
  // States
  const [countryList, setCountryList] = React.useState<Array<CountryList>>([]);
  const [selectedCountry, setSelectedCountry] = React.useState<string>("");
  const [, toggleAppLoader] = React.useState(true);

  const loadAppMetaData = () => {
    const countryInfo = selectedCountry.split(":::");
    getDefaultLanguage(countryInfo[1])
      .then((response: any) => {
        dispatch(appActions.apiData.userLanguage(response.data));
        dispatch(appActions.apiData.userCountry(countryInfo[1]));
        Promise.all([
          fetchLanguagesList(dispatch),
          fetchCropsList(dispatch),
          fetchNotificationTypes(dispatch),
          fetchCountryModules(countryInfo[1], dispatch),
          fetchMobileLengthNurseryData(countryInfo[1], dispatch),
        ])
          .then(async (values) => {
            dispatch(appActions.apiData.baseURL(countryInfo[0]));
          })
          .catch((err) => {
            throw new Error(err.message);
          });
      })
      .catch(() => {})
      .finally(() => {
        toggleAppLoader(false);
      });
  };
  const fetchApplicationConfig = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.app_config;
    apiData.showLoader = false;
    apiData.customHeaders = {
      Authorization: `Bearer ${storeProps.token}`,
    };
    getApplicationConfig(apiData)
      .then((response: any) => {
        dispatch(appActions.apiData.appCountries(response.data));
        setCountryList(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err.message)
      });
  };
  const onCountrySelect = (data: SelectChangeEvent) => {
    setSelectedCountry(data.target.value);
  };

  React.useEffect(() => {
    fetchApplicationConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const countryInfo = selectedCountry.split(":::");

    if (storeProps.countryModules.length > 0) {
      fetchConfigModules(countryInfo[1], dispatch, storeProps.countryModules);
    }
  }, [storeProps.countryModules, selectedCountry]);

  return (
    <div className={classes.contentHelper}>
      <div>
        <Box className={classes.loginContainerHelper}>
          <div>
            <img
              style={{ height: "120px" }}
              src={`${ASSETS_PATH}Web_Portal_Logo.svg`}
              alt="Smartgrower admin web portal"
            />
          </div>
        </Box>
      </div>
      <Paper className={classes.paperHelper}>
        <div className={classes.innerContentHelper}>
          <Icon className={classes.iconHelper}>
            <img src={`${ASSETS_PATH}Plant_Icon.svg`} alt="Plant" />
          </Icon>
          <Typography variant="subtitle1">Hello There,</Typography>
          <FormControl variant="standard" fullWidth>
            {/* -----------------------------Country Selection DropDown Start-------------------------------- */}
            <Typography fontSize={14}>Please select your country.</Typography>
            <FormControl variant="standard" fullWidth>
              <Typography
                className={classes.emailLabelHelper}
                variant="subtitle2"
              >
                Select Country *
              </Typography>
              <Select
                className={classes.countrySelectionHelper}
                value={selectedCountry}
                data-testid="country-select"
                MenuProps={{ classes: { paper: classes.Select } }}
                onChange={(event: SelectChangeEvent) => onCountrySelect(event)}
                IconComponent={() => (
                  <ExpandMoreIcon className={classes.selectExpandMoreHelper} />
                )}
                variant="outlined"
              >
                {countryList?.map((o) => {
                  return (
                    <MenuItem
                      key={o.countryCode}
                      value={`${o.baseUrl}:::${o.countryCode}`}
                    >
                      {o.countryName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* -----------------------------Country Selection DropDown End-------------------------------- */}
          </FormControl>
          <Button
            color="success"
            className={classes.signInButtondHelper}
            variant="contained"
            disabled={selectedCountry === ""}
            onClick={loadAppMetaData}
          >
            <Typography fontSize={12} variant="subtitle2">
              Proceed
            </Typography>
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default CountrySelection;
